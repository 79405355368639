import {
    Form, Input, Typography, Button, Divider,
} from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ModalWrapper from '../Modals';
import FormResetPassword from '../Modals/ResetPassword';

import { loginUser, setError, setLoading } from '../../store/slices/auth';

const { Title } = Typography;

interface FormProps {
    email: string;
    password: string;
}

const LoginForm = () => {
    const { t } = useTranslation('login');
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });
    const { loading, emailExist } = useSelector((state: any) => state.auth);

    const [form] = Form.useForm();
    const [visible, setVisible] = useState<boolean>(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const dispatch = useDispatch<any>();

    const RULES: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
    ];

    const RULES_EMAIL: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
        { type: 'email', message: tf('RULES.INVALID_EMAIL') },
        { type: 'string', min: 3, message: tf('RULES.MIN_LENGTH', { length: 3 }) },
    ];

    const onSubmit = ({ email, password }: FormProps) => {
        dispatch(loginUser({ email, password }));
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
        >
            <ModalWrapper
                visible={visible}
                onCancel={() => setVisible(false)}
            >
                <FormResetPassword />
            </ModalWrapper>
            <Form.Item
                label={tf('INPUTS.EMAIL')}
                name="email"
                rules={RULES_EMAIL}
            >
                <Input placeholder={tf('INPUTS.EMAIL_PLACEHOLDER')} />
            </Form.Item>
            <Form.Item
                label={tf('INPUTS.PASSWORD')}
                name="password"
                rules={RULES}
            >
                <Input.Password
                    placeholder={tf('INPUTS.PASSWORD_PLACEHOLDER')}
                    visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
                />
            </Form.Item>

            <Title
                level={5}
                style={{ textAlign: 'end', cursor: 'pointer' }}
                onClick={() => {
                    dispatch(setLoading(false));
                    dispatch(setError(null));
                    setVisible(true);
                }}
            >
                {t('FORGOT_PASSWORD')}
            </Title>

            <Divider />

            <Button
                block
                type="primary"
                loading={loading}
                htmlType="submit"
            >
                {t('LOGIN')}
            </Button>

        </Form>
    );
};

export default LoginForm;
