import { useContext } from 'react';
import {
    PageHeader, Space, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { CommunityContext } from '../../components/Community/context/community.context';
import { Manage } from '../../components/Community';

const { Paragraph } = Typography;

const CommunityScreen = () => {
    const { t } = useTranslation('community');

    const context = useContext(CommunityContext);

    return (
        <Space direction="vertical" size={8} className="full__width">
            <PageHeader
                className="site-page-header-responsive"
                title={t('WELCOME_COMMUNITY')}
            >
                <Paragraph>
                    {t('DESCRIPTION_COMMUNITY')}
                </Paragraph>
            </PageHeader>

            <Manage {...context} />
        </Space>
    );
};

export default CommunityScreen;
