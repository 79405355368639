import EditArticle from '../../components/Articles/components/Edit';
import { ArticlesProvider } from '../../components/Articles/context/articlesContext';

const ArticleDetail = () => (
    <ArticlesProvider>
        <EditArticle />
    </ArticlesProvider>
);

export default ArticleDetail;
