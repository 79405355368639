import axios from 'axios';
import { DASHBOARD_API } from '../config';

const signup = (payload: any) => {
    const url = `${DASHBOARD_API}/users/signup`;
    const response = axios.post(url, payload);
    return response;
};

const login = (payload: any) => {
    const url = `${DASHBOARD_API}/users/login`;
    const response = axios.post(url, payload);
    return response;
};

const checkExistEmail = (email: string) => {
    const url = `${DASHBOARD_API}/users/check-email?email=${email}`;
    const response = axios.post(url);
    return response;
};

const loadUser = (token: string) => {
    const headers = { authorization: `Bearer ${token}` };
    const url = `${DASHBOARD_API}/users/login/initSession`;
    const response = axios.get(url, { headers });
    return response;
};

export default {
    signup,
    login,
    checkExistEmail,
    loadUser,
};
