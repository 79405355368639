import { IContext } from './study-groups.interface';

export const initialContext: IContext = {
    loading: false,
    loadAction: false,
    studyGroups: [] as any[],
    studyGroup: null,
    total: 0,
    searchParams: {},
    paginator: {
        limit: 10,
        page: 1,
    },
    errors: [] as string[],
    onPageChange: () => {},
    fetchStudyGroups: () => {},
    handleSearch: () => {},
    setStudyGroup: () => {},
};
