import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import { ReviewsProvider } from '../../providers';
import { ReviewsScreen, CreateScreen, UpdateScreen } from '../../screens/Admin/Reviews';

export const ReviewsRouter: FC = () => (
    <Routes>
        <Route
            path=""
            element={(
                <ReviewsProvider>
                    <ReviewsScreen />
                </ReviewsProvider>
            )}
        />
        <Route
            path="create"
            element={(
                <ReviewsProvider>
                    <CreateScreen />
                </ReviewsProvider>
            )}
        />
        <Route
            path=":id"
            element={(
                <ReviewsProvider>
                    <UpdateScreen />
                </ReviewsProvider>
            )}
        />
    </Routes>
);
