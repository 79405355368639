import esES from 'antd/es/locale/es_ES';
import enUS from 'antd/es/locale/en_US';
import ptPT from 'antd/es/locale/pt_PT';

import { ConfigProvider, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';

const DateSelector = ({ onChangeDate }: { onChangeDate: (date: any, dateString: any) => void}) => {
    const { i18n } = useTranslation();

    return (
        <ConfigProvider
            locale={
                i18n.language === 'en'
                    ? enUS : i18n.language === 'pt'
                        ? ptPT : esES
            }
        >
            <DatePicker className="full__width" onChange={onChangeDate} />
        </ConfigProvider>
    );
};
export default DateSelector;
