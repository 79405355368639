import { IContext } from './reviews.interface';

export const initialContext: IContext = {
    loading: false,
    loadAction: false,
    reviews: [] as any[],
    review: null,
    total: 0,
    searchParams: {},
    paginator: {
        limit: 10,
        page: 1,
    },
    errors: [] as string[],
    onPageChange: () => {},
    fetchReviews: () => {},
    handleSearch: () => {},
    handleCountry: () => {},
    setUnavailable: () => {},
    addReview: () => {},
    updateReview: () => {},
    setReview: () => {},
};
