import {
    Col, Form, Input, Row, Typography, Select, DatePickerProps, Switch, Button, Space, Avatar, Divider,
} from 'antd';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMinusCircle } from 'react-icons/fi';
import { WebinarsContext } from '../context/webinars.context';
import DateSelector from '../../../../widgets/Date';
import UploadImage from '../../../UploadImage';
import { COUNTRIES } from '../../../../utils/countries';
import Channels from '../../../../widgets/Channels';
import Interviewers from '../../../../widgets/Interviewers';
import { IUser } from '../../../../interfaces';

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const AddWebinar = ({ form }: { form: any }) => {
    const { t } = useTranslation('admin');
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });
    const { t: ti } = useTranslation('forms', { keyPrefix: 'FORMS.INPUTS' });

    const [interviewers, setInterviewers] = useState<IUser[]>([]);
    const [interviewees, setInterviewees] = useState<IUser[]>([]);
    const [moderators, setModerators] = useState<IUser[]>([]);

    const RULES: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
    ];

    const { categories } = useContext(WebinarsContext);

    const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
        form.setFieldsValue({ date: date?.toISOString() });
    };

    const handleSetInterviewers = (members: IUser[]) => {
        form.setFieldsValue({ interviewers: members });
        setInterviewers(members);
    };
    const handleSetInterviewees = (members: IUser[]) => {
        form.setFieldsValue({ interviewees: members });
        setInterviewees(members);
    };
    const handleSetModerators = (members: IUser[]) => {
        form.setFieldsValue({ moderators: members });
        setModerators(members);
    };

    return (
        <Row gutter={[32, 40]}>
            <Col xs={24} xxl={12} className="col-left">
                <div className="services__container none__margin--top none__padding--large">
                    <div className="services__container-header">
                        <Title level={4}>
                            {t('INFORMATION')}
                        </Title>
                    </div>
                    <div className="services__container-form">
                        <Form.Item name="interviewers" noStyle hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name="interviewees" noStyle hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name="moderators" noStyle hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={`${ti('WEBINARS.TOPIC')} (es)`}
                            name="topic_es"
                            rules={RULES}
                        >
                            <Input placeholder={ti('WEBINARS.TOPIC_PLACEHOLDER')} />
                        </Form.Item>
                        <Form.Item
                            label={`${ti('WEBINARS.TOPIC')} (en)`}
                            name="topic_en"
                            rules={RULES}
                        >
                            <Input placeholder={ti('WEBINARS.TOPIC_PLACEHOLDER')} />
                        </Form.Item>
                        <Form.Item
                            label={`${ti('WEBINARS.TOPIC')} (pt)`}
                            name="topic_pt"
                            rules={RULES}
                        >
                            <Input placeholder={ti('WEBINARS.TOPIC_PLACEHOLDER')} />
                        </Form.Item>

                        <Divider />

                        <Form.Item
                            label={`${ti('WEBINARS.SUMMARY')} (es)`}
                            name="summary_es"
                            rules={RULES}
                        >
                            <Input placeholder={ti('WEBINARS.SUMMARY_PLACEHOLDER')} />
                        </Form.Item>
                        <Form.Item
                            label={`${ti('WEBINARS.SUMMARY')} (en)`}
                            name="summary_en"
                            rules={RULES}
                        >
                            <Input placeholder={ti('WEBINARS.SUMMARY_PLACEHOLDER')} />
                        </Form.Item>
                        <Form.Item
                            label={`${ti('WEBINARS.SUMMARY')} (pt)`}
                            name="summary_pt"
                            rules={RULES}
                        >
                            <Input placeholder={ti('WEBINARS.SUMMARY_PLACEHOLDER')} />
                        </Form.Item>

                        <Row gutter={[16, 24]}>
                            <Col xs={24} xxl={12} className="col-left">
                                <Form.Item
                                    label={ti('WEBINARS.CATEGORY')}
                                    name="categoryID"
                                    rules={RULES}
                                    initialValue={categories[0]?._id}
                                >
                                    <Select
                                        placeholder={ti('WEBINARS.CATEGORY_PLACEHOLDER')}
                                        className="full__width"
                                    >
                                        {
                                            categories.map((category, index) => (
                                                <Option
                                                    value={category._id}
                                                    key={index}
                                                >
                                                    {category.name}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} xxl={12} className="col-right">
                                <Form.Item
                                    label={ti('WEBINARS.TAG')}
                                    name="tag"
                                    rules={RULES}
                                >
                                    <Input placeholder={ti('WEBINARS.TAG_PLACEHOLDER')} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item
                            label={ti('WEBINARS.REGISTER_LINK')}
                            name="registerLink"
                        >
                            <Input
                                placeholder={ti('WEBINARS.REGISTER_LINK_PLACEHOLDER')}
                            />
                        </Form.Item>

                        <Form.Item
                            label={ti('DATE')}
                            name="date"
                        >
                            <DateSelector onChangeDate={onChangeDate} />
                        </Form.Item>

                        <Interviewers
                            label="WEBINARS.INTERVIEWERS"
                            members={interviewers}
                            setMembers={handleSetInterviewers}
                        />
                        <Divider />

                        <Interviewers
                            label="WEBINARS.INTERVIEWEES"
                            members={interviewees}
                            setMembers={handleSetInterviewees}
                        />

                        <Divider />

                        <Interviewers
                            label="WEBINARS.MODERATORS"
                            members={moderators}
                            setMembers={handleSetModerators}
                        />

                    </div>
                </div>
            </Col>
            <Col xs={24} xxl={12} className="col-right">
                <div className="services__container none__margin--top none__padding--large">
                    <div className="services__container-header">
                        <Title level={4}>
                            {t('MARKETING')}
                        </Title>
                    </div>
                    <div className="services__container-form">
                        <Form.Item
                            label={ti('WEBINARS.IMAGE')}
                            name="image"
                            noStyle
                        >
                            <UploadImage t={tf} type="webinars" name={form.getFieldValue('tag')} />
                        </Form.Item>

                        <Form.Item
                            label={ti('WEBINARS.SURVEY')}
                            name="survey"
                        >
                            <Input
                                placeholder={ti('WEBINARS.SURVEY_PLACEHOLDER')}
                            />
                        </Form.Item>

                        <Row gutter={[16, 24]}>
                            <Col xs={24} xl={12} className="col-left">
                                <Form.Item
                                    label={ti('WEBINARS.VIDEO_LINK')}
                                    name="videoLink"
                                >
                                    <Input
                                        placeholder={ti('WEBINARS.VIDEO_LINK_PLACEHOLDER')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} xl={12} className="col-right">
                                <Form.Item
                                    label={ti('WEBINARS.SHOW_VIDEO')}
                                    valuePropName="videoIsAvailable"
                                    name="videoIsAvailable"
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item
                            label={ti('WEBINARS.VIDEO_FRAME')}
                            name="videoFrame"
                        >
                            <TextArea
                                placeholder={ti('WEBINARS.VIDEO_FRAME_PLACEHOLDER')}
                            />
                        </Form.Item>

                        <Form.Item label={ti('WEBINARS.SCHEDULES')}>
                            <Form.List name="schedules">
                                {(fields, { add, remove }) => (
                                    <>
                                        {
                                            fields.map(({ key, name, ...restField }) => (
                                                <Space
                                                    key={key}
                                                    style={{ display: 'flex' }}
                                                    align="start"
                                                    className="full__width"
                                                >
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'country']}
                                                        rules={RULES}
                                                        initialValue="ECU"
                                                    >
                                                        <Select
                                                            placeholder={t('COUNTRY_PLACEHOLDER')}
                                                            showSearch
                                                        >
                                                            {
                                                                COUNTRIES.map((country, index) => (
                                                                    <Option value={country.code} key={index}>
                                                                        <Avatar
                                                                            src={country.flag}
                                                                            style={{
                                                                                marginRight: 8,
                                                                                width: 32,
                                                                                height: 20,
                                                                            }}
                                                                            shape="square"
                                                                        />
                                                                        {country.name}
                                                                    </Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'time']}
                                                        rules={RULES}
                                                    >
                                                        <Input placeholder={ti('WEBINARS.TIME_PLACEHOLDER')} />
                                                    </Form.Item>

                                                    <FiMinusCircle
                                                        size={16}
                                                        color="#d83b01"
                                                        onClick={() => remove(name)}
                                                        style={{ cursor: 'pointer', verticalAlign: 'middle' }}
                                                    />
                                                </Space>
                                            ))
                                        }
                                        <Form.Item>
                                            <Button
                                                block
                                                type="dashed"
                                                onClick={() => add()}
                                            >
                                                {ti('WEBINARS.ADD_SCHEDULE')}
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>

                        <Channels />

                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default AddWebinar;
