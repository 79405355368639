import moment from 'moment';
import { Space, Typography } from 'antd';
import { IComment, IResponse } from '../../interfaces';
import Author from './Author';
import { IContext } from '../../components/Community/context/community.interface';
import Actions from './Actions';

import 'moment/locale/es';
import 'moment/locale/pt-br';

const { Text } = Typography;

const Response = ({
    comment,
    response,
    props,
}: {
    comment: IComment,
    response: IResponse,
    props: IContext,
}) => {

    const language = localStorage.getItem('i18nextLng');
    const keyLanguage = language?.split('-')[0];

    const {
        likeResponseComment,
    } = props;

    return (
        <div className="card__comments--comment">
            <Author
                image={response.userID?.image}
                name={
                    `${response.userID?.name} ${response.userID?.lastName}`
                }
            />
            <div style={{ paddingLeft: 40 }}>
                <Space direction="vertical" size={0}>
                    <Text>
                        {response.content}
                    </Text>
                    <Text className="card__date">
                        {moment(response.createdAt).locale(keyLanguage as string).fromNow()}
                    </Text>
                </Space>
                <Actions
                    comments={0}
                    likes={response?.likes?.length || 0}
                    like={() => likeResponseComment(response._id as string, comment._id as string)}
                    commentSingularText="RESPONSE"
                    commentPluralText="RESPONSES"
                />
            </div>
        </div>
    );
};

export default Response;
