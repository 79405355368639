import cs from 'classnames';
import {
    Form, Button, Input, Row, Col, Card, Space, Avatar, Typography,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MEMBERSHIPS } from '../../constants/memberships';
import { IMembership } from '../../interfaces';
import { updateMembershipData } from '../../store/slices/auth';

const { Title, Paragraph } = Typography;

const MembershipForm = () => {
    const { t } = useTranslation('forms', { keyPrefix: 'FORMS' });
    const { t: ts } = useTranslation('shared', { keyPrefix: 'MEMBERSHIP' });

    const { user, loading, error } = useSelector((state: any) => state.auth);

    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [membership, setMembership] = useState<IMembership>();

    const onSubmit = (values: any) => {
        const category = { ...values };
        const userID = user?._id;
        const payload = { userID, category, membership };
        dispatch(updateMembershipData(payload));
        if (!loading && !error) {
            form.resetFields();
            navigate('/onboarding/completed');
        }
    };

    const onChange = (membership: IMembership) => {
        setMembership(membership);
        form.setFieldsValue({ membership: membership.keyName });
    };

    return (
        <Form
            form={form}
            layout="vertical"
            className="onboarding__form"
            onFinish={onSubmit}
        >

            <Form.Item
                hidden
                name="membership"
            >
                <Input />
            </Form.Item>

            <Space direction="vertical" size={24}>
                <Row gutter={16}>
                    {
                        MEMBERSHIPS.map((membership: IMembership) => {
                            const {
                                keyName, name, target, purpose, image, color,
                            } = membership;

                            const title = `${ts('CATEGORY')} ${name}`;

                            return (
                                <Col xs={24} xl={8}>
                                    <Card
                                        title={(
                                            <Space direction="horizontal" size={12}>
                                                <Avatar
                                                    size={28}
                                                    src={image}
                                                    shape="square"
                                                    className="membership__card--avatar"
                                                />
                                                <span
                                                    style={{ color }}
                                                    className="membership__card--title"
                                                >
                                                    {title}
                                                </span>
                                            </Space>
                                        )}
                                        hoverable
                                        key={keyName}
                                        onClick={() => onChange(membership)}
                                        className={
                                            cs(
                                                'membership__card',
                                                keyName,
                                                { active: keyName === form.getFieldValue('membership') },
                                            )
                                        }
                                    >
                                        <span className="membership__card--target" style={{ color }}>
                                            {ts(`${target}`)}
                                        </span>
                                        <Paragraph className="membership__card--paragraph">
                                            {ts(`${purpose}`)}
                                        </Paragraph>
                                    </Card>
                                </Col>
                            );
                        })
                    }
                </Row>

                <Button
                    block
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                >
                    {t('ACTIONS.CONTINUE')}
                </Button>
            </Space>

        </Form>
    );
};

export default MembershipForm;
