import { useSearchParams } from 'react-router-dom';
import { StudyGroups } from '../../../components/Admin/StudyGroups';

const StudyGroupsScreen = () => {
    const [searchParams] = useSearchParams();

    return (
        <div className="articles__edit">
            <StudyGroups params={searchParams} />
        </div>
    );
};

export default StudyGroupsScreen;
