import { Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const Loading = () => {
    const { t } = useTranslation('shared');

    return (
        <div className="loading__section">
            <Spin />
            <Title level={4}>
                {t('GETTING_DATA')}
            </Title>
        </div>
    );
};

export default Loading;
