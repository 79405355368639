import { Row, Typography } from 'antd';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { updateSettingsData } from '../../store/slices/auth';

interface Props {
    t: (key: string) => string;
}

const { Title, Paragraph } = Typography;

const Welcome = ({ t }: Props) => {
    const dispatch = useDispatch<any>();

    const closeBanner = () => {
        const payload = {
            isFirstTime: false,
        };
        dispatch(updateSettingsData(payload));
    };

    return (
        <div className="home__welcome">
            <Row align="top" justify="space-between">
                <Title level={3}>
                    {t('WELCOME')}
                </Title>
                <div className="action__icon" onClick={() => closeBanner()}>
                    <AiFillCloseCircle className="action__icon-icon" />
                </div>
            </Row>
            <Paragraph>
                {t('WELCOME_MESSAGE')}
            </Paragraph>
        </div>
    );
};

export default Welcome;
