import {
    Row, Col, Typography, Space,
} from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { theme } from '../../theme';

import SignUpForm from '../../components/Forms/SignupForm';
import WelcomeMessage from '../../components/Login/WelcomeMessage';
import LogoRectoral from '../../widgets/Logo';
import { setError, setLoading } from '../../store/slices/auth';

const { Title, Paragraph } = Typography;

const Signup = () => {
    const { t } = useTranslation('login');

    const dispatch = useDispatch<any>();

    useEffect(() => {
        dispatch(setLoading(false));
        dispatch(setError(null));
    }, []);

    return (
        <Row className="full__size">
            <Col xs={24} lg={12} className="wrapper wrapper__signup">
                <div className="forms__main">

                    <Space direction="vertical" size={24} className="full__width">
                        <LogoRectoral
                            size={{ width: 150 }}
                            type="dark"
                            direction="horizontal"
                            className="responsive"
                        />

                        <Title level={2}>
                            {t('SIGN_UP')}
                        </Title>
                    </Space>

                    <Paragraph>
                        {t('REGISTER_YOUR_INFORMATION')}
                    </Paragraph>

                    <SignUpForm />

                    <Space
                        className="dont-has-account"
                        direction="horizontal"
                        size={8}
                    >
                        <Title
                            level={5}
                            style={{
                                color: theme.black,
                                fontWeight: 400,
                            }}
                        >
                            {t('HAVE_ACCOUNT')}
                        </Title>

                        <Link to="/login">
                            <Title level={5}>
                                {t('LOGIN')}
                            </Title>
                        </Link>
                    </Space>

                </div>
            </Col>
            <Col xs={0} lg={12} className="wrapper sliders">
                <WelcomeMessage />
            </Col>
        </Row>
    );
};

export default Signup;
