import Academic from '../components/Onboarding/Academic';
import Completed from '../components/Onboarding/Completed';
import Membership from '../components/Onboarding/Membership';

import { STEPS_PATH } from '../enums/Steps';
import { IStep } from '../interfaces';

export const PATHS = {
    ACADEMIC: STEPS_PATH.ACADEMIC,
    MEMBERSHIP: STEPS_PATH.MEMBERSHIP,
    COMPLETED: STEPS_PATH.COMPLETED,
};

export const STEPS: IStep[] = [
    {
        name: 'ACADEMIC.NAME',
        path: PATHS.ACADEMIC,
        component: Academic,
    },
    {
        name: 'MEMBERSHIP.NAME',
        path: PATHS.MEMBERSHIP,
        component: Membership,
    },
    {
        name: 'COMPLETED.NAME',
        path: PATHS.COMPLETED,
        component: Completed,
    },
];
