import {
    Form, Input, Button, Divider,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createAccount } from '../../store/slices/auth';
import Country from '../../widgets/Country';
import PhoneNumber from '../../widgets/PhoneNumber';
import TermsAndConditions from '../../widgets/TermsAndConditions';
import Gender from '../../widgets/Gender';

const SignupForm = () => {
    const { t } = useTranslation('login');
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });
    const { loading } = useSelector((state: any) => state.auth);

    const dispatch = useDispatch<any>();

    const [form] = Form.useForm();
    const [passwordVisible, setPasswordVisible] = useState(false);

    const RULES: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
    ];

    const RULES_EMAIL: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
        { type: 'email', message: tf('RULES.INVALID_EMAIL') },
        { type: 'string', min: 3, message: tf('RULES.MIN_LENGTH', { length: 3 }) },
    ];

    const onSubmit = (values: any) => {
        const { countryCode, mobile } = values;
        const user = {
            ...values,
            fullMobile: `${countryCode}${mobile}`,
        };
        delete user.terms;
        dispatch(createAccount(user));
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            initialValues={{ terms: true }}
        >
            <Form.Item name="role" hidden initialValue={['SIGNATORY']}>
                <Input />
            </Form.Item>

            <Form.Item name="origin" hidden initialValue="WEB">
                <Input />
            </Form.Item>

            <Form.Item
                label={tf('INPUTS.NAME')}
                name="name"
                rules={RULES}
            >
                <Input placeholder={tf('INPUTS.NAME_PLACEHOLDER')} />
            </Form.Item>

            <Form.Item
                label={tf('INPUTS.LAST_NAME')}
                name="lastName"
                rules={RULES}
            >
                <Input placeholder={tf('INPUTS.LAST_NAME_PLACEHOLDER')} />
            </Form.Item>

            <Country />

            <PhoneNumber />

            <Gender />

            <Form.Item
                label={tf('INPUTS.EMAIL')}
                name="email"
                rules={RULES_EMAIL}
            >
                <Input placeholder={tf('INPUTS.EMAIL_PLACEHOLDER')} />
            </Form.Item>

            <Form.Item
                label={tf('INPUTS.PASSWORD')}
                name="password"
                rules={RULES}
            >
                <Input.Password
                    placeholder={tf('INPUTS.PASSWORD_PLACEHOLDER')}
                    visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
                />
            </Form.Item>

            <TermsAndConditions form={form} />

            <Divider />

            <Button
                block
                type="primary"
                loading={loading}
                htmlType="submit"
            >
                {t('SIGN_UP')}
            </Button>

        </Form>
    );
};

export default SignupForm;
