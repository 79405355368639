import {
    Tag, Space, Typography,
} from 'antd';
import { ICategory } from '../../interfaces';

interface Props {
    title: string,
    summary: string,
    categoryID: ICategory,
}

const { Text } = Typography;

const Summary = ({ title, summary, categoryID }: Props) => {
    const language = localStorage.getItem('i18nextLng');
    const keyLanguage = language?.split('-')[0].toUpperCase();
    const category = keyLanguage ? categoryID[keyLanguage as 'EN' | 'ES' | 'PT'] : categoryID.ES;

    return (
        <Space direction="vertical" size={16}>
            <Space direction="vertical" size={6}>
                <Text strong>
                    {title}
                </Text>
                <Tag color="blue">
                    {category}
                </Tag>
            </Space>
            <div className="card__summary">
                <Text>
                    {summary}
                </Text>
            </div>
        </Space>
    );
};

export default Summary;
