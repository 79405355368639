import moment from 'moment';
import { useState } from 'react';
import { List, Space, Typography } from 'antd';
import { IComment, IResponse } from '../../interfaces';
import Author from './Author';
import Actions from './Actions';
import Response from './Response';
import { IContext } from '../../components/Community/context/community.interface';
import PostComment from './Post';

import 'moment/locale/es';
import 'moment/locale/pt-br';

const { Text } = Typography;

const Comment = ({
    comment,
    props,
}: { comment: IComment, props: IContext }) => {
    const [showReply, setShowReply] = useState<boolean>(false);

    const language = localStorage.getItem('i18nextLng');
    const keyLanguage = language?.split('-')[0] || 'es';

    const CustomEmptyText = () => null;

    const {
        likeComment,
        addResponse,
    } = props;

    return (
        <div className="card__comments--comment">
            <Author
                image={comment.userID?.image}
                name={`${comment.userID?.name} ${comment.userID?.lastName}`}
            />
            <div style={{ paddingLeft: 40 }}>
                <Space direction="vertical" size={0}>
                    <Text>
                        {comment.content}
                    </Text>
                    <Text className="card__date">
                        {
                            moment(comment.createdAt).locale(keyLanguage as string || 'es').fromNow()
                        }
                    </Text>
                </Space>

                <Actions
                    comments={comment.responses?.length || 0}
                    likes={comment?.likes?.length || 0}
                    like={() => likeComment(comment._id as string)}
                    commentSingularText="RESPONSE"
                    commentPluralText="RESPONSES"
                    setShowReply={() => setShowReply(!showReply)}
                />

                <div className="card__comments responses">
                    {
                        comment.responses.length > 0 && (
                            <List
                                locale={{ emptyText: <CustomEmptyText /> }}
                                dataSource={comment.responses}
                                renderItem={(response: IResponse) => (
                                    <List.Item
                                        key={response._id}
                                        className="full__width"
                                        style={{ padding: 8 }}
                                    >
                                        <Response
                                            comment={comment}
                                            response={response}
                                            props={props}
                                        />
                                    </List.Item>
                                )}
                            />
                        )
                    }
                </div>

                { showReply && <PostComment action={(values: any) => addResponse(comment._id, values)} /> }
            </div>
        </div>
    );
};

export default Comment;
