import {
    Space, Spin, Typography, Upload, notification,
} from 'antd';
import { useState } from 'react';
import { MdDriveFolderUpload } from 'react-icons/md';
import ImageCrop from '../../widgets/ImageCrop';
import commonAPI from '../../api/common';

const { Text } = Typography;

const UploadFile = ({
    value, onChange, type, name, form, t,
}: any) => {

    const [file, setFile] = useState<any>();
    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleUploadFile = async (file: any) => {
        setLoading(true);
        setVisible(false);
        setFile(null);
        const response = await commonAPI.uploadFileToS3(file, type, file.name);
        onChange(response.data);
        form.setFieldsValue({
            externalLink: response.data,
        });
        setLoading(false);
    };

    const handleUpload = (data: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            setFile(reader.result);
            setVisible(true);
        });
        reader.readAsDataURL(data.file.originFileObj);
        handleUploadFile(data.file.originFileObj);
    };

    return (
        <div className="upload-file">
            <Upload
                showUploadList={false}
                onChange={handleUpload}
            >
                {
                    value ? (
                        <Space
                            direction="vertical"
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <span>
                                {value.split('/')[value.split('/').length - 1]}
                            </span>
                            <Text strong>
                                {t('CHANGE_FILE_INSTRUCTION')}
                            </Text>
                        </Space>
                    ) : (
                        loading
                            ? (<Spin />)
                            : (
                                <Space direction="vertical" size={4} className="ant-upload-items">
                                    <MdDriveFolderUpload size={45} color="#1b4484" />
                                    <Text>
                                        {t('FILE_INSTRUCTION')}
                                    </Text>
                                </Space>
                            )
                    )
                }
            </Upload>
        </div>
    );

};

export default UploadFile;
