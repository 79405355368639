import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Welcome, Summary } from '../../components/Home';

const Home = () => {
    const { t } = useTranslation('home');

    const { user } = useSelector((state: any) => state.auth);

    return (
        <div className="home">
            { user.settings.isFirstTime && <Welcome t={t} />}
            <Summary t={t} />
        </div>
    );
};

export default Home;
