import {
    Row, Col, Form, Typography, Input, Button, notification, PageHeader,
} from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IAcademic } from '../../interfaces';
import { updateAcademicData } from '../../store/slices/auth';
import InstituteType from '../../widgets/InstituteType';
import Languages from '../../widgets/Languages';

interface Props {
    t: (key: string) => string;
}

const { Title } = Typography;
const { TextArea } = Input;

const Academic = ({ t }: Props) => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const { user, loading, error } = useSelector((state: any) => state.auth);
    const {
        academic: {
            institute, instituteType, jobPosition, designation, biography, curriculum, languages,
        },
    } = user;

    const [form] = Form.useForm();

    const dispatch = useDispatch<any>();

    const onSubmit = (values: IAcademic) => {
        const payload = {
            userID: user?._id,
            academic: {
                ...values,
            },
        };
        dispatch(updateAcademicData(payload));
        if (!loading) {
            if (error) {
                notification.error({
                    message: 'Error',
                    description: error,
                });
            } else {
                notification.success({
                    message: tf('RESPONSES.UPDATE_SUCCESS'),
                });
            }
        }
    };

    const RULES: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
    ];

    const RULES_URL: any = [
        { type: 'url', warningOnly: true, message: tf('RULES.INVALID_URL') },
        { type: 'string', min: 6, message: tf('RULES.MIN_LENGTH', { length: 6 }) },
    ];

    useEffect(() => {
        form.setFieldsValue({
            institute,
            instituteType,
            jobPosition,
            designation,
            biography,
            curriculum,
            languages,
        });
    }, []);

    return (
        <div className="profile__manage-tabs-forms">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                className="full__width"
            >
                <PageHeader
                    title={(
                        <Title level={4} style={{ marginBottom: 0 }}>
                            {t('PERSONAL.ACADEMIC_DATA')}
                        </Title>
                    )}
                    extra={(
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {tf('ACTIONS.UPDATE')}
                        </Button>
                    )}
                />
                <Row className="full__size" gutter={[24, 28]}>
                    <Col xs={24} xxl={12} className="col-left">
                        <Form.Item
                            label={tf('INPUTS.ACADEMIC.INSTITUTE')}
                            name="institute"
                            rules={RULES}
                        >
                            <Input placeholder={tf('INPUTS.ACADEMIC.INSTITUTE_PLACEHOLDER')} />
                        </Form.Item>

                        <InstituteType />

                        <Form.Item
                            label={tf('INPUTS.ACADEMIC.DESIGNATION')}
                            name="designation"
                            rules={RULES}
                        >
                            <Input placeholder={tf('INPUTS.ACADEMIC.DESIGNATION_PLACEHOLDER')} />
                        </Form.Item>

                        <Form.Item
                            label={tf('INPUTS.ACADEMIC.JOB_POSITION')}
                            name="jobPosition"
                            rules={RULES}
                        >
                            <Input placeholder={tf('INPUTS.ACADEMIC.JOB_POSITION_PLACEHOLDER')} />
                        </Form.Item>

                    </Col>
                    <Col xs={24} xxl={12} className="col-right">

                        <Form.Item
                            label={tf('INPUTS.ACADEMIC.CURRICULUM')}
                            name="curriculum"
                            rules={RULES_URL}
                        >
                            <Input
                                addonBefore="https://"
                                placeholder={tf('INPUTS.ACADEMIC.CURRICULUM_PLACEHOLDER')}
                            />
                        </Form.Item>

                        <Form.Item
                            label={tf('INPUTS.ACADEMIC.BIOGRAPHY')}
                            name="biography"
                            rules={RULES}
                        >
                            <TextArea
                                placeholder={tf('INPUTS.ACADEMIC.BIOGRAPHY_PLACEHOLDER')}
                                maxLength={300}
                                showCount
                            />
                        </Form.Item>

                        <Languages />

                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default Academic;
