import { IUser } from '../../../../interfaces';
import { IUsersContext } from './users.interface';

export const initialContext: IUsersContext = {
    users: [] as IUser[],
    userSelected: null,
    loading: false,
    total: 0,
    searchParams: {},
    paginator: {
        limit: 10,
        page: 1,
    },
    errors: [],
    onPageChange: () => {},
    fetchUsers: () => {},
    fetchUser: () => {},
    handleSearch: () => {},
};
