import {
    Form, Input, Divider, Button, notification,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { statusResetPassword } from '../../store/slices/auth';

import userAPI from '../../api/user';

const ResetPasswordForm = () => {
    const { t } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const [form] = Form.useForm();

    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
    const [repeatPasswordVisible, setRepeatPasswordVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [params] = useSearchParams();
    const token = params.get('token');
    const dispatch = useDispatch<any>();

    const onSubmit = (values: any) => {
        setLoading(true);
        const { password } = values;

        if (token) {
            userAPI.resetPassword(token, password)
                .then(() => {
                    dispatch(statusResetPassword('success'));
                    form.resetFields();
                })
                .catch((error) => {
                    console.log(error?.response?.data);
                    dispatch(statusResetPassword('error'));
                })
                .finally(() => setLoading(false));
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
        >
            <Form.Item
                label={t('INPUTS.PASSWORD')}
                name="password"
                rules={[
                    { required: true, message: t('RULES.REQUIRED_FIELD') },
                ]}
            >
                <Input.Password
                    placeholder={t('INPUTS.PASSWORD_PLACEHOLDER')}
                    visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
                />
            </Form.Item>
            <Form.Item
                label={t('INPUTS.REPEAT_PASSWORD')}
                name="repeatPassword"
                dependencies={['password']}
                rules={[
                    { required: true },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(t('RESPONSES.NOT_SAME_PASSWORD')));
                        },
                    }),
                ]}
            >
                <Input.Password
                    placeholder={t('INPUTS.PASSWORD_PLACEHOLDER')}
                    visibilityToggle={{ visible: repeatPasswordVisible, onVisibleChange: setRepeatPasswordVisible }}
                />
            </Form.Item>

            <Divider />

            <Button
                block
                type="primary"
                loading={loading}
                htmlType="submit"
                disabled={!token}
            >
                {t('ACTIONS.SET_NEW_PASSWORD')}
            </Button>
        </Form>
    );
};

export default ResetPasswordForm;
