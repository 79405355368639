import {
    Avatar, Form, List, Space, Typography,
} from 'antd';
import { MdDelete } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import SearchUser from '../../components/SearchUser';
import { IUser } from '../../interfaces';

interface Props {
    label: string;
    members: IUser[];
    setMembers: (members: IUser[]) => void;
}

const { Text } = Typography;

const Interviewers = ({
    label,
    members,
    setMembers,
}: Props) => {
    const { t: ti } = useTranslation('forms', { keyPrefix: 'FORMS.INPUTS' });

    const handleSelect = (item: IUser) => {
        setMembers([
            ...members,
            { ...item },
        ]);
    };

    const handleRemove = (member: IUser) => {
        const newMembers = members.filter((item: IUser) => item !== member);
        setMembers(newMembers);
    };

    return (
        <div>
            <Form.Item
                label={
                    `${ti(label)} (${members?.length || 0})`
                }
            >
                <Space className="full__width" size={8} direction="vertical">
                    <SearchUser
                        handleSelect={handleSelect}
                    />
                </Space>
            </Form.Item>

            { members?.length > 0 && (
                <List
                    dataSource={members}
                    renderItem={(item: IUser, index: number) => (
                        <List.Item
                            key={index}
                            actions={[
                                <MdDelete
                                    size={28}
                                    className="table__actions-icon delete"
                                    onClick={() => handleRemove(item)}
                                />,
                            ]}
                        >
                            <List.Item.Meta
                                avatar={(
                                    <Avatar
                                        size={28}
                                        style={{
                                            backgroundColor: '#1b4484',
                                            fontSize: 12,
                                        }}
                                    >
                                        {item.name.charAt(0).toUpperCase()}
                                    </Avatar>
                                )}
                                title={(
                                    <Text strong>
                                        {`${item.name} ${item.lastName}`}
                                    </Text>
                                )}
                            />
                        </List.Item>
                    )}
                />
            )}
        </div>
    );
};

export default Interviewers;
