/* eslint-disable no-unused-expressions */
import { createContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IAxiosError, IAxiosResponse, IUser } from '../../../../interfaces';
import usersAPI from '../../../../api/users';
import { IUsersContext } from './users.interface';
import { initialContext } from './users.initial';

interface Props {
    children: React.ReactNode;
}

export const UsersContext = createContext<IUsersContext>(initialContext);

export const UsersProvider = ({ children }: Props) => {
    const [users, setUsers] = useState<IUser[]>(initialContext.users);
    const [userSelected, setUserSelected] = useState<IUser | null>(initialContext.userSelected);
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [errors, setErrors] = useState<string[]>(initialContext.errors);
    const [total, setTotal] = useState<number>(initialContext.total);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paginator, setPaginator] = useState(initialContext.paginator);

    const search = searchParams.get('search') || '';

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({
            page,
            limit: pageSize,
        });
    };

    const getParams = () => {
        const params: any = { };
        search && (params.search = search);
        return params;
    };

    const handleSearch = (search: string) => {
        setSearchParams({ ...getParams(), search });
        setPaginator(initialContext.paginator);
    };

    const fetchUsers = async () => {
        setLoading(true);
        setErrors([]);

        const params: any = {
            ...getParams(),
            ...paginator,
            role: 'SIGNATORY',
        };

        usersAPI.getUsers(params)
            .then((response: Partial<IAxiosResponse>) => {
                const { data, meta } = response;
                setUsers(data);
                setTotal(meta?.itemCount || 0);
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setLoading(false));
    };

    const fetchUser = async (id: string) => {
        setLoading(true);
        setErrors([]);

        usersAPI.getUser(id)
            .then((response: Partial<IAxiosResponse>) => {
                const { data } = response;
                setUserSelected(data);
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setLoading(false));

    };

    return (
        <UsersContext.Provider
            value={{
                users,
                userSelected,
                loading,
                total,
                searchParams,
                paginator,
                errors,
                onPageChange,
                fetchUsers,
                fetchUser,
                handleSearch,
            }}
        >
            {children}
        </UsersContext.Provider>
    );

};
