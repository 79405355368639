import {
    Button, Form, Input, Space, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
    handleNewUser: (name: string, lastName: string) => void;
}

const { Title } = Typography;

const Collaborator = ({ handleNewUser }: Props) => {
    const { t } = useTranslation('forms', { keyPrefix: 'FORMS' });
    const [form] = Form.useForm();

    const onSubmit = (values: any) => {
        handleNewUser(values.name, values.lastName);
        form.resetFields();
    };

    const RULES: any = [
        { required: true, message: t('RULES.REQUIRED_FIELD') },
    ];

    return (
        <Form
            form={form}
            layout="vertical"
            name="add-collaborator"
            onFinish={onSubmit}
        >
            <Space direction="vertical" size={16} className="full__width">
                <Title level={4}>
                    {t('ADD_NEW_COLLABORATOR')}
                </Title>

                <div>
                    <Form.Item
                        label={t('INPUTS.NAME')}
                        name="name"
                        rules={RULES}
                    >
                        <Input placeholder={t('INPUTS.NAME_PLACEHOLDER')} />
                    </Form.Item>
                    <Form.Item
                        label={t('INPUTS.LAST_NAME')}
                        name="lastName"
                        rules={RULES}
                    >
                        <Input placeholder={t('INPUTS.LAST_NAME_PLACEHOLDER')} />
                    </Form.Item>
                    <Button
                        block
                        type="primary"
                        htmlType="submit"
                    >
                        {t('ACTIONS.ADD')}
                    </Button>
                </div>

            </Space>
        </Form>
    );
};

export default Collaborator;
