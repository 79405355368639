import { useSearchParams } from 'react-router-dom';
import { Users } from '../../../components/Admin/Users';

const UsersScreen = () => {
    const [searchParams] = useSearchParams();

    return (
        <div className="articles__edit">
            <Users params={searchParams} />
        </div>
    );
};

export default UsersScreen;
