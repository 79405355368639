import { useSearchParams } from 'react-router-dom';
import { Webinars } from '../../../components/Admin/Webinars';

const WebinarsScreen = () => {
    const [searchParams] = useSearchParams();

    return (
        <div className="articles__edit">
            <Webinars params={searchParams} />
        </div>
    );
};

export default WebinarsScreen;
