/* eslint-disable react/no-danger */
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
    Divider,
    Drawer,
    Space,
    Tag,
    Typography,
    Image,
} from 'antd';
import { IArticle } from '../../interfaces';
import { RECTORAL_BOARD_SITE } from '../../config';
import Author from '../CardArticle/Author';
import DrawerHeader from '../Drawers/Header';

interface Props {
    article: IArticle;
    open: boolean;
    onClose: () => void;
}

const { Text, Title } = Typography;

const DrawerArticle = ({
    article,
    open,
    onClose,
}: Props) => {
    const { t } = useTranslation('admin');

    const language = localStorage.getItem('i18nextLng');
    const keyLanguage = language?.split('-')[0];

    const date = moment(article.createdAt).locale(keyLanguage as string).format('ddd D MMM YYYY');

    const category = keyLanguage ? article.categoryID[keyLanguage.toUpperCase() as 'EN' | 'ES' | 'PT'] : article.categoryID.ES;

    const viewArticle = () => window.open(`${RECTORAL_BOARD_SITE}/ktdra-channel/${article._id}`, '_blank');

    return (
        <Drawer
            open={open}
            onClose={() => {}}
            width={650}
            headerStyle={{ display: 'none' }}
        >
            <Space direction="vertical" size={24}>
                <DrawerHeader
                    date={date}
                    action={viewArticle}
                    onClose={onClose}
                />
                <Space direction="vertical" size={12}>
                    <Tag color="blue">
                        {category}
                    </Tag>
                    <Title level={5} className="none__margin--bottom">
                        {article.title}
                    </Title>
                </Space>
                <Author
                    image={article.authorID?.image}
                    name={`${article.authorID?.name} ${article.authorID?.lastName}`}
                    country={article.authorID?.country}
                    membership={article.authorID?.membership}
                    academic={article.authorID?.academic}
                />
                <Text>
                    {article.summary}
                </Text>
                <Text>
                    <div dangerouslySetInnerHTML={{ __html: article.content }} />
                </Text>

                <Divider
                    style={{ margin: 0 }}
                />

                {
                    article.image.original && (
                        <Image
                            width={100}
                            src={article.image.original}
                            previewPrefixCls="article__preview"
                            preview={false}
                        />
                    )
                }
            </Space>
        </Drawer>
    );
};

export default DrawerArticle;
