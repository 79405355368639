import { createContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    IAxiosError, IAxiosResponse, ICreateDocument, IDocument, IUpdateDocument,
} from '../../../interfaces';
import libraryAPI from '../../../api/library';

interface Props {
    children: React.ReactNode;
}

interface ILibraryContext {
    documents: IDocument[];
    loading: boolean;
    actionLoading: boolean;
    total: number;
    searchParams: any;
    paginator: {
        limit: number;
        page: number;
    },
    errors: string[];
    onPageChange: (page: number, pageSize: number) => void,
    fetchDocuments: () => void,
    addDocument: (payload: ICreateDocument) => void,
    updateDocument: (documentID: string, payload: IUpdateDocument) => void,
    deleteDocument: (documentID: string) => void,
}

const initialContext = {
    documents: [] as IDocument[],
    loading: false,
    actionLoading: false,
    total: 0,
    searchParams: {},
    paginator: {
        limit: 5,
        page: 1,
    },
    errors: [],
    onPageChange: () => {},
    fetchDocuments: () => {},
    addDocument: () => {},
    updateDocument: () => {},
    deleteDocument: () => {},
};

export const LibraryContext = createContext<ILibraryContext>(initialContext);

export const LibraryProvider = ({ children }: Props) => {
    const [documents, setDocuments] = useState<IDocument[]>(initialContext.documents);
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [errors, setErrors] = useState<string[]>(initialContext.errors);
    const [actionLoading, setActionLoading] = useState<boolean>(initialContext.actionLoading);
    const [total, setTotal] = useState<number>(initialContext.total);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paginator, setPaginator] = useState(initialContext.paginator);

    const { user } = useSelector((state: any) => state.auth);

    const navigate = useNavigate();

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({
            page,
            limit: pageSize,
        });
    };

    const fetchDocuments = async () => {
        setLoading(true);
        setErrors([]);
        const params: any = { ...paginator, userID: user._id };

        libraryAPI.getDocuments(params)
            .then((response: Partial<IAxiosResponse>) => {
                const { data, meta } = response;
                setDocuments(data);
                setTotal(meta?.itemCount || 0);
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setLoading(false));
    };
    const addDocument = async (payload: ICreateDocument) => {
        setActionLoading(true);
        setErrors([]);
        libraryAPI.createDocument(payload)
            .then((response: Partial<IAxiosResponse>) => {
                const { data } = response;
                setDocuments([data, ...documents]);
                setTotal(total + 1);
                navigate('/my-library');
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setActionLoading(false));
    };
    const updateDocument = async (documentID: string, payload: IUpdateDocument) => {
        setActionLoading(true);
        setErrors([]);
        libraryAPI.updateDocument(documentID, payload)
            .then((response: Partial<IAxiosResponse>) => {
                const { data } = response;
                setDocuments(documents.map((document: IDocument) => {
                    if (document._id === data._id) return data;
                    return document;
                }));
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setActionLoading(false));
    };

    const deleteDocument = async (documentID: string) => {
        setActionLoading(true);
        setErrors([]);
        libraryAPI.deleteDocument(documentID)
            .then((response: Partial<IAxiosResponse>) => {
                const { data } = response;
                setDocuments(documents.filter((document: IDocument) => document._id !== data._id));
                setTotal(total - 1);
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setActionLoading(false));
        setActionLoading(false);
    };

    return (
        <LibraryContext.Provider
            value={{
                documents,
                loading,
                actionLoading,
                total,
                searchParams,
                paginator,
                errors,
                onPageChange,
                fetchDocuments,
                addDocument,
                updateDocument,
                deleteDocument,
            }}
        >
            {children}
        </LibraryContext.Provider>
    );
};
