import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import {
    Avatar, Input, List, Space, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import usersAPI from '../../api/users';
import { IAxiosError, IAxiosResponse, IUser } from '../../interfaces';
import { getCountry } from '../../utils/countries';

const { Text } = Typography;
const { Search } = Input;

interface Props {
    handleSelect: (item: IUser) => void;
}

const SearchUser = ({ handleSelect }: Props) => {
    const { t, i18n } = useTranslation('admin');

    const [loading, setLoading] = useState<boolean>(false);
    const [users, setUsers] = useState<any[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [search, setSearch] = useState<string>('');

    const fetchUsers = (search?: string) => {
        setLoading(true);
        setErrors([]);

        const params: any = {
            role: 'SIGNATORY',
        };

        if (search && search !== '') params.search = search;

        usersAPI.getUsers(params)
            .then((response: Partial<IAxiosResponse>) => {
                const { data } = response;
                setUsers(data);
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setLoading(false));
    };

    const debounceFetch = useCallback<any>(debounce(fetchUsers, 700), []);

    const handleSearch = (value: string) => {
        if (!value) {
            setSearch(value);
            setUsers([]);
            return;
        }
        debounceFetch(value);
    };

    return (
        <Space direction="vertical" size={0} className="full__width">
            <Search
                allowClear
                enterButton
                placeholder={t('TABLE.SEARCH_MEMBER')}
                onSearch={handleSearch}
                defaultValue={search}
                disabled={loading}
            />

            <div className="services__container--list">
                <List
                    dataSource={users}
                    loading={loading}
                    size="small"
                    locale={{
                        emptyText: (
                            <Text>
                                {t('TABLE.EMPTY')}
                            </Text>
                        ),
                    }}
                    renderItem={(item: IUser) => (
                        <List.Item
                            key={item._id}
                            className="full__width services__container--list-item"
                            extra={[
                                <Avatar
                                    src={getCountry(item.country)?.flag || ''}
                                    shape="square"
                                    style={{
                                        width: 32,
                                        height: 20,
                                    }}
                                />,
                            ]}
                            onClick={() => handleSelect(item)}
                        >
                            <List.Item.Meta
                                title={(
                                    <Text>
                                        {`${item.name} ${item.lastName}`}
                                    </Text>
                                )}
                                description={(
                                    <Text>
                                        {item.email}
                                    </Text>
                                )}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </Space>
    );
};

export default SearchUser;
