import { Tabs } from 'antd';
import type { TabPaneProps } from 'antd/lib/tabs';

import Personal from './Personal';
import Academic from './Academic';
import Contact from './Contact';
import Account from './Account';
import Membership from './Membership';
import WelcomePhrase from './WelcomePhrase';

type TabPosition = 'top' | 'right' | 'bottom' | 'left';

interface Props {
    t: (key: string) => string;
}

const ManageTabs = ({ t }: Props) => {
    const mode: TabPosition = 'top';

    const items: TabPaneProps[] = [
        {
            tab: t('TABS.PERSONAL'),
            tabKey: '1',
            children: <Personal t={t} />,
        },
        {
            tab: t('TABS.ACADEMIC'),
            tabKey: '2',
            children: <Academic t={t} />,
        },
        {
            tab: t('TABS.CONTACT'),
            tabKey: '3',
            children: <Contact t={t} />,
        },
        {
            tab: t('TABS.MEMBERSHIP'),
            tabKey: '4',
            children: <Membership t={t} />,
        },
        {
            tab: t('TABS.WELCOME_PHRASE'),
            tabKey: '5',
            children: <WelcomePhrase t={t} />,
        },
    ];

    return (
        <Tabs
            defaultActiveKey="0"
            tabPosition={mode}
            className="profile__manage-tabs"
            type="card"
            items={
                items.map((item, index) => {
                    const { tab, children } = item;
                    const id = String(index);
                    return {
                        label: tab,
                        key: id,
                        children,
                    };
                })
            }
        />
    );
};

export default ManageTabs;
