import {
    Typography, Form, Row, Col, Input, Button, notification, PageHeader,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MEMBERSHIPS } from '../../constants/memberships';
import { IMembership } from '../../interfaces';
import { updateMembershipData } from '../../store/slices/auth';
import CardMembership from '../../widgets/CardMembership';

interface Props {
    t: (key: string) => string;
}

const { Title } = Typography;

const Membership = ({ t }: Props) => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const [form] = Form.useForm();
    const [membership, setMembership] = useState<IMembership>();

    const { user, loading, error } = useSelector((state: any) => state.auth);

    const dispatch = useDispatch<any>();

    const onSubmit = (values: any) => {
        const category = { ...values };
        const userID = user?._id;
        const payload = { userID, category, membership };
        dispatch(updateMembershipData(payload));
        if (!loading) {
            if (error) {
                notification.error({
                    message: 'Error',
                    description: error,
                });
            } else {
                notification.success({
                    message: tf('RESPONSES.UPDATE_SUCCESS'),
                });
            }
        }
    };

    useEffect(() => {
        form.setFieldsValue({
            membership: user.membership.keyName,
        });
        setMembership(
            MEMBERSHIPS.find((membership: IMembership) => membership.keyName === user.membership.keyName),
        );
    }, []);

    const onChange = (membership: IMembership) => {
        setMembership(membership);
        form.setFieldsValue({ membership: membership.keyName });
    };

    return (
        <div className="profile__manage-tabs-forms">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                className="full__width"
            >
                <PageHeader
                    title={(
                        <Title level={4} style={{ marginBottom: 0 }}>
                            {t('PERSONAL.MEMBERSHIP_DATA')}
                        </Title>
                    )}
                    extra={(
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {tf('ACTIONS.UPDATE')}
                        </Button>
                    )}
                />
                <Form.Item hidden name="membership">
                    <Input />
                </Form.Item>
                <Row className="full__size" gutter={16}>
                    {
                        MEMBERSHIPS.map((membership: IMembership, index: number) => (
                            <Col xs={24} xxl={8} key={index}>
                                <CardMembership
                                    form={form}
                                    membership={membership}
                                    onChange={onChange}
                                />
                            </Col>
                        ))
                    }
                </Row>
            </Form>
        </div>
    );
};

export default Membership;
