import {
    EContributionCategory,
    EContributionStatus,
} from '../enums/contributions.enum';

export const CContributionTypes: string[] = [
    EContributionCategory.NETWORK,
    EContributionCategory.IES,
    EContributionCategory.GOVERNMENT,
];

export const CContributionStatus: string[] = [
    EContributionStatus.ACTIVE,
    EContributionStatus.FINISHED,
];
