import { Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import LogoRectoral from '../../widgets/Logo';

const { Title, Paragraph } = Typography;

const Header = () => {
    const { t } = useTranslation('onboarding');
    return (
        <Space direction="vertical" size={8}>

            <LogoRectoral
                size={{ width: 200 }}
                type="light"
                direction="horizontal"
            />

            <Title
                level={2}
                className="center"
                style={{ fontWeight: 700 }}
            >
                {t('WELCOME_TO_RECTORAL_BOARD')}
            </Title>
            <Paragraph className="center text">
                {t('ONBOARDING_MESSAGE')}
            </Paragraph>
        </Space>
    );
};

export default Header;
