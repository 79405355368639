import { Space, Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Image, IImageProps } from '@fluentui/react/lib/Image';
import IMAGES from '../../constants/images';

const { Title, Paragraph } = Typography;

const Completed = () => {
    const { t } = useTranslation('onboarding');
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const navigate = useNavigate();

    const imageProps: Partial<IImageProps> = {
        src: IMAGES.illustrations.success,
        alt: 'success register',
        loading: 'lazy',
        width: 230,
        height: 230,
        styles: {
            root: { margin: '0 auto' },
        },
    };

    return (
        <Space direction="vertical" size={24} className="full__width">
            <Title
                level={3}
                className="center"
                style={{ fontWeight: 600 }}
            >
                {t('COMPLETED.TITLE')}
            </Title>

            <Paragraph className="center">
                {t('COMPLETED.HELP')}
            </Paragraph>

            <Image {...imageProps} />

            <Button
                block
                type="primary"
                onClick={() => navigate('/')}
            >
                {tf('ACTIONS.FINISH')}
            </Button>
        </Space>
    );
};

export default Completed;
