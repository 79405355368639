import axios from 'axios';
import { DASHBOARD_API } from '../config';

const getWebinars = (query: string) => {
    const params = new URLSearchParams(query).toString();
    const url = `${DASHBOARD_API}/webinars?${params}`;
    const response = axios.get(url);
    return response;
};

const getWebinarsCategories = () => {
    const url = `${DASHBOARD_API}/webinars/categories`;
    const response = axios.get(url);
    return response;
};

const createWebinar = (payload: any) => {
    const url = `${DASHBOARD_API}/webinars`;
    const response = axios.post(url, payload);
    return response;
};

export default {
    getWebinars,
    getWebinarsCategories,
    createWebinar,
};
