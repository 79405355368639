import moment from 'moment';
import {
    Button, Col, Row, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { IoClose } from 'react-icons/io5';
import { MdOpenInNew } from 'react-icons/md';

interface Props {
    date: string;
    action: () => void;
    onClose: () => void;
}

const { Text } = Typography;

const DrawerHeader = ({
    date,
    action,
    onClose,
}: Props) => {
    const { t } = useTranslation('admin');

    const language = localStorage.getItem('i18nextLng');
    const keyLanguage = language?.split('-')[0];

    const formatDate = moment(date).locale(keyLanguage as string).format('ddd D MMM YYYY');

    return (
        <Row justify="space-between" align="middle">
            <Col>
                <Text className="card__date" style={{ fontSize: 12 }}>
                    {`${t('TABLE.POSTED_AT')} ${formatDate}`}
                </Text>
            </Col>
            <Col>
                <Row justify="end" align="middle" gutter={[0, 8]}>
                    <Col>
                        <Button
                            type="text"
                            icon={<MdOpenInNew />}
                            onClick={action}
                        />
                    </Col>
                    <Col>
                        <Button
                            type="text"
                            icon={<IoClose />}
                            onClick={onClose}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default DrawerHeader;
