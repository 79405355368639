import { Form, Select, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const Gender = () => {
    const { t } = useTranslation('forms', { keyPrefix: 'FORMS.INPUTS' });

    const GENDER_TYPES = [
        {
            code: 'HIDDEN',
            name: t('GENDER_TYPES.HIDDEN'),
        },
        {
            code: 'MALE',
            name: t('GENDER_TYPES.MALE'),
        },
        {
            code: 'FEMALE',
            name: t('GENDER_TYPES.FEMALE'),
        },
    ];

    return (
        <Form.Item
            label={t('GENDER')}
            name="gender"
            initialValue="HIDDEN"
        >
            <Select placeholder={t('GENDER_PLACEHOLDER')}>
                {
                    GENDER_TYPES.map((gender, index) => (
                        <Option value={gender.code} key={index}>
                            {gender.name}
                        </Option>
                    ))
                }
            </Select>
        </Form.Item>
    );
};

export default Gender;
