import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../utils/countries';

const { Option } = Select;

const Languages = () => {
    const { t } = useTranslation('forms', { keyPrefix: 'FORMS.INPUTS' });
    const { t: tr } = useTranslation('forms', { keyPrefix: 'FORMS.RULES' });

    const RULES = [{ required: true, message: tr('REQUIRED_FIELD') }];

    return (
        <Form.Item
            label={t('LANGUAGES')}
            name="languages"
            rules={RULES}
        >
            <Select
                placeholder={t('LANGUAGES_PLACEHOLDER')}
                showSearch
                mode="multiple"
            >
                {
                    LANGUAGES.map((language, index) => (
                        <Option
                            value={language.iso639_2}
                            key={index}
                        >
                            {language.name}
                        </Option>
                    ))
                }
            </Select>
        </Form.Item>
    );
};

export default Languages;
