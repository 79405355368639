import { IUser } from '../../../../interfaces';
import { IContext } from './webinars.interface';

export const initialContext: IContext = {
    data: [] as any[],
    itemSelected: null,
    loading: false,
    actionLoading: false,
    total: 0,
    searchParams: {},
    paginator: {
        limit: 10,
        page: 1,
    },
    errors: [],
    categories: [] as any[],
    onPageChange: () => {},
    fetchData: () => {},
    fetchItem: () => {},
    fetchCategories: () => {},
    handleSearch: () => {},
    handleCategory: () => {},
    setItemSelected: () => {},
    addItem: () => {},
};
