import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import { QuotesProvider } from '../../providers';
import { QuotesScreen } from '../../screens/Admin/Quotes';

export const QuotesRouter: FC = () => (
    <Routes>
        <Route
            path=""
            element={(
                <QuotesProvider>
                    <QuotesScreen />
                </QuotesProvider>
            )}
        />
    </Routes>
);
