import axios from 'axios';
import { DASHBOARD_API } from '../config';

const getContributions = (query: any, userID: string) => {
    const urlParams = new URLSearchParams(query).toString();
    const url = `${DASHBOARD_API}/contributions/${userID}?${urlParams}`;
    const response = axios.get(url);
    return response;
};

const getAllContributions = (query: any) => {
    const urlParams = new URLSearchParams(query).toString();
    const url = `${DASHBOARD_API}/contributions?${urlParams}`;
    const response = axios.get(url);
    return response;
};

const getContribution = (contributionID: string) => {
    const url = `${DASHBOARD_API}/contributions/detail/${contributionID}`;
    const response = axios.get(url);
    return response;
};

const createContribution = (payload: any) => {
    const url = `${DASHBOARD_API}/contributions`;
    const response = axios.post(url, payload);
    return response;
};

const updateContribution = (contributionID: string, payload: any) => {
    const url = `${DASHBOARD_API}/contributions/${contributionID}`;
    const response = axios.put(url, payload);
    return response;
};

const deleteContribution = (contributionID: string) => {
    const url = `${DASHBOARD_API}/contributions/${contributionID}`;
    const response = axios.delete(url);
    return response;
};

export default {
    getContributions,
    getAllContributions,
    getContribution,
    createContribution,
    updateContribution,
    deleteContribution,
};
