/* global JSX React */
import { Modal } from 'antd';
import { IoMdCloseCircle } from 'react-icons/io';
import IMAGES from '../../constants/images';

import styles from './styles.module.scss';

interface Props {
    visible: boolean;
    children: React.ReactNode;
    onCancel: () => void;
}

const ModalWrapper = ({ visible, children, onCancel }: Props): JSX.Element => {
    const closableIcon = () => (
        <IoMdCloseCircle
            size={28}
            color="#1b4484"
            style={{ marginTop: 12 }}
            onClick={() => onCancel()}
        />
    );

    return (
        <Modal
            centered
            open={visible}
            onCancel={onCancel}
            closeIcon={closableIcon()}
            className={styles.modal}
            footer={null}
        >
            <div
                className="trama"
                style={{
                    backgroundImage: `url(${IMAGES.illustrations.trama})`,
                }}
            />
            <div className={styles.body}>
                {children}
            </div>
        </Modal>
    );
};

export default ModalWrapper;
