import { useSearchParams } from 'react-router-dom';
import { Reviews } from '../../../components/Admin/Reviews';

const ReviewsScreen = () => {
    const [searchParams] = useSearchParams();

    return (
        <div className="articles__edit">
            <Reviews params={searchParams} />
        </div>
    );
};

export default ReviewsScreen;
