import moment from 'moment';
import { List, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { IContext } from '../context/study-groups.interface';

interface IColumn {
    title: string;
    dataIndex: string;
    key: string;
    align: 'center' | 'left' | 'right' | undefined;
    render: (value: any, object?: any) => React.ReactNode;
}

const { Text } = Typography;

const TableStudyGroups = ({ ...props }: IContext) => {
    const { t } = useTranslation('admin', { keyPrefix: 'TABLE' });

    const {
        loading, total, studyGroups, onPageChange, paginator, setStudyGroup,
    } = props;

    const COLUMNS: ColumnsType<IColumn> = [
        {
            title: t('CREATED_AT'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center' as 'center',
            render: (createdAt: string) => (
                <Text className="table__summary">
                    {moment(createdAt).format('DD MMM HH:mm')}
                </Text>
            ),
        },
        {
            title: t('OWNER'),
            dataIndex: 'ownerID',
            key: 'ownerID',
            render: (ownerID: string, group: any) => (
                <List.Item.Meta
                    title={(
                        <Text className="table__summary" strong>
                            {`${group.ownerData?.name} ${group.ownerData?.lastName}`}
                        </Text>
                    )}
                    description={(
                        <Text className="table__summary">
                            {group.ownerData?.email}
                        </Text>
                    )}
                />
            ),
        },
        {
            title: t('GROUP_CODE'),
            dataIndex: 'code',
            key: 'code',
            align: 'center' as 'center',
            render: (code: string) => (
                <Text className="table__summary">
                    {code}
                </Text>
            ),
        },
        {
            title: t('GROUP_NAME'),
            dataIndex: 'name',
            key: 'name',
            align: 'center' as 'center',
            render: (name: string) => (
                <Text className="table__summary">
                    {name}
                </Text>
            ),
        },
        {
            title: t('MEMBERS'),
            dataIndex: 'members',
            key: 'members',
            align: 'center' as 'center',
            render: (members: string[], group: any) => (
                <Text
                    className="table__summary"
                    style={{
                        color: '#1b4484',
                        cursor: 'pointer',
                    }}
                    onClick={() => setStudyGroup(group)}
                >
                    {
                        `${members.length} ${t(members.length === 1 ? 'MEMBER' : 'MEMBERS')}`
                    }
                </Text>
            ),
        },
        {
            title: t('CODE_OTP'),
            dataIndex: 'codeOTP',
            key: 'codeOTP',
            align: 'center' as 'center',
            render: (codeOTP: number) => (
                <Text className="table__summary">
                    {codeOTP}
                </Text>
            ),
        },
        {
            title: t('VERIFIED'),
            dataIndex: 'isVerified',
            key: 'isVerified',
            align: 'center' as 'center',
            render: (isVerified: boolean) => (
                <Text
                    className="table__not-available"
                    style={{
                        color: isVerified ? '#52c41a' : '#8a8886',
                        borderColor: isVerified ? '#52c41a' : '#8a8886',
                    }}
                >
                    {t(isVerified ? 'YES' : 'NO')}
                </Text>
            ),
        },
    ];

    return (
        <Table
            className="table"
            bordered
            rowKey="_id"
            loading={loading}
            columns={COLUMNS}
            dataSource={studyGroups}
            scroll={{ x: 1500 }}
            pagination={{
                total,
                pageSize: paginator.limit,
                current: paginator.page,
                onChange: onPageChange,
                showSizeChanger: false,
                size: 'small',
            }}
        />
    );
};

export default TableStudyGroups;
