import {
    Col, Input, PageHeader, Row, Select, Space, Typography,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { QuotesContext } from './context/quotesContext';
import TableQuotes from './components/Table';
import { QUOTES_TYPES, QUOTES_STATUS } from '../../../constants/quotes.constants';
import { EQuotesType } from '../../../enums/quotes.enum';
import QuoteDetail from './components/Detail';

interface Props {
    params: any;
}

const { Search } = Input;
const { Option } = Select;

const Quotes = ({ params }: Props) => {
    const { t } = useTranslation('admin');

    const [header, setHeader] = useState<string>(EQuotesType.MENTORING);

    const {
        loading, total, paginator, quotes, quote, openDetail, onPageChange, fetchQuotes, searchParams,
        handleSearch, handleType, handleStatus, handleOpenDetail, handleQuote, changeStatusQuote,
    } = useContext(QuotesContext);

    const { limit, page } = paginator;
    const { search, type, status } = searchParams;

    const defaultSearch = params.get('search');
    const defaultType = params.get('type');
    const defaultStatus = params.get('status');

    useEffect(() => {
        fetchQuotes();
    }, [paginator, search, defaultSearch, type, defaultType, status, defaultStatus]);

    return (
        <div className="articles__edit">
            { openDetail && (
                <QuoteDetail
                    quote={quote}
                    header={header}
                    openDetail={openDetail}
                    handleOpenDetail={handleOpenDetail}
                />
            )}
            <PageHeader
                className="site-page-header-responsive"
                title={(
                    <Trans
                        t={t}
                        i18nKey="ADMIN_QUOTES"
                        components={{ span: <span style={{ fontWeight: 500, textTransform: 'capitalize' }} /> }}
                        values={{ type: header }}
                    />
                )}
                footer={(
                    <Space direction="vertical" size={16} className="full__width">
                        <Row className="full__width" gutter={[24, 32]}>
                            <Col xs={24} lg={16}>
                                <Search
                                    allowClear
                                    enterButton
                                    placeholder={t('TABLE.SEARCH_QUOTE')}
                                    onSearch={handleSearch}
                                    defaultValue={search || defaultSearch}
                                    disabled={loading}
                                />
                            </Col>
                            <Col xs={24} lg={4}>
                                <Select
                                    placeholder={t('TABLE.QUOTE_TYPE')}
                                    className="full__width"
                                    defaultValue={defaultType}
                                    onChange={(value) => {
                                        handleType(value);
                                        setHeader(value);
                                    }}
                                >
                                    {
                                        QUOTES_TYPES.map((type, index) => (
                                            <Option
                                                value={type}
                                                key={index}
                                            >
                                                {t(`TABLE.${type.toUpperCase()}`)}
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </Col>
                            <Col xs={24} lg={4}>
                                <Select
                                    placeholder={t('TABLE.QUOTE_STATUS')}
                                    className="full__width"
                                    defaultValue={defaultStatus}
                                    onChange={(value) => {
                                        handleStatus(value);
                                    }}
                                >
                                    {
                                        QUOTES_STATUS.map((status, index) => (
                                            <Option
                                                value={status}
                                                key={index}
                                            >
                                                {t(`TABLE.${status.toUpperCase()}`)}
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </Col>
                        </Row>
                        <TableQuotes
                            loading={loading}
                            total={total}
                            quotes={quotes}
                            onPageChange={onPageChange}
                            limit={limit}
                            page={page}
                            openDetail={openDetail}
                            handleOpenDetail={handleOpenDetail}
                            handleQuote={handleQuote}
                            changeStatusQuote={changeStatusQuote}
                        />
                    </Space>
                )}
            >
                <Typography.Paragraph>
                    {t('ADMIN_QUOTES_DESCRIPTION')}
                </Typography.Paragraph>
            </PageHeader>
        </div>
    );
};

export default Quotes;
