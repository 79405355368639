import { createContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import contributionsAPI from '../../../api/contributions';
import {
    IAxiosError, IAxiosResponse, IContribution, IContributionPayload,
} from '../../../interfaces';

interface Props {
    children: React.ReactNode;
}

interface IArticlesContext {
    contributions: IContribution[];
    loading: boolean;
    actionLoading: boolean;
    total: number;
    searchParams: any;
    paginator: {
        limit: number;
        page: number;
    },
    errors: string[];
    onPageChange: (page: number, pageSize: number) => void,
    fetchContributions: () => void,
    addContribution: (payload: any) => void,
    updateContribution: (contributionID: string, payload: any) => void,
    deleteContribution: (contributionID: string) => void,
}

const initialContext = {
    contributions: [] as IContribution[],
    loading: false,
    actionLoading: false,
    total: 0,
    searchParams: {},
    paginator: {
        limit: 5,
        page: 1,
    },
    errors: [],
    onPageChange: () => {},
    fetchContributions: () => {},
    addContribution: () => {},
    updateContribution: () => {},
    deleteContribution: () => {},
};

export const ContributionsContext = createContext<IArticlesContext>(initialContext);

export const ContributionsProvider = ({ children }: Props) => {

    const [contributions, setContributions] = useState<IContribution[]>(initialContext.contributions);
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [errors, setErrors] = useState<string[]>(initialContext.errors);
    const [actionLoading, setActionLoading] = useState<boolean>(initialContext.actionLoading);
    const [total, setTotal] = useState<number>(initialContext.total);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paginator, setPaginator] = useState(initialContext.paginator);

    const { user } = useSelector((state: any) => state.auth);

    const navigate = useNavigate();

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({
            page,
            limit: pageSize,
        });
    };

    const fetchContributions = () => {
        setLoading(true);
        setErrors([]);
        const params: any = { ...paginator, userID: user._id };

        contributionsAPI.getContributions(params, user._id)
            .then((response: Partial<IAxiosResponse>) => {
                const { data, meta } = response;
                setContributions(data);
                setTotal(meta?.itemCount || 0);
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setLoading(false));
    };

    const deleteContribution = (contributionID: string) => {
        setActionLoading(true);
        setErrors([]);
        contributionsAPI.deleteContribution(contributionID)
            .then((response: Partial<IAxiosResponse>) => {
                const { data } = response;
                setContributions(contributions.filter((contribution: IContribution) => contribution._id !== data._id));
                setTotal(total - 1);
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setActionLoading(false));
        setActionLoading(false);
    };

    const addContribution = (payload: IContributionPayload) => {
        setActionLoading(true);
        setErrors([]);
        contributionsAPI.createContribution(payload)
            .then((response: Partial<IAxiosResponse>) => {
                const { data } = response;
                setContributions([data, ...contributions]);
                setTotal(total + 1);
                navigate('/contributions');
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setActionLoading(false));
    };

    const updateContribution = (contributionID: string, payload: IContributionPayload) => {
        setActionLoading(true);
        setErrors([]);
        contributionsAPI.updateContribution(contributionID, payload)
            .then((response: Partial<IAxiosResponse>) => {
                const { data } = response;
                setContributions(contributions.map((contribution: IContribution) => {
                    if (contribution._id === data._id) return data;
                    return contribution;
                }));
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setActionLoading(false));
    };

    return (
        <ContributionsContext.Provider
            value={{
                contributions,
                loading,
                actionLoading,
                total,
                searchParams,
                paginator,
                errors,
                onPageChange,
                fetchContributions,
                addContribution,
                updateContribution,
                deleteContribution,
            }}
        >
            {children}
        </ContributionsContext.Provider>
    );
};
