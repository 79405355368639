import axios from 'axios';
import { Space } from 'antd';
import { Route, Routes } from 'react-router-dom';

import { BiLogOut } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { logout } from '../../store/slices/auth';

import Header from '../../components/Onboarding/Header';
import Academic from '../../components/Onboarding/Academic';
import Membership from '../../components/Onboarding/Membership';
import Completed from '../../components/Onboarding/Completed';
import IMAGES from '../../constants/images';
import Steps from '../../components/Onboarding/StepManager';

const Onboarding = () => {
    const { t } = useTranslation('onboarding');

    const dispatch = useDispatch<any>();

    const startLogout = () => {
        localStorage.clear();
        delete axios.defaults.headers.common.authorization;
        dispatch(logout());
    };

    return (
        <div className="onboarding">
            <div
                style={{ backgroundImage: `url(${IMAGES.illustrations.trama})` }}
                className="trama"
            />
            <div className="inner">
                <Header />
                <Routes>
                    <Route
                        path="*"
                        element={(
                            <Space direction="vertical" size={24} className="full__width">
                                <Steps />
                                <div className="section">
                                    <Routes>
                                        <Route path="academic-record" element={<Academic />} />
                                        <Route path="membership-register" element={<Membership />} />
                                        <Route path="completed" element={<Completed />} />
                                    </Routes>
                                </div>
                                <div className="logout" onClick={() => startLogout()}>
                                    <BiLogOut className="icon" size={20} />
                                    <span className="text">
                                        {t('LOGOUT')}
                                    </span>
                                </div>
                            </Space>
                        )}
                    />
                </Routes>
            </div>
        </div>
    );
};

export default Onboarding;
