import { useEffect, useState } from 'react';
import {
    Button, Card, Col, Form, Input, PageHeader, Row, Space, Switch, Typography, notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { IUser } from '../../../../interfaces';
import lucesAPI from '../../../../api/luces';

interface Props {
    t: (key: string) => string;
    userData: IUser | null;
}

const { Title } = Typography;

const LucesInfo = ({ t, userData }: Props) => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const RULES: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
    ];

    const transformObject = (values: any) => {
        const output: any = {
            isPartner: values.isPartner,
            isMentor: values.isMentor,
            signature: {},
            jobPosition: {},
            designation: values.designation,
        };

        Object.keys(values).forEach((key) => {
            if (key.startsWith('signature.')) {
                const language = key.split('.')[1];
                output.signature[language] = values[key];
            } else if (key.startsWith('jobPosition.')) {
                const language = key.split('.')[1];
                output.jobPosition[language] = values[key];
            }
        });

        return output;
    };

    const onSubmit = async (values: any) => {
        const payload = await transformObject(values);
        lucesAPI.updateLucesInfo(userData?._id as string, payload)
            .then(() => {
                userData!.luces = payload;
                notification.success({
                    message: tf('RESPONSES.UPDATE_SUCCESS'),
                });
            })
            .catch((error: any) => {
                const description = error?.response?.data?.message || error.message;
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        form.setFieldsValue({
            'signature.es': userData?.luces?.signature.es,
            'signature.en': userData?.luces?.signature.en,
            'signature.pt': userData?.luces?.signature.pt,
            'jobPosition.es': userData?.luces?.jobPosition.es,
            'jobPosition.en': userData?.luces?.jobPosition.en,
            'jobPosition.pt': userData?.luces?.jobPosition.pt,
            isPartner: userData?.luces?.isPartner,
            isMentor: userData?.role.includes('MENTOR'),
            designation: userData?.luces?.designation,
        });
    }, []);

    return (
        <div className="profile__manage-tabs-forms">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                className="full__width"
            >
                <PageHeader
                    title={(
                        <Title level={4} style={{ marginBottom: 0 }}>
                            {t('PERSONAL.LUCES')}
                        </Title>
                    )}
                    extra={(
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {tf('ACTIONS.UPDATE')}
                        </Button>
                    )}
                />
                <Row className="full__size" gutter={[24, 28]}>
                    <Col xs={24} xxl={12} className="col-left">
                        <Space direction="vertical" size={24} className="full__width">
                            <Card>
                                <Form.Item
                                    label={tf('INPUTS.LUCES.SIGNATURE_ES')}
                                    name="signature.es"
                                    rules={RULES}
                                >
                                    <Input placeholder={tf('INPUTS.LUCES.SIGNATURE_ES')} />
                                </Form.Item>
                                <Form.Item
                                    label={tf('INPUTS.LUCES.SIGNATURE_EN')}
                                    name="signature.en"
                                    rules={RULES}
                                >
                                    <Input placeholder={tf('INPUTS.LUCES.SIGNATURE_EN')} />
                                </Form.Item>
                                <Form.Item
                                    label={tf('INPUTS.LUCES.SIGNATURE_PT')}
                                    name="signature.pt"
                                    rules={RULES}
                                >
                                    <Input placeholder={tf('INPUTS.LUCES.SIGNATURE_PT')} />
                                </Form.Item>
                            </Card>
                            <Row className="full__size" gutter={[24, 28]}>
                                <Col span={12} className="col-left">
                                    <Card>
                                        <Form.Item
                                            label={tf('INPUTS.SET_AS_LUCES_PARTNER')}
                                            valuePropName="isPartner"
                                            name="isPartner"
                                        >
                                            <Switch defaultChecked={userData?.luces?.isPartner} />
                                        </Form.Item>
                                    </Card>
                                </Col>
                                <Col span={12} className="col-right">
                                    <Card>
                                        <Form.Item
                                            label={tf('INPUTS.SET_AS_LUCES_MENTOR')}
                                            valuePropName="isMentor"
                                            name="isMentor"
                                        >
                                            <Switch defaultChecked={userData?.role.includes('MENTOR')} />
                                        </Form.Item>
                                    </Card>
                                </Col>
                            </Row>
                        </Space>
                    </Col>
                    <Col xs={24} xxl={12} className="col-right">
                        <Space direction="vertical" size={24} className="full__width">
                            <Card>
                                <Form.Item
                                    label={tf('INPUTS.LUCES.JOB_POSITION_ES')}
                                    name="jobPosition.es"
                                    rules={RULES}
                                >
                                    <Input placeholder={tf('INPUTS.LUCES.JOB_POSITION_ES')} />
                                </Form.Item>
                                <Form.Item
                                    label={tf('INPUTS.LUCES.JOB_POSITION_EN')}
                                    name="jobPosition.en"
                                    rules={RULES}
                                >
                                    <Input placeholder={tf('INPUTS.LUCES.JOB_POSITION_EN')} />
                                </Form.Item>
                                <Form.Item
                                    label={tf('INPUTS.LUCES.JOB_POSITION_PT')}
                                    name="jobPosition.pt"
                                    rules={RULES}
                                >
                                    <Input placeholder={tf('INPUTS.LUCES.JOB_POSITION_PT')} />
                                </Form.Item>
                            </Card>
                            <Card>
                                <Form.Item
                                    label={tf('INPUTS.LUCES_DESIGNATION')}
                                    name="designation"
                                    rules={RULES}
                                >
                                    <Input placeholder={tf('INPUTS.LUCES_DESIGNATION')} />
                                </Form.Item>
                            </Card>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default LucesInfo;
