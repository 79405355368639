/* eslint-disable no-unused-expressions */
import { notification } from 'antd';
import { createContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IAxiosError, IAxiosResponse } from '../../../../interfaces';
import quotesAPI from '../../../../api/quotes';
import { EQuotesStatus, EQuotesType } from '../../../../enums/quotes.enum';

interface Props {
    children: React.ReactNode;
}

interface IQuotesContext {
    quotes: any[];
    quote: any;
    loading: boolean;
    loadingAction: boolean;
    total: number;
    searchParams: any;
    paginator: {
        limit: number;
        page: number;
    },
    errors: string[];
    openDetail: boolean;
    onPageChange: (page: number, pageSize: number) => void,
    fetchQuotes: () => void,
    handleSearch: (search: string) => void;
    handleType: (type: string) => void;
    handleStatus: (status: string) => void;
    handleOpenDetail: (openDetail: boolean) => void;
    handleQuote: (quote: any) => void;
    changeStatusQuote: (quote: any) => void;
}

const initialContext = {
    quotes: [] as any[],
    quote: {} as any,
    loading: false,
    loadingAction: false,
    total: 0,
    searchParams: {},
    paginator: {
        limit: 10,
        page: 1,
    },
    errors: [],
    openDetail: false,
    onPageChange: () => {},
    fetchQuotes: () => {},
    handleSearch: () => {},
    handleType: () => {},
    handleStatus: () => {},
    handleOpenDetail: () => {},
    handleQuote: () => {},
    changeStatusQuote: () => {},
};

export const QuotesContext = createContext<IQuotesContext>(initialContext);

export const QuotesProvider = ({ children }: Props) => {

    const [quotes, setQuotes] = useState<any[]>(initialContext.quotes);
    const [quote, setQuote] = useState<any>(initialContext.quote);
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [loadingAction, setLoadingAction] = useState<boolean>(initialContext.loadingAction);
    const [errors, setErrors] = useState<string[]>(initialContext.errors);
    const [total, setTotal] = useState<number>(initialContext.total);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paginator, setPaginator] = useState(initialContext.paginator);

    const [openDetail, setOpenDetail] = useState<boolean>(initialContext.openDetail);

    const search = searchParams.get('search') || '';
    const type = searchParams.get('type') || EQuotesType.MENTORING;
    const status = searchParams.get('status') || undefined;

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({
            page,
            limit: pageSize,
        });
    };

    const getStatusValue = (status: string): boolean | undefined => {
        let isApproved: boolean | undefined;

        switch (status) {
        case EQuotesStatus.APPROVED:
            isApproved = true;
            break;

        case EQuotesStatus.PENDING:
            isApproved = false;
            break;

        default:
            isApproved = undefined;
            break;
        }
        return isApproved;
    };

    const getParams = () => {
        const params: any = { };
        search && (params.search = search);
        type && (params.type = type);
        status && (params.status = status);
        return params;
    };

    const handleSearch = (search: string) => {
        setSearchParams({ ...getParams(), search });
        setPaginator(initialContext.paginator);
    };

    const handleType = (type: string) => {
        setSearchParams({ ...getParams(), type });
        setPaginator(initialContext.paginator);
    };

    const handleStatus = (status: string) => {
        setSearchParams({ ...getParams(), status });
        setPaginator(initialContext.paginator);
    };

    const handleOpenDetail = (openDetail: boolean) => setOpenDetail(openDetail);

    const handleQuote = (quote: any) => setQuote(quote);

    const fetchQuotes = async () => {
        setLoading(true);
        setErrors([]);

        const params: any = {
            ...getParams(),
            ...paginator,
        };

        const isApproved = getStatusValue(params.status);

        isApproved !== undefined && isApproved && (params.isApproved = isApproved);
        delete params.status;

        quotesAPI.getQuotes(params)
            .then((response: Partial<IAxiosResponse>) => {
                const { data, meta } = response;
                setQuotes(data);
                setTotal(meta?.itemCount || 0);
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setLoading(false));
    };

    const changeStatusQuote = (quote: any) => {
        setLoadingAction(true);
        const { _id, isApproved } = quote;

        const quoteID: string = _id;
        const type = searchParams.get('type');

        const params = {
            status: !isApproved,
            type,
        };

        quotesAPI.changeStatus(quoteID, params)
            .then((response: Partial<IAxiosResponse>) => {
                const { message } = response;
                const newQuote = quotes.filter((item: any) => item._id === quoteID)[0];
                newQuote.isApproved = !newQuote.isApproved;
                notification.success({ message });
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                notification.error({ message });
            })
            .finally(() => setLoadingAction(false));
    };

    return (
        <QuotesContext.Provider
            value={{
                quotes,
                quote,
                loading,
                loadingAction,
                total,
                searchParams,
                paginator,
                errors,
                openDetail,
                onPageChange,
                fetchQuotes,
                handleSearch,
                handleType,
                handleStatus,
                handleOpenDetail,
                handleQuote,
                changeStatusQuote,
            }}
        >
            {children}
        </QuotesContext.Provider>
    );

};
