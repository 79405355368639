import { Avatar, Typography, Form } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IService } from '../../interfaces';
import Header from './Header';
import FormServices from './FormServices';

const { Paragraph } = Typography;

interface Props {
    t: (key: string) => string;
    data: IService;
}

const Leadership = ({ t, data }: Props) => {
    const {
        keyName, name, icon, description, questions,
    } = data;
    const { user: { services } } = useSelector((state: any) => state.auth);

    const [form] = Form.useForm();
    const [hasService, setHasService] = useState<boolean>(false);

    useEffect(() => {
        const service = services.find((service: any) => service.keyName === keyName);
        if (service) {
            setHasService(service.active);
            service.questions.forEach((question: any) => {
                const { keyName: questionKeyName, answer } = question;
                form.setFieldValue([questionKeyName], answer);
            });
        }
    }, []);

    return (
        <div className="services__container">
            <Header
                t={t}
                name={name}
                keyName={keyName}
                hasService={hasService}
                setHasService={setHasService}
            />

            <Paragraph className="services__container-description">
                {t(description)}
            </Paragraph>

            <Avatar
                src={icon}
                size={64}
                className="services__container-avatar"
            />

            <FormServices
                t={t}
                form={form}
                keyName={keyName}
                questions={questions}
                services={services}
            />
        </div>
    );
};

export default Leadership;
