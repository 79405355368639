import axios from 'axios';
import { DASHBOARD_API } from '../config';

const uploadImageToS3 = (file: any, type: any, name: string) => {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('type', type);
    formData.append('name', name);

    const url = `${DASHBOARD_API}/users/upload-image`;
    const headers = {
        'Content-Type': 'multipart/form-data',
    };

    const response = axios.post(url, formData, { headers });
    return response;
};

const uploadFileToS3 = (file: any, type: any, name: string) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    formData.append('name', name);

    const url = `${DASHBOARD_API}/users/upload-file`;
    const headers = {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
    };

    const response = axios.post(url, formData, { headers });
    return response;
};

const getCategories = () => {
    const url = `${DASHBOARD_API}/category`;
    const response = axios.get(url);
    return response;
};

export default {
    uploadImageToS3,
    uploadFileToS3,
    getCategories,
};
