import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import { UsersProvider } from '../../providers';
import { UsersScreen, UpdateUserScreen } from '../../screens/Admin/Users';

export const UsersRouter: FC = () => (
    <Routes>
        <Route
            path=""
            element={(
                <UsersProvider>
                    <UsersScreen />
                </UsersProvider>
            )}
        />
        <Route
            path=":id"
            element={(
                <UsersProvider>
                    <UpdateUserScreen />
                </UsersProvider>
            )}
        />
    </Routes>
);
