import { Form, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IAcademic } from '../../interfaces';
import { updateAcademicData } from '../../store/slices/auth';
import InstituteType from '../../widgets/InstituteType';
import Languages from '../../widgets/Languages';

const { TextArea } = Input;

const AcademicForm = () => {
    const { t } = useTranslation('forms', { keyPrefix: 'FORMS' });
    const { t: ti } = useTranslation('forms', { keyPrefix: 'FORMS.INPUTS' });
    const { t: tr } = useTranslation('forms', { keyPrefix: 'FORMS.RULES' });

    const { user, loading, error } = useSelector((state: any) => state.auth);

    const [form] = Form.useForm();

    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const RULES: any = [
        { required: true, message: tr('REQUIRED_FIELD') },
    ];

    const RULES_URL: any = [
        { type: 'url', warningOnly: true, message: tr('INVALID_URL') },
        { type: 'string', min: 6, message: tr('MIN_LENGTH', { length: 6 }) },
    ];

    const onSubmit = (values: IAcademic) => {
        const payload = {
            userID: user?._id,
            academic: {
                ...values,
            },
        };
        dispatch(updateAcademicData(payload));
        if (!loading && !error) {
            form.resetFields();
            navigate('/onboarding/membership-register');
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
        >
            <Form.Item
                label={ti('ACADEMIC.INSTITUTE')}
                name="institute"
                rules={RULES}
            >
                <Input placeholder={ti('ACADEMIC.INSTITUTE_PLACEHOLDER')} />
            </Form.Item>

            <InstituteType />

            <Form.Item
                label={ti('ACADEMIC.JOB_POSITION')}
                name="jobPosition"
                rules={RULES}
            >
                <Input placeholder={ti('ACADEMIC.JOB_POSITION_PLACEHOLDER')} />
            </Form.Item>

            <Form.Item
                label={ti('ACADEMIC.DESIGNATION')}
                name="designation"
                rules={RULES}
            >
                <Input placeholder={ti('ACADEMIC.DESIGNATION_PLACEHOLDER')} />
            </Form.Item>

            <Form.Item
                label={ti('ACADEMIC.BIOGRAPHY')}
                name="biography"
                rules={RULES}
            >
                <TextArea
                    placeholder={ti('ACADEMIC.BIOGRAPHY_PLACEHOLDER')}
                    maxLength={300}
                    showCount
                />
            </Form.Item>

            <Form.Item
                label={ti('ACADEMIC.CURRICULUM')}
                name="curriculum"
                rules={RULES_URL}
            >
                <Input
                    addonBefore="https://"
                    placeholder={ti('ACADEMIC.CURRICULUM_PLACEHOLDER')}
                />
            </Form.Item>

            <Languages />

            <Button
                block
                type="primary"
                loading={loading}
                htmlType="submit"
            >
                {t('ACTIONS.CONTINUE')}
            </Button>
        </Form>
    );
};

export default AcademicForm;
