import {
    Avatar, Col, Input, Row, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { IContext } from '../context/reviews.interface';
import { COUNTRIES } from '../../../../utils/countries';

interface IProps {
    defaultSearch: string | null;
    defaultCountry: string | null;
    props: IContext;
}

const { Search } = Input;
const { Option } = Select;

const Filters = ({
    defaultCountry, defaultSearch, props,
}: IProps) => {
    const { t } = useTranslation('admin');
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS.INPUTS' });

    const {
        handleSearch, loading, searchParams, handleCountry,
    } = props;
    const { search } = searchParams;

    return (
        <Row className="full__width" gutter={[24, 24]}>
            <Col xs={24} lg={16} className="col-left">
                <Search
                    allowClear
                    enterButton
                    placeholder={t('TABLE.SEARCH_REVIEW')}
                    onSearch={handleSearch}
                    defaultValue={search || defaultSearch}
                    disabled={loading}
                />
            </Col>
            <Col xs={24} lg={4} className="col-right">
                <Select
                    placeholder={tf('COUNTRY_PLACEHOLDER')}
                    className="full__width"
                    allowClear
                    defaultValue={defaultCountry}
                    onChange={(value) => handleCountry(!value ? '' : value)}
                >
                    {
                        COUNTRIES.map((country, index) => (
                            <Option value={country.code} key={index}>
                                <Avatar
                                    src={country.flag}
                                    style={{
                                        marginRight: 8,
                                        width: 32,
                                        height: 20,
                                    }}
                                    shape="square"
                                />
                                {country.name}
                            </Option>
                        ))
                    }
                </Select>
            </Col>
        </Row>
    );
};

export default Filters;
