import { useSelector } from 'react-redux';
import { createContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    IArticle, IAxiosError, IAxiosResponse,
} from '../../../interfaces';
import articlesAPI from '../../../api/articles';

interface Props {
    children: React.ReactNode;
}

interface IArticlesContext {
    articles: IArticle[];
    loading: boolean;
    actionLoading: boolean;
    total: number;
    searchParams: any;
    paginator: {
        limit: number;
        page: number;
    },
    errors: string[];
    onPageChange: (page: number, pageSize: number) => void,
    fetchArticles: () => void,
    deleteArticle: (articleID: string) => void,
    addArticle: (payload: any) => void,
    editArticle: (articleID: string, payload: any) => void,
}

const initialContext = {
    articles: [] as IArticle[],
    loading: false,
    actionLoading: false,
    total: 0,
    searchParams: {},
    paginator: {
        limit: 5,
        page: 1,
    },
    errors: [],
    onPageChange: () => {},
    fetchArticles: () => {},
    deleteArticle: () => {},
    addArticle: () => {},
    editArticle: () => {},
};

export const ArticlesContext = createContext<IArticlesContext>(initialContext);

export const ArticlesProvider = ({ children }: Props) => {
    const [articles, setArticles] = useState<IArticle[]>(initialContext.articles);
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [errors, setErrors] = useState<string[]>(initialContext.errors);
    const [actionLoading, setActionLoading] = useState<boolean>(initialContext.actionLoading);
    const [total, setTotal] = useState<number>(initialContext.total);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paginator, setPaginator] = useState(initialContext.paginator);

    const search = searchParams.get('search') || '';
    const category = searchParams.get('category') || '';

    const { user } = useSelector((state: any) => state.auth);

    const navigate = useNavigate();

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({
            page,
            limit: pageSize,
        });
    };

    const getParams = () => {
        const params: any = {};
        if (search) params.search = search;
        if (category) params.category = category;
        return params;
    };

    /* Articles */
    const fetchArticles = () => {
        setLoading(true);
        setErrors([]);
        const params: any = { ...paginator };
        if (search) params.search = search;
        if (category) params.category = category;

        articlesAPI.getArticles(params, user._id)
            .then((response: Partial<IAxiosResponse>) => {
                const { data, meta } = response;
                setArticles(data);
                setTotal(meta?.itemCount || 0);
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setLoading(false));
    };

    const deleteArticle = (articleID: string) => {
        setActionLoading(true);
        setErrors([]);
        articlesAPI.deleteArticle(articleID)
            .then((response: Partial<IAxiosResponse>) => {
                const { data } = response;
                setArticles(articles.filter((article: IArticle) => article._id !== data._id));
                setTotal(total - 1);
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setActionLoading(false));
    };

    const addArticle = (payload: any) => {
        setActionLoading(true);
        setErrors([]);
        articlesAPI.createArticle(payload)
            .then((response: Partial<IAxiosResponse>) => {
                const { data } = response;
                setArticles([data, ...articles]);
                setTotal(total + 1);
                navigate('/articles');
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setActionLoading(false));
    };

    const editArticle = (articleID: string, payload: any) => {
        setActionLoading(true);
        setErrors([]);
        articlesAPI.updateArticle(articleID, payload)
            .then((response: Partial<IAxiosResponse>) => {
                const { data } = response;
                setArticles(articles.map((article: IArticle) => {
                    if (article._id === data._id) return data;
                    return article;
                }));
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setActionLoading(false));
    };

    return (
        <ArticlesContext.Provider
            value={{
                articles,
                loading,
                actionLoading,
                total,
                searchParams,
                paginator,
                errors,
                onPageChange,
                fetchArticles,
                deleteArticle,
                addArticle,
                editArticle,
            }}
        >
            {children}
        </ArticlesContext.Provider>
    );
};
