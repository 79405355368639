import axios from 'axios';
import { DASHBOARD_API } from '../config';

const getStudyGroups = (query: string) => {
    const urlParams = new URLSearchParams(query).toString();
    const url = `${DASHBOARD_API}/book/study-groups?${urlParams}`;
    const response = axios.get(url);
    return response;
};

export default {
    getStudyGroups,
};
