import {
    Input, PageHeader, Space, Typography,
} from 'antd';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TableUsers from './components/Table';
import { UsersContext } from './context/users.context';

interface Props {
    params: any;
}

const { Search } = Input;

const Users = ({ params }: Props) => {
    const { t } = useTranslation('admin');

    const {
        loading, total, paginator, users, onPageChange, fetchUsers, searchParams, handleSearch,
    } = useContext(UsersContext);

    const { limit, page } = paginator;
    const { search } = searchParams;

    const defaultSearch = params.get('search');

    useEffect(() => {
        fetchUsers();
    }, [paginator, search, defaultSearch]);

    return (
        <div className="articles__edit">
            <PageHeader
                className="site-page-header-responsive"
                title={t('ADMIN_USERS')}
                footer={(
                    <Space direction="vertical" size={16} className="full__width">
                        <Search
                            allowClear
                            enterButton
                            placeholder={t('TABLE.SEARCH_MEMBER')}
                            onSearch={handleSearch}
                            defaultValue={search || defaultSearch}
                            disabled={loading}
                        />
                        <TableUsers
                            loading={loading}
                            total={total}
                            users={users}
                            onPageChange={onPageChange}
                            limit={limit}
                            page={page}
                        />
                    </Space>
                )}
            >
                <Typography.Paragraph>
                    {t('ADMIN_USERS_DESCRIPTION')}
                </Typography.Paragraph>
            </PageHeader>
        </div>
    );
};

export default Users;
