import moment from 'moment';
import cs from 'classnames';
import {
    Space, Table, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { BiEdit } from 'react-icons/bi';
import { BsLayoutSidebarInsetReverse } from 'react-icons/bs';
import ExternalLinkColumn from '../../../../widgets/Table/Link';
import NotAvailable from '../../../../widgets/Table/NotAvailable';

const { Title, Text } = Typography;

interface Props {
    loading: boolean;
    total: number;
    data: any[];
    limit: number;
    page: number;
    onPageChange: (page: number, pageSize: number) => void;
    setItemSelected: (item: any) => void;
}

const TableWebinars = ({
    loading, total, data, onPageChange, limit, page, setItemSelected,
}: Props) => {
    const { t } = useTranslation('admin', { keyPrefix: 'TABLE' });
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const language = localStorage.getItem('i18nextLng');
    const keyLanguage = language?.split('-')[0] || 'es';

    const navigate = useNavigate();

    const handleAction = (action: string, item: any) => {
        switch (action) {
        case 'VIEW':
            setItemSelected(item);
            break;
        case 'EDIT':
            navigate(`/admin/webinars/${item._id}`);
            break;
        case 'DELETE':
            break;
        default:
            break;
        }
    };

    const COLUMNS: ColumnsType<any> = [
        {
            title: t('WEBINAR'),
            dataIndex: 'tag',
            key: 'tag',
            width: 250,
            render: (tag: any, item: any) => (
                <Space direction="vertical" size={0}>
                    <Title level={5}>
                        {`${tag} ${t('WEBINAR')}`}
                    </Title>
                    <Text className={cs('table__category', item.categoryID.class)}>
                        {item.categoryID.name}
                    </Text>
                </Space>
            ),
        },
        {
            title: t('TOPIC'),
            dataIndex: 'topic',
            key: 'topic',
            width: 300,
            render: (topic: string[]) => (
                <Space direction="vertical" size={8} style={{ width: '100%' }}>
                    {
                        topic.map((t: string) => (
                            <Text>
                                {t}
                            </Text>
                        ))
                    }
                </Space>
            ),
        },
        {
            title: t('EVENT_DATE'),
            dataIndex: 'date',
            key: 'date',
            width: 200,
            align: 'center' as 'center',
            render: (date: string) => (
                <Text className="table__summary">
                    {moment(date).locale(keyLanguage as string || 'es').format('ddd DD MMM YYYY - HH:mm')}
                </Text>
            ),
        },
        {
            title: t('REGISTER_LINK'),
            dataIndex: 'registerLink',
            key: 'registerLink',
            width: 200,
            render: (registerLink: string) => <ExternalLinkColumn link={registerLink} />,
        },
        {
            title: t('VIDEO'),
            children: [
                {
                    title: t('VIDEO_LINK'),
                    dataIndex: 'link',
                    key: 'link',
                    width: 200,
                    align: 'center' as 'center',
                    render: (_: any, item: any) => <ExternalLinkColumn link={item?.video?.link || ''} />,
                },
                {
                    title: t('VIDEO_FRAME'),
                    dataIndex: 'frame',
                    key: 'frame',
                    render: (_: any, item: any) => (
                        item?.video?.frame ? (
                            <Text className="table__summary">
                                {item?.video?.frame}
                            </Text>
                        ) : (
                            <NotAvailable />
                        )
                    ),
                },
                {
                    title: t('VIDEO_IS_AVAILABLE'),
                    dataIndex: 'isAvailable',
                    key: 'isAvailable',
                    render: (_: any, item: any) => (
                        item?.video?.isAvailable ? (
                            <Text className="table__summary">
                                {item?.video?.frame}
                            </Text>
                        ) : (
                            <NotAvailable />
                        )
                    ),
                },
            ],
        },
        {
            title: t('ACTIONS'),
            dataIndex: 'actions',
            key: 'actions',
            fixed: 'right' as 'right',
            width: 160,
            render: (actions: any, item: any) => (
                <div className="table__actions">
                    <BsLayoutSidebarInsetReverse size={28} className="table__actions-icon" onClick={() => handleAction('VIEW', item)} />
                    <BiEdit size={28} className="table__actions-icon" onClick={() => handleAction('EDIT', item)} />
                    {/* <MdDelete size={28} className="table__actions-icon delete" onClick={() => handleAction('DELETE', user)} /> */}
                </div>
            ),
        },
    ];

    /* if (loading) return <Loading />; */

    return (
        <Table
            className="table"
            bordered
            rowKey="_id"
            loading={loading}
            columns={COLUMNS}
            dataSource={data}
            scroll={{ x: 1500 }}
            locale={{
                emptyText: t('EMPTY'),
            }}
            pagination={{
                total,
                pageSize: limit,
                current: page,
                onChange: onPageChange,
                showSizeChanger: false,
                size: 'small',
            }}
        />
    );
};

export default TableWebinars;
