import {
    Button,
    Modal, notification, Space, Table, Tooltip, Typography,
} from 'antd';
import { useContext, useEffect } from 'react';
import {
    BiEdit, BiHelpCircle, BiLinkExternal,
} from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { IArticle } from '../../../interfaces';
import EmptyData from '../../../widgets/EmptyData';
import Loading from '../../../widgets/Loading';
import { ArticlesContext } from '../context/articlesContext';

interface Props {
    t: (key: string) => string;
}

const { Text } = Typography;

const TableArticles = ({ t }: Props) => {
    const {
        loading, errors, actionLoading, articles, total, paginator, searchParams, onPageChange, fetchArticles, deleteArticle,
    } = useContext(ArticlesContext);

    const { limit, page } = paginator;
    const { search, category } = searchParams;

    const navigate = useNavigate();

    useEffect(() => {
        fetchArticles();
    }, [paginator, search, category]);

    const confirmDelete = (title: string, articleID: string) => {
        Modal.confirm({
            className: 'modal__confirm-delete',
            icon: <MdDelete size={28} className="modal__confirm-delete icon" />,
            title: t('DELETE_CONFIRMATION'),
            content: title,
            okText: t('DELETE'),
            cancelText: t('CANCEL'),
            onOk: () => {
                deleteArticle(articleID);
                if (errors.length === 0) {
                    notification.success({ message: t('DELETE_SUCCESS') });
                } else {
                    notification.error({
                        message: t('DELETE_ERROR'),
                        description: errors[0] || t('SOMENTHING_WENT_WRONG'),
                    });
                }
            },
            okButtonProps: {
                loading: actionLoading,
            },
        });
    };

    const handleAction = (action: string, article: IArticle) => {
        switch (action) {
        case 'EDIT':
            navigate(`/articles/${article._id}`);
            break;
        case 'DELETE':
            confirmDelete(article.title, article._id || '');
            break;
        default:
            break;
        }
    };

    const COLUMNS = [
        {
            title: t('TABLE.TITLE'),
            dataIndex: 'title',
            key: 'title',
            width: 400,
        },
        {
            title: t('TABLE.SUMMARY'),
            dataIndex: 'summary',
            key: 'summary',
            render: (summary: string) => (
                <Text className="table__summary">
                    {summary}
                </Text>
            ),
        },
        {
            title: t('TABLE.CATEGORY'),
            dataIndex: 'categoryID',
            key: 'categoryID',
            width: 240,
            render: (categoryID: any) => {
                const language = localStorage.getItem('i18nextLng');
                const keyLanguage = language?.split('-')[0].toUpperCase();
                const category = keyLanguage ? categoryID[keyLanguage] : categoryID.ES;

                return (
                    <Text className="table__category">
                        {category}
                    </Text>
                );
            },
        },
        {
            title: (
                <div className="table__help">
                    {t('TABLE.EXTERNAL_LINK')}
                    <Tooltip
                        placement="bottom"
                        showArrow
                        title={t('HELPS.EXTERNAL_LINK')}
                    >
                        <BiHelpCircle size={16} />
                    </Tooltip>
                </div>
            ),
            dataIndex: 'externalLink',
            key: 'externalLink',
            width: 200,
            render: (externalLink: string) => (
                !externalLink
                    ? (
                        <Text className="table__not-available">
                            {t('NOT_AVAILABLE')}
                        </Text>
                    )
                    : (
                        <Tooltip title={externalLink} showArrow placement="topLeft">
                            <div className="table__external-link" onClick={() => window.open(externalLink, '_blank')}>
                                {t('TABLE.GO_TO_LINK')}
                                <BiLinkExternal size={16} className="table__external-link-icon" />
                            </div>
                        </Tooltip>
                    )
            ),
        },
        {
            title: t('TABLE.ACTIONS'),
            dataIndex: 'actions',
            key: 'actions',
            fixed: 'right' as 'right',
            width: 160,
            render: (actions: any, article: IArticle) => (
                <div className="table__actions">
                    <BiEdit size={28} className="table__actions-icon" onClick={() => handleAction('EDIT', article)} />
                    <MdDelete size={28} className="table__actions-icon delete" onClick={() => handleAction('DELETE', article)} />
                </div>
            ),
        },
    ];

    if (loading) return <Loading />;

    return (
        total === 0 ? (
            <EmptyData
                t={t}
                path="/articles/new-article"
                nameButton="ADD_ARTICLE"
            />
        ) : (
            <Table
                className="table"
                bordered
                rowKey="_id"
                loading={loading}
                columns={COLUMNS}
                dataSource={articles}
                scroll={{ x: 1500 }}
                pagination={{
                    total,
                    pageSize: limit,
                    current: page,
                    onChange: onPageChange,
                    showSizeChanger: false,
                    size: 'small',
                }}
            />
        )
    );
};

export default TableArticles;
