import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const SendEmail = () => {
    const { t } = useTranslation('forms', { keyPrefix: 'FORMS.INPUTS' });
    const { t: tr } = useTranslation('forms', { keyPrefix: 'FORMS.RULES' });

    const RULES_EMAIL: any = [
        { required: true, message: tr('REQUIRED_FIELD') },
        { type: 'email', message: tr('INVALID_EMAIL') },
        { type: 'string', min: 3, message: tr('MIN_LENGTH', { length: 3 }) },
    ];

    return (
        <Form.Item
            label={t('EMAIL')}
            name="email"
            rules={RULES_EMAIL}
        >
            <Input placeholder={t('EMAIL_PLACEHOLDER')} />
        </Form.Item>
    );
};

export default SendEmail;
