import ReactDOM from 'react-dom/client';

import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { PartialTheme, ThemeProvider } from '@fluentui/react';
import { initializeIcons } from '@fluentui/react/lib/Icons';

import { theme } from './theme';

import './plugins';
import App from './App';
import store from './store';
import Loading from './screens/Loading';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const customTheme : PartialTheme = { palette: theme };

initializeIcons(undefined, { disableWarnings: true });
root.render(
    <Provider store={store}>
        <Suspense fallback={<Loading />}>
            <ThemeProvider theme={customTheme}>
                <App />
            </ThemeProvider>
        </Suspense>
    </Provider>,
);
