import { INSTITUTE_TYPE } from '../enums/Institution';

export const TYPES: string[] = [
    INSTITUTE_TYPE.IES_PUBLIC,
    INSTITUTE_TYPE.IES_PRIVATE,
    INSTITUTE_TYPE.IES_COMMUNITY,
    INSTITUTE_TYPE.IES_TECH,
    INSTITUTE_TYPE.EDUCATIONAL_NETWORK,
    INSTITUTE_TYPE.GOVERNMENT_INSTITUTION,
    INSTITUTE_TYPE.CONSULTING_ASSISTANCE,
    INSTITUTE_TYPE.TECHNOLOGY_PROVIDER,
];
