import axios from 'axios';
import { DASHBOARD_API } from '../config';

const updateContactDataUser = (payload: any, contactID: string) => {
    const url = `${DASHBOARD_API}/contacts/${contactID}`;
    const response = axios.put(url, payload);
    return response;
};

export default {
    updateContactDataUser,
};
