import { useState } from 'react';
import {
    Button, Col, Form, Input, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Author from './Author';

interface Props {
    action: (values: any) => void;
}

const { TextArea } = Input;

const PostComment = ({ action }: Props) => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });
    const { user } = useSelector((state: any) => state.auth);

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const RULES: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
    ];

    const onSubmit = (values: any) => {
        setLoading(true);
        action(values);
        form.resetFields();
        setLoading(false);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            className="full__width"
        >
            <div className="card__post full__width flex-column start-column-start">
                <Author
                    image={user?.image}
                    name={`${user?.name} ${user?.lastName}`}
                    type="light"
                />
                <Form.Item
                    name="content"
                    rules={RULES}
                    className="full__width"
                >
                    <TextArea
                        placeholder={tf('INPUTS.COMMENT_PLACEHOLDER')}
                    />
                </Form.Item>
                <Row justify="end" align="middle">
                    <Col>
                        <Button
                            type="primary"
                            size="small"
                            loading={loading}
                            htmlType="submit"
                        >
                            {tf('ACTIONS.POST')}
                        </Button>
                    </Col>
                </Row>
            </div>
        </Form>
    );
};

export default PostComment;
