import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    setEmailExist,
    login,
    signup,
    setLoading,
    setError,
    updateAcademic,
    updateMembership,
    updatePersonal,
    updateContact,
    updateSettings,
    updateWelcomePhrase,
} from './authSlice';

import authAPI from '../../../api/auth';
import userAPI from '../../../api/user';
import contactAPI from '../../../api/contact';

type LoginUserPayload = {
    email: string;
    password: string;
};

type RegisterUserPayload = {
    name: string;
    lastName: string;
    country: string;
    countryCode: string;
    mobile: string;
    fullMobile: string;
    email: string;
    password: string;
    role: string[];
    origin: string;
    gender?: string;
};

export const createAccount = createAsyncThunk(
    'auth/createAccount',
    async (payload: RegisterUserPayload, { dispatch, rejectWithValue }) => {
        dispatch(setLoading(true));
        dispatch(setError(null));
        try {
            const { data } = await authAPI.signup(payload);
            dispatch(signup(data));
            return data;
        } catch (error: any) {
            dispatch(setError(error.response.data));
            return rejectWithValue(error.response.data);
        } finally {
            dispatch(setLoading(false));
        }
    },
);

export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async (payload: LoginUserPayload, { dispatch, rejectWithValue }) => {
        dispatch(setLoading(true));
        dispatch(setError(null));
        try {
            const { data } = await authAPI.login(payload);
            dispatch(login(data));
            return data;
        } catch (error: any) {
            dispatch(setError(error.response.data));
            return rejectWithValue(error.response.data);
        } finally {
            dispatch(setLoading(false));
        }
    },
);

export const checkEmail = createAsyncThunk(
    'auth/checkEmail',
    async (email: string, { dispatch, rejectWithValue }) => {
        dispatch(setLoading(true));
        dispatch(setError(null));
        try {
            const { data } = await authAPI.checkExistEmail(email);
            dispatch(setEmailExist(data));
            return data;
        } catch (error: any) {
            dispatch(setError(error.response.data));
            return rejectWithValue(error.response.data);
        } finally {
            dispatch(setLoading(false));
        }
    },
);

export const loadUser = createAsyncThunk(
    'auth/loadUser',
    async (token: string, { dispatch, rejectWithValue }) => {
        dispatch(setLoading(true));
        dispatch(setError(null));
        try {
            const { data } = await authAPI.loadUser(token);
            dispatch(login(data));
            return data;
        } catch (error: any) {
            dispatch(setError(error.response.data));
            return rejectWithValue(error.response.data);
        } finally {
            dispatch(setLoading(false));
        }
    },
);

export const updatePersonalData = createAsyncThunk(
    'auth/updatePersonalData',
    async (payload: any, { dispatch, rejectWithValue }) => {
        dispatch(setLoading(true));
        dispatch(setError(null));
        try {
            const { userID, personal } = payload;
            await userAPI.updatePersonalDataUser(personal, userID);
            dispatch(updatePersonal({ ...personal }));
            return true;
        } catch (error: any) {
            dispatch(setError(error.response.data));
            return rejectWithValue(error.response.data);
        } finally {
            dispatch(setLoading(false));
        }
    },
);

export const updateWelcomePhraseData = createAsyncThunk(
    'auth/updateWelcomePhraseData',
    async (payload: any, { dispatch, rejectWithValue }) => {
        dispatch(setLoading(true));
        dispatch(setError(null));
        try {
            await userAPI.updateWelcomePhraseDataUser(payload);
            dispatch(updateWelcomePhrase({ ...payload }));
            return true;
        } catch (error: any) {
            dispatch(setError(error.response.data));
            return rejectWithValue(error.response.data);
        } finally {
            dispatch(setLoading(false));
        }
    },
);

export const updateContactData = createAsyncThunk(
    'auth/updateContactData',
    async (payload: any, { dispatch, rejectWithValue }) => {
        dispatch(setLoading(true));
        dispatch(setError(null));
        try {
            const { contactID } = payload;
            await contactAPI.updateContactDataUser(payload, contactID);
            delete payload.contactID;
            dispatch(updateContact({ ...payload }));
            return true;
        } catch (error: any) {
            dispatch(setError(error.response.data));
            return rejectWithValue(error.response.data);
        } finally {
            dispatch(setLoading(false));
        }
    },
);

export const updateAcademicData = createAsyncThunk(
    'auth/updateAcademicData',
    async (payload: any, { dispatch, rejectWithValue }) => {
        dispatch(setLoading(true));
        dispatch(setError(null));
        try {
            const { userID, academic } = payload;
            await userAPI.updateUser(academic, userID);
            delete payload.userID;
            dispatch(updateAcademic({ ...payload.academic }));
            return true;
        } catch (error: any) {
            dispatch(setError(error.response.data));
            return rejectWithValue(error.response.data);
        } finally {
            dispatch(setLoading(false));
        }
    },
);

export const updateMembershipData = createAsyncThunk(
    'auth/updateMembershipData',
    async (payload: any, { dispatch, rejectWithValue }) => {
        dispatch(setLoading(true));
        dispatch(setError(null));
        try {
            const { userID, membership, category } = payload;
            await userAPI.updateMembership(category, userID);
            dispatch(updateMembership({ ...membership }));
            return true;
        } catch (error: any) {
            dispatch(setError(error.response.data));
            return rejectWithValue(error.response.data);
        } finally {
            dispatch(setLoading(false));
        }
    },
);

export const sendMailToResetPassword = createAsyncThunk(
    'auth/sendMailToResetPassword',
    async (payload: any, { dispatch, rejectWithValue }) => {
        dispatch(setLoading(true));
        dispatch(setError(null));
        try {
            await userAPI.sendMailToResetPassword(payload);
            return true;
        } catch (error: any) {
            dispatch(setError(error.response.data));
            return rejectWithValue(error.response.data);
        } finally {
            dispatch(setLoading(false));
        }
    },
);

export const updateSettingsData = createAsyncThunk(
    'auth/updateSettingsData',
    async (payload: any, { dispatch, rejectWithValue }) => {
        dispatch(setLoading(true));
        dispatch(setError(null));
        try {
            await userAPI.updateSettings(payload);
            dispatch(updateSettings(payload));
            return true;
        } catch (error: any) {
            dispatch(setError(error.response.data));
            return rejectWithValue(error.response.data);
        } finally {
            dispatch(setLoading(false));
        }
    },
);
