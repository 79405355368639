import { Button, PageHeader, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LibraryContext } from '../../components/Library/context/libraryContext';
import { TableLibrary } from '../../components/Library';

const MyLibrary = () => {
    const { t } = useTranslation('library', { keyPrefix: 'LIBRARY' });
    const { total } = useContext(LibraryContext);
    const navigate = useNavigate();

    return (
        <div className="library">
            <PageHeader
                className="site-page-header-responsive"
                title={t('TITLE')}
                footer={<TableLibrary t={t} />}
                extra={total > 0 ? [
                    <Button
                        key="1"
                        type="primary"
                        onClick={() => navigate('/my-library/new-document')}
                    >
                        {t('ADD_DOCUMENT')}
                    </Button>,
                ] : []}
            >
                <Typography.Paragraph>
                    {t('DESCRIPTION')}
                </Typography.Paragraph>
            </PageHeader>
        </div>
    );
};

export default MyLibrary;
