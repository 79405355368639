import { Alert, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AcademicForm from '../Forms/AcademicForm';

const { Title } = Typography;

const Academic = () => {
    const { t } = useTranslation('onboarding');
    const { error } = useSelector((state: any) => state.auth);

    return (
        <>
            <Title
                level={3}
                className="center"
                style={{ fontWeight: 600 }}
            >
                {t('ACADEMIC.TITLE')}
            </Title>

            { error && (
                <Alert
                    message={`${error?.status}: ${error?.error}`}
                    type="error"
                    showIcon
                />
            )}

            <AcademicForm />
        </>
    );
};

export default Academic;
