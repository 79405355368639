import {
    Row, Col, Typography, Space, Alert,
} from 'antd';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { theme } from '../../theme';

import LoginForm from '../../components/Forms/LoginForm';

import WelcomeMessage from '../../components/Login/WelcomeMessage';
import LogoRectoral from '../../widgets/Logo';
import { setError, setLoading } from '../../store/slices/auth';

const { Title, Paragraph } = Typography;

const Login = () => {
    const { t } = useTranslation('login');
    const { error } = useSelector((state: any) => state.auth);

    const dispatch = useDispatch<any>();

    useEffect(() => {
        dispatch(setLoading(false));
        dispatch(setError(null));
    }, []);

    return (
        <Row className="full__size">
            <Col xs={24} md={12} className="wrapper wrapper__login">
                <div className="forms__main">

                    <Space direction="vertical" size={24} className="full__width">
                        <LogoRectoral
                            size={{ width: 150 }}
                            type="dark"
                            direction="horizontal"
                            className="responsive"
                        />

                        <Title level={2}>
                            {t('LOGIN')}
                        </Title>
                    </Space>

                    <Paragraph>
                        {t('ENTER_YOUR_CREDENTIALS')}
                    </Paragraph>

                    <Space direction="vertical" size={24} className="full__width">
                        { error && (
                            <Alert
                                message={t(error?.error)}
                                type="error"
                                showIcon
                                closable
                            />
                        )}

                        <LoginForm />
                    </Space>

                    <Space
                        className="dont-has-account"
                        direction="horizontal"
                        size={8}
                    >
                        <Title
                            level={5}
                            style={{
                                color: theme.black,
                                fontWeight: 400,
                            }}
                        >
                            {t('DONT_HAVE_ACCOUNT')}
                        </Title>

                        <Link to="/sign-up">
                            <Title level={5}>
                                {t('SIGN_UP')}
                            </Title>
                        </Link>
                    </Space>

                </div>
            </Col>
            <Col xs={0} md={12} className="wrapper sliders">
                <WelcomeMessage />
            </Col>
        </Row>
    );
};

export default Login;
