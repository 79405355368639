import {
    Col, Form, Row, Typography, Input, Space, Button, Tooltip, notification, PageHeader,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ContributionsContext } from '../context/contributionsContext';
import UploadImage from '../../UploadImage';
import Location from './Location';
import ContributionStatus from '../../../widgets/Status';
import ContributionCategory from '../../../widgets/ContributionType';
import Country from '../../../widgets/Country';
import contributionsAPI from '../../../api/contributions';
import {
    IAxiosError, IAxiosResponse, IContribution, IContributionPayload,
} from '../../../interfaces';

const { Title } = Typography;
const { TextArea } = Input;

const EditContribution = () => {
    const { t } = useTranslation('contributions', { keyPrefix: 'CONTRIBUTIONS' });
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });
    const { t: ti } = useTranslation('forms', { keyPrefix: 'FORMS.INPUTS' });
    const { actionLoading, errors, updateContribution } = useContext(ContributionsContext);
    const { contributionID } = useParams();
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [coordinates, setCoordinates] = useState<any>(null);

    const onSubmit = (values: any) => {
        const payload = {
            authorID: values.authorID,
            registerBy: values.registerBy,
            isAvailable: values.isAvailable,
            project: {
                name: values.projectName,
                summary: values.summary,
                category: values.category,
                startYear: values.startYear,
                endYear: values.endYear,
                leadership: values.leadership,
                externalLink: values.externalLink,
            },
            keyWords: values.keyWords,
            location: {
                city: values.city,
                country: values.country,
                coordinates,
            },
            status: values.status,
            category: values.category,
            image: {
                original: values.image,
                thumbnail: values.image,
            },
        };

        updateContribution(contributionID || '', payload);

        if (!actionLoading) {
            if (errors.length === 0) {
                notification.success({ message: t('EDIT_SUCCESS') });
            } else {
                notification.error({
                    message: t('EDIT_ERROR'),
                    description: errors[0] || t('SOMETHING_WENT_WRONG'),
                });
            }
        }
    };

    const RULES: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
    ];

    const RULES_YEAR: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
        { type: 'string', max: 4, message: tf('RULES.MAX_LENGTH', { length: 4 }) },
        { type: 'string', min: 4, message: tf('RULES.MIN_LENGTH', { length: 4 }) },
    ];

    const RULES_URL: any = [
        { type: 'url', warningOnly: true, message: tf('RULES.INVALID_URL') },
        { type: 'string', min: 6, message: tf('RULES.MIN_LENGTH', { length: 6 }) },
    ];

    useEffect(() => {
        if (!contributionID) return;
        setLoading(true);
        contributionsAPI.getContribution(contributionID)
            .then((response: Partial<IAxiosResponse>) => {
                const contribution: IContribution = response.data;
                form.setFieldsValue({
                    projectName: contribution.project.name,
                    summary: contribution.project.summary,
                    projectCategory: contribution.project?.category,
                    startYear: contribution.project.startYear,
                    endYear: contribution.project.endYear,
                    leadership: contribution.project?.leadership,
                    externalLink: contribution.project?.externalLink,
                    keyWords: contribution.keyWords,
                    status: contribution.status,
                    category: contribution.category,
                    country: contribution.location?.country,
                    city: contribution.location?.city,
                    image: contribution.image?.original,
                    authorID: contribution.authorID._id,
                    registerBy: contribution.registerBy,
                    isAvailable: contribution.isAvailable,
                });

                if (contribution.location?.coordinates) {
                    setCoordinates({
                        lat: contribution.location.coordinates.lat,
                        lng: contribution.location.coordinates.lng,
                    });
                }
            })
            .catch((error: Partial<IAxiosError>) => console.log(error))
            .finally(() => setLoading(false));
    }, [contributionID]);

    return (
        <div className="new__edit">
            <Form
                form={form}
                layout="vertical"
                name="add-article"
                onFinish={onSubmit}
            >
                <PageHeader
                    onBack={() => navigate(-1)}
                    className="site-page-header-responsive"
                    title={t('EDIT_CONTRIBUTION')}
                    extra={[
                        <Button
                            type="primary"
                            loading={loading}
                            htmlType="submit"
                        >
                            {t('UPDATE_CONTRIBUTION')}
                        </Button>,
                    ]}
                    footer={(
                        <Row gutter={[40, 32]}>
                            <Col xs={24} xl={12} className="col-left">
                                <Space direction="vertical" size={24} className="full__width">
                                    <div className="services__container none__margin--top none__padding--large">
                                        <div className="services__container-header">
                                            <Title level={4}>
                                                {t('CONTRIBUTION_INFORMATION')}
                                            </Title>
                                        </div>

                                        <div className="services__container-form">

                                            <Form.Item name="authorID" hidden>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name="registerBy" hidden>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name="isAvailable" hidden>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label={ti('CONTRIBUTION.INSTITUTE_LOGO')}
                                                name="image"
                                                noStyle
                                            >
                                                <UploadImage
                                                    t={t}
                                                    type="contribution"
                                                    name={form.getFieldValue('projectName')}
                                                />
                                            </Form.Item>
                                            <ContributionStatus />
                                            <ContributionCategory />
                                        </div>
                                    </div>

                                    <div className="services__container none__margin--top none__padding--large">
                                        <div className="services__container-header">
                                            <Title level={4}>
                                                {t('LOCATION_PROJECT')}
                                            </Title>
                                        </div>
                                        <div className="services__container-form">
                                            <Location
                                                t={t}
                                                coordinates={coordinates}
                                                setCoordinates={setCoordinates}
                                            />
                                            <Row gutter={[24, 28]}>
                                                <Col xs={12} className="col-left">
                                                    <Country />
                                                </Col>
                                                <Col xs={12} className="col-right">
                                                    <Form.Item
                                                        label={ti('CONTRIBUTION.CITY')}
                                                        name="city"
                                                        rules={RULES}
                                                    >
                                                        <Input placeholder={ti('CONTRIBUTION.CITY_PLACEHOLDER')} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Space>
                            </Col>
                            <Col xs={24} xl={12} className="col-left">
                                <div className="services__container none__margin--top none__padding--large">
                                    <div className="services__container-header">
                                        <Title level={4}>
                                            {t('PROJECT_INFORMATION')}
                                        </Title>
                                    </div>

                                    <div className="services__container-form">
                                        <Form.Item
                                            label={ti('CONTRIBUTION.PROJECT_NAME')}
                                            name="projectName"
                                            rules={RULES}
                                        >
                                            <Input placeholder={ti('CONTRIBUTION.PROJECT_NAME_PLACEHOLDER')} />
                                        </Form.Item>

                                        <Form.Item
                                            label={ti('CONTRIBUTION.SUMMARY')}
                                            name="summary"
                                            rules={RULES}
                                        >
                                            <TextArea
                                                placeholder={ti('CONTRIBUTION.SUMMARY_PLACEHOLDER')}
                                                maxLength={500}
                                                showCount
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label={ti('CONTRIBUTION.LEADERSHIP')}
                                            name="leadership"
                                            rules={RULES}
                                        >
                                            <Input placeholder={ti('CONTRIBUTION.LEADERSHIP_PLACEHOLDER')} />
                                        </Form.Item>

                                        <ContributionCategory />

                                        <Tooltip placement="bottomLeft" showArrow title={ti('KEY_WORDS_HELP')}>
                                            <Form.Item
                                                label={ti('KEY_WORDS')}
                                                name="keyWords"
                                                rules={RULES}
                                            >
                                                <Input placeholder={ti('KEY_WORDS_PLACEHOLDER')} />
                                            </Form.Item>
                                        </Tooltip>

                                        <Form.Item
                                            label={ti('CONTRIBUTION.START_YEAR')}
                                            name="startYear"
                                            rules={RULES_YEAR}
                                        >
                                            <Input placeholder={ti('CONTRIBUTION.START_YEAR_PLACEHOLDER')} />
                                        </Form.Item>

                                        <Form.Item
                                            label={ti('CONTRIBUTION.END_YEAR')}
                                            name="endYear"
                                            rules={RULES_YEAR}
                                        >
                                            <Input placeholder={ti('CONTRIBUTION.END_YEAR_PLACEHOLDER')} />
                                        </Form.Item>

                                        <Form.Item
                                            label={ti('CONTRIBUTION.EXTERNAL_LINK')}
                                            name="externalLink"
                                            rules={RULES_URL}
                                        >
                                            <Input
                                                placeholder={ti('CONTRIBUTION.EXTERNAL_LINK_PLACEHOLDER')}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )}
                />
            </Form>
        </div>
    );
};

export default EditContribution;
