export const MapStyles = [
    {
        featureType: 'landscape',
        elementType: 'geometry.fill',
        stylers: [{ color: '#efefeb' }],
    },
    {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'simplified' }],
    },
    {
        featureType: 'poi',
        elementType: 'labels.icon',
        stylers: [{ color: '#d3d3cf' }],
    },
    {
        featureType: 'poi',
        elementType: 'labels.text',
        stylers: [
            {
                color: '#525f7f',
            },
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'poi.attraction',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ visibility: '#d3d3cf' }],
    },
    {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
    },
];
