import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import { WebinarsProvider } from '../../providers';
import { WebinarsScreen, AddWebinarScreen } from '../../screens/Admin/Webinars';

export const WebinarsRouter: FC = () => (
    <Routes>
        <Route
            path=""
            element={(
                <WebinarsProvider>
                    <WebinarsScreen />
                </WebinarsProvider>
            )}
        />
        <Route
            path="/new-webinar"
            element={(
                <WebinarsProvider>
                    <AddWebinarScreen />
                </WebinarsProvider>
            )}
        />
    </Routes>
);
