import { useTranslation } from 'react-i18next';
import { Manage } from '../../components/Services';
import Header from '../../widgets/Header';

const Services = () => {
    const { t } = useTranslation('services', { keyPrefix: 'SERVICES' });

    return (
        <div className="services">
            <Header title={t('TITLE')} description={t('DESCRIPTION')} />
            <Manage t={t} />
        </div>
    );
};

export default Services;
