import { Select, Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import articlesAPI from '../../api/articles';
import { IAxiosError, IAxiosResponse, ICategory } from '../../interfaces';

const { Option } = Select;
const { Item } = Form;

const Categories = () => {
    const { t } = useTranslation('forms', { keyPrefix: 'FORMS.INPUTS' });
    const { t: tr } = useTranslation('forms', { keyPrefix: 'FORMS.RULES' });

    const [loading, setLoading] = useState<boolean>(false);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [errors, setErrors] = useState<string[]>([]);

    const RULES = [{ required: true, message: tr('REQUIRED_FIELD') }];
    const language = localStorage.getItem('i18nextLng');
    const keyLanguage = language?.split('-')[0].toUpperCase();

    const fetchCategories = () => {
        setLoading(true);
        articlesAPI.getCategories()
            .then((response: Partial<IAxiosResponse>) => {
                const { data } = response;
                setCategories(data);
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <Item
            label={t('CATEGORY')}
            name="categoryID"
            rules={RULES}
        >
            <Select
                placeholder={t('CATEGORY')}
            >
                {
                    categories.map((category: any, index: number) => (
                        <Option value={category._id} key={index}>
                            { keyLanguage ? category[keyLanguage] : category.ES}
                        </Option>
                    ))
                }
            </Select>
        </Item>
    );
};

export default Categories;
