/* global React JSX */
import axios from 'axios';

import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LS_USER_KEY } from '../constants/localStorageKeys';
import { loadUser } from '../store/slices/auth';

interface Props {
    children: JSX.Element;
}

const PrivateRoute = ({ children }: Props) => {
    const { user } = useSelector((state: any) => state.auth);

    const { pathname } = useLocation();
    const dispatch = useDispatch<any>();

    useEffect(() => {
        const localStorageUser = localStorage.getItem(LS_USER_KEY);
        const token = localStorageUser && JSON.parse(localStorageUser).token;
        if (token) {
            dispatch(loadUser(token));
        }
    }, [dispatch]);

    useEffect(() => {
        if (!user?._id) return;
        axios.defaults.headers.common.authorization = `Bearer ${user.token}`;
        localStorage.setItem(LS_USER_KEY, JSON.stringify(user));
    }, [user, dispatch]);

    if (!user?._id) return <Navigate to="/login" />;

    if (!user?.academic && !pathname.startsWith('/onboarding')) {
        return <Navigate to="/onboarding/academic-record" />;
    }

    if (user?.academic && !user?.membership && !pathname.startsWith('/onboarding')) {
        return <Navigate to="/onboarding/membership-register" />;
    }

    return children;
};

export default PrivateRoute;
