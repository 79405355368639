import {
    Avatar, List, Tooltip, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { IContext } from '../context/study-groups.interface';
import { getCountry } from '../../../../utils/countries';

const { Title, Text } = Typography;

const Members = ({ ...props }: IContext) => {
    const { t } = useTranslation('admin');
    const { studyGroup } = props;

    const getRole = (role: string[]) => {
        if (role.includes('SECRETARY')) return t('TABLE.SECRETARY_GROUP');
        return t('TABLE.MEMBER_GROUP');
    };

    return (
        <div>
            <Title level={3}>
                {t('TABLE.MEMBERS')}
            </Title>

            <List
                dataSource={studyGroup?.membersData}
                renderItem={(item: any) => {
                    const country = getCountry(item?.country);

                    return (
                        <List.Item
                            key={item._id}
                            extra={(
                                <Tooltip title={country?.nativeName}>
                                    <Avatar
                                        src={country?.flag}
                                        shape="square"
                                        style={{
                                            width: 32,
                                            height: 20,
                                        }}
                                    />
                                </Tooltip>
                            )}
                        >
                            <List.Item.Meta
                                className="full__width"
                                style={{ marginBottom: 16 }}
                                avatar={
                                    item?.image?.original ? (
                                        <Avatar
                                            size={28}
                                            src={item?.image?.original}
                                        />
                                    ) : (
                                        <Avatar
                                            size={28}
                                            style={{ backgroundColor: '#1b4484', fontSize: 12 }}
                                        >
                                            {item.name.charAt(0).toUpperCase()}
                                        </Avatar>
                                    )
                                }
                                title={(
                                    <Text>
                                        {`${item.name} ${item.lastName} - ${getRole(item.role)}`}
                                    </Text>
                                )}
                                description={(
                                    <Text>
                                        {item.email}
                                    </Text>
                                )}
                            />
                        </List.Item>
                    );
                }}
            />
        </div>
    );
};

export default Members;
