import {
    Form, Row, Col, Typography, Input, Tooltip, Button, Space, notification, PageHeader, List, Avatar,
} from 'antd';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import Categories from '../../../widgets/Categories';
import UploadImage from '../../UploadImage';
import { ArticlesContext } from '../context/articlesContext';
import TextEditor from './TextEditor';
import { ICollaborator, IUser } from '../../../interfaces';
import ModalWrapper from '../../Modals';
import Collaborator from './Collaborator';
import SearchUser from '../../SearchUser';

const { Title, Text } = Typography;
const { TextArea } = Input;

const AddArticle = () => {
    const { t } = useTranslation('articles', { keyPrefix: 'ARTICLES' });
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });
    const { t: ti } = useTranslation('forms', { keyPrefix: 'FORMS.INPUTS' });
    const { user } = useSelector((state: any) => state.auth);
    const { errors, actionLoading, addArticle } = useContext(ArticlesContext);

    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [newCollaborator, setNewCollaborator] = useState<boolean>(false);
    const [collaborators, setCollaborators] = useState<ICollaborator[]>([]);

    const handleSelectUser = ({ _id, name, lastName }: IUser) => {
        setCollaborators([
            ...collaborators,
            {
                collaboratorID: _id,
                name,
                lastName,
            },
        ]);
    };

    const handleNewUser = (name: string, lastName: string) => {
        setCollaborators([
            ...collaborators,
            {
                collaboratorID: '',
                name,
                lastName,
            },
        ]);
        setNewCollaborator(false);
    };

    const handleRemoveCollaborator = (collaborator: ICollaborator) => {
        const newCollaborators = collaborators.filter((item: ICollaborator) => item !== collaborator);
        setCollaborators(newCollaborators);
    };

    const onSubmit = (values: any) => {
        const payload = {
            ...values,
            type: 'ARTICLE',
            authorID: user._id,
            registerID: user._id,
            isAvailable: true,
            dataSheet: {
                source: values.source,
                publisher: values.publisher || '',
                publisher_year: values.publisher_year,
            },
            image: {
                original: values.image,
                thumbnail: values.image,
            },
            collaborators,
        };

        addArticle(payload);

        if (!actionLoading) {
            if (errors.length === 0) {
                notification.success({ message: tf('RESPONSES.ADD_SUCCESS') });
                form.resetFields();
            } else {
                notification.error({
                    message: tf('RESPONSES.ADD_ERROR'),
                    description: errors[0] || tf('SOMENTHING_WENT_WRONG'),
                });
            }
        }
    };

    const RULES: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
    ];

    const RULES_YEAR: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
        { type: 'string', max: 4, message: tf('RULES.MAX_LENGTH', { length: 4 }) },
        { type: 'string', min: 4, message: tf('RULES.MIN_LENGTH', { length: 4 }) },
    ];

    const RULES_URL: any = [
        { type: 'url', warningOnly: true, message: tf('RULES.INVALID_URL') },
        { type: 'string', min: 6, message: tf('RULES.MIN_LENGTH', { length: 6 }) },
    ];

    return (
        <div className="new__add">
            <Form
                form={form}
                layout="vertical"
                name="add-article"
                onFinish={onSubmit}
            >
                <PageHeader
                    onBack={() => navigate(-1)}
                    className="site-page-header-responsive"
                    title={t('ADD_ARTICLE')}
                    extra={[
                        <Button
                            type="primary"
                            loading={actionLoading}
                            htmlType="submit"
                        >
                            {t('ADD_ARTICLE')}
                        </Button>,
                    ]}
                    footer={(
                        <Row gutter={[32, 40]}>
                            {
                                newCollaborator && (
                                    <ModalWrapper
                                        visible={newCollaborator}
                                        onCancel={() => setNewCollaborator(false)}
                                    >
                                        <Collaborator
                                            handleNewUser={handleNewUser}
                                        />
                                    </ModalWrapper>
                                )
                            }
                            <Col xs={24} xl={12} className="col-left">
                                <div className="services__container none__margin--top none__padding--large">
                                    <div className="services__container-header">
                                        <Title level={4}>
                                            {t('INFORMATION_SHEET')}
                                        </Title>
                                    </div>

                                    <div className="services__container-form">
                                        <Form.Item
                                            label={ti('ARTICLES.TITLE')}
                                            name="title"
                                            rules={RULES}
                                        >
                                            <Input placeholder={ti('ARTICLES.TITLE_PLACEHOLDER')} />
                                        </Form.Item>

                                        <Form.Item
                                            label={ti('ARTICLES.SUMMARY')}
                                            name="summary"
                                            rules={RULES}
                                        >
                                            <TextArea
                                                placeholder={ti('ARTICLES.SUMMARY_PLACEHOLDER')}
                                                maxLength={500}
                                                showCount
                                            />
                                        </Form.Item>

                                        <Categories />

                                        <Tooltip placement="bottomLeft" showArrow title={ti('ARTICLES.SOURCE_HELP')}>
                                            <Form.Item
                                                label={ti('ARTICLES.SOURCE')}
                                                name="source"
                                                rules={RULES}
                                            >
                                                <Input placeholder={ti('ARTICLES.SOURCE_PLACEHOLDER')} />
                                            </Form.Item>
                                        </Tooltip>

                                        <Form.Item
                                            label={ti('ARTICLES.YEAR_PUBLISHER')}
                                            name="publisher_year"
                                            rules={RULES_YEAR}
                                        >
                                            <Input placeholder={ti('ARTICLES.YEAR_PUBLISHER_PLACEHOLDER')} />
                                        </Form.Item>

                                        <Tooltip placement="bottomLeft" showArrow title={ti('ARTICLES.KEY_WORDS_HELP')}>
                                            <Form.Item
                                                label={ti('ARTICLES.KEY_WORDS')}
                                                name="keyWords"
                                                rules={RULES}
                                            >
                                                <Input placeholder={ti('ARTICLES.KEY_WORDS_PLACEHOLDER')} />
                                            </Form.Item>
                                        </Tooltip>

                                        <Form.Item
                                            label={ti('ARTICLES.EXTERNAL_LINK')}
                                            name="externalLink"
                                            rules={RULES_URL}
                                        >
                                            <Input
                                                placeholder={ti('ARTICLES.EXTERNAL_LINK_PLACEHOLDER')}
                                            />
                                        </Form.Item>

                                        <div>
                                            <Form.Item
                                                label={
                                                    `${ti('ARTICLES.COLLABORATORS')} (${collaborators?.length || 0})`
                                                }
                                            >
                                                <Space className="full__width" size={8} direction="vertical">
                                                    <Trans
                                                        t={ti}
                                                        i18nKey="ARTICLES.COLLABORATORS_HELP"
                                                        components={{
                                                            b: (
                                                                <b
                                                                    style={{
                                                                        color: '#1b4484',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() => setNewCollaborator(true)}
                                                                />
                                                            ),
                                                        }}
                                                        values={{
                                                            action: ti('ARTICLES.ADD_NEW_COLLABORATOR'),
                                                        }}
                                                    />
                                                    <SearchUser
                                                        handleSelect={handleSelectUser}
                                                    />
                                                </Space>
                                            </Form.Item>

                                            { collaborators?.length > 0 && (
                                                <List
                                                    dataSource={collaborators}
                                                    renderItem={(item: ICollaborator, index: number) => (
                                                        <List.Item
                                                            key={index}
                                                            actions={[
                                                                <MdDelete
                                                                    size={28}
                                                                    className="table__actions-icon delete"
                                                                    onClick={() => handleRemoveCollaborator(item)}
                                                                />,
                                                            ]}
                                                        >
                                                            <List.Item.Meta
                                                                avatar={(
                                                                    <Avatar
                                                                        size={28}
                                                                        style={{
                                                                            backgroundColor: '#1b4484',
                                                                            fontSize: 12,
                                                                        }}
                                                                    >
                                                                        {item.name.charAt(0).toUpperCase()}
                                                                    </Avatar>
                                                                )}
                                                                title={(
                                                                    <Text strong>
                                                                        {`${item.name} ${item.lastName}`}
                                                                    </Text>
                                                                )}
                                                                description={(
                                                                    <Text>
                                                                        {item.collaboratorID ? t('IS_MEMBER') : t('IS_NOT_MEMBER')}
                                                                    </Text>
                                                                )}
                                                            />
                                                        </List.Item>
                                                    )}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} xl={12} className="col-right">

                                <div className="services__container none__margin--top none__padding--large">
                                    <div className="services__container-header">
                                        <Title level={4}>
                                            {t('ARTICLE_CONTENT')}
                                        </Title>
                                    </div>

                                    <div className="services__container-form">
                                        <Space direction="vertical" size={24} className="full__width">
                                            <Form.Item
                                                label={ti('ARTICLES.IMAGE')}
                                                name="image"
                                                noStyle
                                            >
                                                <UploadImage t={tf} type="articles" name={form.getFieldValue('title')} />
                                            </Form.Item>
                                            <TextEditor form={form} />
                                        </Space>
                                        <Form.Item
                                            hidden
                                            name="content"
                                            rules={RULES}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )}
                />
            </Form>
        </div>
    );
};

export default AddArticle;
