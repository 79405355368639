export const enum EQuotesType {
    MENTORING = 'mentoring',
    LEADERSHIP= 'leadership',
    TOOL_BOX = 'toolbox'
}

export const enum EQuotesStatus {
    PENDING = 'pending',
    APPROVED = 'approved',
}
