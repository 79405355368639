import { Drawer, Typography, Space } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { EQuotesType } from '../../../../enums/quotes.enum';
import QuoteMentoring from './Mentoring';
import General from './General';
import QuoteToolBox from './ToolBox';
import QuoteLeadership from './Leadership';

interface Props {
    quote: any;
    header: string;
    openDetail: boolean;
    handleOpenDetail: (openDetail: boolean) => void;
}

const { Title, Text } = Typography;

const QuoteDetail = ({
    quote, header, openDetail, handleOpenDetail,
}: Props) => {

    const { t } = useTranslation('admin');

    return (
        <Drawer
            title={(
                <Title level={4} className="none__margin--bottom">
                    <Trans
                        t={t}
                        i18nKey="QUOTE_DETAIL"
                        components={{ span: <span /> }}
                        values={{ type: t(`TABLE.${header.toUpperCase()}`) }}
                    />
                </Title>
            )}
            width={720}
            open={openDetail}
            onClose={() => handleOpenDetail(!openDetail)}
            closable={false}
        >
            <Space direction="vertical" size={12} className="full__width">

                <General {...quote} />

                { header === EQuotesType.MENTORING && <QuoteMentoring quote={quote} />}
                { header === EQuotesType.LEADERSHIP && <QuoteLeadership quote={quote} />}
                { header === EQuotesType.TOOL_BOX && <QuoteToolBox quote={quote} />}

            </Space>
        </Drawer>
    );
};

export default QuoteDetail;
