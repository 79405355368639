import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { BiLinkExternal } from 'react-icons/bi';
import NotAvailable from './NotAvailable';

const ExternalLinkColumn = ({ link }: { link: string }) => {
    const { t } = useTranslation('admin', { keyPrefix: 'TABLE' });

    return !link ? (
        <NotAvailable />
    ) : (
        <Tooltip title={link} showArrow placement="topLeft">
            <div
                className="table__external-link"
                onClick={() => window.open(link, '_blank')}
            >
                {t('GO_TO_LINK')}
                <BiLinkExternal size={16} className="table__external-link-icon" />
            </div>
        </Tooltip>
    );
};

export default ExternalLinkColumn;
