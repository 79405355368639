import { useTranslation } from 'react-i18next';
import { ManageTabs } from '../../components/Profile';
import Header from '../../widgets/Header';

const Profile = () => {
    const { t } = useTranslation('profile', { keyPrefix: 'PROFILE' });

    return (
        <div className="profile">
            <Header title={t('TITLE')} description={t('DESCRIPTION')} />
            <ManageTabs t={t} />
        </div>
    );
};

export default Profile;
