import { DASHBOARD_IMAGES } from '../config';

const BUCKET_URL = DASHBOARD_IMAGES;
const IMAGES = {
    logo: {
        vertical: {
            dark: `${BUCKET_URL}/logos/vertical-logo.svg`,
            light: `${BUCKET_URL}/logos/vertical-logo-light.svg`,
        },
        horizontal: {
            dark: `${BUCKET_URL}/logos/logo.svg`,
            light: `${BUCKET_URL}/logos/logo-light.svg`,
        },
    },
    apps: {
        institute: `${BUCKET_URL}/apps/rectoral.svg`,
        academy: `${BUCKET_URL}/logos/luces-iso.svg`,
    },
    dashboard: {
        default_avatar: `${BUCKET_URL}/dashboard/default-avatar.svg`,
    },
    portrait: {
        main: `${BUCKET_URL}/portraits/main.svg`,
        presentation: `${BUCKET_URL}/portraits/presentation.svg`,
    },
    illustrations: {
        books_graduate: `${BUCKET_URL}/illustrations/books-graduate.svg`,
        map: `${BUCKET_URL}/illustrations/map.svg`,
        trama: `${BUCKET_URL}/illustrations/trama.svg`,
        success: `${BUCKET_URL}/illustrations/success.svg`,
    },
    memberships: {
        gold: `${BUCKET_URL}/logos/gold.png`,
        platinum: `${BUCKET_URL}/logos/platinum.png`,
        diamond: `${BUCKET_URL}/logos/diamond.png`,
    },
    services: {
        mentoring: `${BUCKET_URL}/services/mentoring.png`,
        leadership: `${BUCKET_URL}/services/leadership.png`,
        toolbox: `${BUCKET_URL}/services/toolbox.png`,
        blog: `${BUCKET_URL}/services/blog.png`,
        other: `${BUCKET_URL}/services/others.svg`,
    },
    previews: {
        welcome_phrase: `${BUCKET_URL}/previews/welcome-phrase.svg`,
    },
    languages: {
        spanish: 'https://flagcdn.com/es.svg',
        english: 'https://flagcdn.com/gb.svg',
        portuguese: 'https://flagcdn.com/br.svg',
    },
};

export default IMAGES;
