import cs from 'classnames';
import { Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import { FaFileSignature } from 'react-icons/fa';
import { IContext } from '../context/community.interface';
import Articles from './Articles';

const Manage = ({ ...props }: IContext) => {
    const { t: ta } = useTranslation('admin');

    const { action } = props;

    const actions = [
        {
            key: 'ARTICLES',
            label: ta('TABLE.ARTICLES'),
            icon: <FaFileSignature color="#1b4484" />,
            active: action === 'ARTICLES',
        },
    ];

    return (
        <div className="tags flex-column start-column-start">
            <div className="full__width flex-row start-row-center" style={{ gap: 24 }}>
                {
                    actions.map((item) => (
                        <div
                            key={item.key}
                            className={cs('tags__category flex-row center-row-center', { active: item.active })}
                        >
                            <Avatar
                                src={item.icon}
                                className="tags__category-icon flex-row center-row-center"
                            />
                            {item.label}
                        </div>
                    ))
                }
            </div>
            {
                action === 'ARTICLES' && (
                    <Articles {...props} />
                )
            }
        </div>
    );
};

export default Manage;
