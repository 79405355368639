import {
    Avatar,
    Card, List, Space, Typography,
} from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { AiFillCheckCircle, AiOutlineCodeSandbox } from 'react-icons/ai';

interface Props {
    quote: any;
}

const { Title, Text } = Typography;

const QuoteToolBox = ({ quote }: Props) => {
    const { t } = useTranslation('admin');

    const section = (label: string, value: string) => (
        <Space direction="vertical" size={0} className="full__width">
            <Text strong style={{ fontSize: 16 }}>
                {t(label)}
            </Text>
            <Text style={{ fontSize: 16 }}>
                {value}
            </Text>
        </Space>
    );

    return (
        <Space direction="vertical" size={12} className="full__width">
            <Card
                title={(
                    <Title level={4} className="none__margin--bottom">
                        {t('TOOL_BOX.TOOL_BOX_SERVICE')}
                    </Title>
                )}
            >
                {
                    section(
                        'TABLE.CONTEXTUALIZATION',
                        quote.contextualize,
                    )
                }
            </Card>

            <Card
                title={(
                    <Title level={4} className="none__margin--bottom">
                        {t('TOOL_BOX.BOX_DETAIL')}
                    </Title>
                )}
            >
                <List
                    itemLayout="horizontal"
                    dataSource={quote.box}
                    split={false}
                    renderItem={(item: any, index: number) => (
                        <List.Item key={index}>
                            <List.Item.Meta
                                avatar={(
                                    <Avatar
                                        style={{
                                            backgroundColor: '#eaeaea',
                                            borderRadius: 4,
                                        }}
                                        shape="square"
                                        size={28}
                                        src={(
                                            <AiOutlineCodeSandbox color="#1b4484" size={14} />
                                        )}
                                        className="flex-row center-row-center"
                                    />
                                )}
                                title={(
                                    <Space direction="vertical" size={0} className="full__width">
                                        <Title level={5} className="none__margin--bottom">
                                            {t(`TOOL_BOX.${item.name}`)}
                                        </Title>
                                    </Space>
                                )}
                                description={(
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={item.tools}
                                        renderItem={(tool: any, index: number) => (
                                            <List.Item
                                                key={index}
                                                actions={[
                                                    <AiFillCheckCircle
                                                        color={
                                                            tool.value ? '#1b4484' : '#c8c6c4'
                                                        }
                                                        size={20}
                                                        style={{ verticalAlign: 'middle' }}
                                                    />,
                                                ]}
                                            >
                                                <List.Item.Meta
                                                    title={(
                                                        <Space direction="vertical" size={0} className="full__width">
                                                            <Text strong={tool.item === 'OTHERS'}>
                                                                {t(`TOOL_BOX.${tool.item}`)}
                                                            </Text>
                                                        </Space>
                                                    )}
                                                    description={(
                                                        tool.item === 'OTHERS' && (
                                                            <Text>
                                                                {tool.value}
                                                            </Text>
                                                        )
                                                    )}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                )}
                            />
                        </List.Item>
                    )}
                />

            </Card>
        </Space>
    );
};

export default QuoteToolBox;
