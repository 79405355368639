import { Card, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
    names: string;
    email: string;
    institute: string;
    fullMobile: string;
}

const { Title, Text } = Typography;

const General = ({
    names, email, institute, fullMobile,
}: Props) => {
    const { t } = useTranslation('admin');

    const section = (label: string, value: string) => (
        <Space direction="vertical" size={0} className="full__width">
            <Text strong style={{ fontSize: 16 }}>
                {t(`TABLE.${label}`)}
            </Text>
            <Text style={{ fontSize: 16 }}>
                {value}
            </Text>
        </Space>
    );

    return (
        <Card
            title={(
                <Title level={4} className="none__margin--bottom">
                    {t('GENERAL_INFORMATION')}
                </Title>
            )}
        >
            <Space direction="vertical" size={12} className="full__width">
                {section('REQUESTER', names)}
                {section('EMAIL', email)}
                {section('INSTITUTE', institute)}
                {section('MOBILE', fullMobile)}
            </Space>
        </Card>
    );
};

export default General;
