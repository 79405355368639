import {
    Button, PageHeader, Typography,
} from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ContributionsContext } from '../../components/Contributions/context/contributionsContext';
import { TableContributions } from '../../components/Contributions';

const Contributions = () => {
    const { t } = useTranslation('contributions', { keyPrefix: 'CONTRIBUTIONS' });
    const { contributions } = useContext(ContributionsContext);
    const navigate = useNavigate();

    return (
        <div className="contributions">
            <PageHeader
                className="site-page-header-responsive"
                title={t('TITLE')}
                footer={<TableContributions t={t} />}
                extra={contributions.length > 0 ? [
                    <Button
                        key="1"
                        type="primary"
                        onClick={() => navigate('/contributions/new-contribution')}
                    >
                        {t('ADD_CONTRIBUTION')}
                    </Button>,
                ] : []}
            >
                <Typography.Paragraph>
                    {t('DESCRIPTION')}
                </Typography.Paragraph>
            </PageHeader>
        </div>
    );
};

export default Contributions;
