import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
    Col, Row, Typography, Checkbox, notification, PageHeader,
} from 'antd';
import { IUser } from '../../../../interfaces';
import usersAPI from '../../../../api/users';

interface Props {
    t: (key: string) => string;
    userData: IUser | null;
}

const { Title } = Typography;

const Partner = ({ t, userData }: Props) => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const [isPartner, setIsPartner] = useState<boolean>(false);

    const [loading, setLoading] = useState(false);

    const onChange = (e: CheckboxChangeEvent) => {
        setLoading(true);
        const value = e.target.checked;
        setIsPartner(value);

        const payload = {
            isPartner: value,
        };

        const userID = userData?._id || '';

        usersAPI.setAsPartner(userID, payload)
            .then(() => {
                notification.success({
                    message: tf('RESPONSES.UPDATE_SUCCESS'),
                });
            })
            .catch((error: any) => {
                const description = error?.response?.data?.message || error.message;
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setIsPartner(userData?.isPartner || false);
    }, []);

    return (
        <div className="profile__manage-tabs-forms">
            <PageHeader
                title={(
                    <Title level={4} style={{ marginBottom: 0 }}>
                        {t('PERSONAL.PARTNER')}
                    </Title>
                )}
            />

            <div className="full__width">
                <Row className="full__size" gutter={[24, 28]}>
                    <Col xs={24} className="col-left">
                        <Checkbox
                            disabled={loading}
                            checked={isPartner}
                            onChange={onChange}
                        >
                            {tf('INPUTS.SET_AS_PARTNER')}
                        </Checkbox>
                    </Col>
                </Row>
            </div>

        </div>
    );
};

export default Partner;
