import {
    Form, Input, Checkbox, Typography,
} from 'antd';
import { IQuestion } from '../../interfaces';

interface Props {
    t: (key: string) => string;
    question: IQuestion;
}

const { Text } = Typography;

const Question = ({ t, question }: Props) => {
    const { keyName, label, type } = question;
    switch (type) {
    case 'TEXT':
        return (
            <Form.Item
                label={t(label)}
                name={keyName}
            >
                <Input placeholder={t(label)} />
            </Form.Item>
        );
    case 'CHECK':
        return (
            <Form.Item
                label={t(label)}
                name={keyName}
                valuePropName="checked"
            >
                <Checkbox>
                    <Text>
                        {t('FORMS.YES_OF_CURSE')}
                    </Text>
                </Checkbox>
            </Form.Item>
        );
    default:
        return null;
    }
};

export default Question;
