import { useEffect } from 'react';
import {
    List, Space, Input, Row, Col, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import CardArticle from '../../../widgets/CardArticle';
import { ICategory } from '../../../interfaces';
import { IContext } from '../context/community.interface';

const { Option } = Select;
const { Search } = Input;

const Articles = ({ ...props }: IContext) => {
    const { t } = useTranslation('admin');
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS.INPUTS' });

    const {
        articles: items,
        loading,
        fetchAllArticles: fetchAllItems,
        handleSearch,
        search,
        categories,
        handleCategory,
    } = props;

    const language = localStorage.getItem('i18nextLng');
    const keyLanguage = language?.split('-')[0].toUpperCase();

    useEffect(() => {
        fetchAllItems();
    }, []);

    return (
        <Space direction="vertical" size={16} className="full__width">
            <Row justify="space-between" align="middle" className="full__width" gutter={[16, 16]}>
                <Col flex={1} xs={24} lg={20}>
                    <Search
                        allowClear
                        enterButton
                        placeholder={t('TABLE.SEARCH_MEMBER')}
                        onSearch={handleSearch}
                        defaultValue={search}
                        disabled={loading}
                    />
                </Col>
                <Col xs={24} lg={4}>
                    <Select
                        allowClear
                        placeholder={tf('CATEGORY')}
                        onChange={handleCategory}
                        className="full__width"
                    >
                        {
                            categories.map((category: ICategory, index: number) => (
                                <Option value={category._id} key={index}>
                                    { keyLanguage ? category[keyLanguage as 'EN' | 'ES' | 'PT'] : category.ES}
                                </Option>
                            ))
                        }
                    </Select>
                </Col>
            </Row>

            <List
                className="full__width"
                locale={{
                    emptyText: t('TABLE.EMPTY'),
                }}
                dataSource={items}
                renderItem={(item, index) => (
                    <List.Item key={item._id}>
                        <CardArticle
                            article={item}
                            props={props}
                        />
                    </List.Item>
                )}
            />
        </Space>
    );
};

export default Articles;
