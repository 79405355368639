/* global React JSX */
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

interface Props {
    children: JSX.Element;
}

const PublicRoute = ({ children }: Props) => {
    const { user } = useSelector((state: any) => state.auth);
    const { pathname } = useLocation();

    if (user?._id) {
        return <Navigate to="/" />;
    } if (pathname === '/login' && user?._id) {
        return <Navigate to="/sign-up" />;
    }

    return children;
};

export default PublicRoute;
