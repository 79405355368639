import { TYPES_SERVICES } from '../enums/Services';
import { IServices } from '../interfaces';
import IMAGES from './images';

export const NAMES = {
    MENTORING: TYPES_SERVICES.MENTORING,
    LEADERSHIP: TYPES_SERVICES.LEADERSHIP,
    TOOL_BOX: TYPES_SERVICES.TOOL_BOX,
    BLOG: TYPES_SERVICES.BLOG,
    OTHER: TYPES_SERVICES.OTHER,
};

export const SERVICES: IServices = {
    [NAMES.MENTORING]: {
        keyName: 'MENTORING',
        name: 'TYPES.MENTORING',
        icon: IMAGES.services.mentoring,
        description: 'DESCRIPTIONS.MENTORING',
        questions: [
            {
                keyName: 'QUESTION_1',
                label: 'QUESTIONS.MENTORING_QUESTION_1',
                type: 'TEXT',
            },
            {
                keyName: 'QUESTION_2',
                label: 'QUESTIONS.MENTORING_QUESTION_2',
                type: 'TEXT',
            },
            {
                keyName: 'QUESTION_3',
                label: 'QUESTIONS.MENTORING_QUESTION_3',
                type: 'CHECK',
            },
        ],
    },
    [NAMES.LEADERSHIP]: {
        keyName: 'LEADERSHIP',
        name: 'TYPES.LEADERSHIP',
        icon: IMAGES.services.leadership,
        description: 'DESCRIPTIONS.LEADERSHIP',
        questions: [
            {
                keyName: 'QUESTION_1',
                label: 'QUESTIONS.LEADERSHIP_QUESTION_1',
                type: 'TEXT',
            },
        ],
    },
    [NAMES.TOOL_BOX]: {
        keyName: 'TOOL_BOX',
        name: 'TYPES.TOOL_BOX',
        icon: IMAGES.services.toolbox,
        description: 'DESCRIPTIONS.TOOL_BOX',
        questions: [
            {
                keyName: 'QUESTION_1',
                label: 'QUESTIONS.TOOL_BOX_QUESTION_1',
                type: 'TEXT',
            },
            {
                keyName: 'QUESTION_2',
                label: 'QUESTIONS.TOOL_BOX_QUESTION_2',
                type: 'TEXT',
            },
        ],
    },
    [NAMES.BLOG]: {
        keyName: 'BLOG',
        name: 'TYPES.BLOG',
        icon: IMAGES.services.blog,
        description: 'DESCRIPTIONS.BLOG',
        questions: [
            {
                keyName: 'QUESTION_1',
                label: 'QUESTIONS.BLOG_QUESTION_1',
                type: 'TEXT',
            },
        ],
    },
    [NAMES.OTHER]: {
        keyName: 'OTHER',
        name: 'TYPES.OTHER',
        icon: IMAGES.services.other,
        description: 'DESCRIPTIONS.OTHER',
        questions: [
            {
                keyName: 'QUESTION_1',
                label: 'QUESTIONS.OTHER_QUESTION_1',
                type: 'TEXT',
            },
            {
                keyName: 'QUESTION_2',
                label: 'QUESTIONS.OTHER_QUESTION_2',
                type: 'TEXT',
            },
        ],
    },
};
