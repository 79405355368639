import moment from 'moment';
import { Space, Tooltip, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { AiFillCheckSquare } from 'react-icons/ai';

interface Props {
    loading: boolean;
    total: number;
    quotes: any[];
    limit: number;
    page: number;
    openDetail: boolean;
    onPageChange: (page: number, pageSize: number) => void;
    handleOpenDetail: (openDetail: boolean) => void;
    handleQuote: (quote: any) => void;
    changeStatusQuote: (quote: any) => void;
}

const { Text } = Typography;

const TableQuotes = ({
    loading, total, quotes, onPageChange, limit, page, openDetail, handleOpenDetail, handleQuote, changeStatusQuote,
}: Props) => {
    const { t } = useTranslation('admin', { keyPrefix: 'TABLE' });

    const handleAction = (action: string, quote: any) => {
        switch (action) {
        case 'VIEW':
            handleOpenDetail(!openDetail);
            handleQuote(quote);
            break;
        case 'EDIT':
            changeStatusQuote(quote);
            break;
        case 'DELETE':
            break;
        default:
            break;
        }
    };

    const COLUMNS: ColumnsType<any> = [
        {
            title: t('REQUEST_DATE'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center' as 'center',
            render: (createdAt: string) => (
                <Text className="table__summary">
                    {moment(createdAt).format('lll')}
                </Text>
            ),
        },
        {
            title: t('REQUESTER'),
            dataIndex: 'names',
            key: 'names',
            render: (names: string, quote: any) => (
                <Space
                    size={0}
                    direction="vertical"
                    onClick={() => handleAction('VIEW', quote)}
                >
                    <Text
                        className="table__summary"
                        style={{ color: '#1976d2', cursor: 'pointer' }}
                    >
                        {names}
                    </Text>
                    <Text
                        className="table__summary"
                        style={{
                            color: '#999',
                            fontSize: 14,
                        }}
                    >
                        {quote.email}
                    </Text>
                </Space>
            ),
        },
        {
            title: t('MOBILE'),
            dataIndex: 'fullMobile',
            key: 'fullMobile',
            align: 'center' as 'center',
            render: (fullMobile: string) => (
                fullMobile ? (
                    <Text>
                        {fullMobile}
                    </Text>
                ) : (
                    <Text className="table__not-available">
                        {t('NOT_AVAILABLE')}
                    </Text>
                )
            ),
        },
        {
            title: t('INSTITUTE'),
            dataIndex: 'institute',
            key: 'institute',
            align: 'center' as 'center',
            render: (institute: string) => (
                <Text className="table__summary">
                    {institute}
                </Text>
            ),
        },
        {
            title: t('CONTEXTUALIZATION'),
            dataIndex: 'contextualize',
            key: 'contextualize',
            render: (contextualize: string) => (
                <Tooltip title={contextualize} placement="bottomLeft">
                    <Text
                        className="table__summary"
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%',
                            maxHeight: '100px',
                            display: 'inline-block',
                        }}
                    >
                        {contextualize}
                    </Text>
                </Tooltip>
            ),
        },
        {
            title: t('STATUS'),
            dataIndex: 'isApproved',
            key: 'isApproved',
            render: (isApproved: boolean) => (
                <Text
                    className="table__not-available"
                    style={{
                        color: isApproved ? '#52c41a' : '#8a8886',
                        borderColor: isApproved ? '#52c41a' : '#8a8886',
                    }}
                >
                    {t(isApproved ? 'APPROVED' : 'PENDING')}
                </Text>
            ),
        },
        {
            title: t('ACTIONS'),
            dataIndex: 'actions',
            key: 'actions',
            fixed: 'right' as 'right',
            width: 160,
            render: (actions: any, quote: any) => (
                <div className="table__actions">
                    <AiFillCheckSquare size={28} className="table__actions-icon" onClick={() => handleAction('EDIT', quote)} />
                    {/* <MdDelete size={28} className="table__actions-icon delete" onClick={() => handleAction('DELETE', user)} /> */}
                </div>
            ),
        },
    ];

    return (
        <Table
            className="table"
            bordered
            rowKey="_id"
            loading={loading}
            columns={COLUMNS}
            dataSource={quotes}
            scroll={{ x: 1500 }}
            pagination={{
                total,
                pageSize: limit,
                current: page,
                onChange: onPageChange,
                showSizeChanger: false,
                size: 'small',
            }}
        />
    );
};

export default TableQuotes;
