import axios from 'axios';
import { DASHBOARD_API } from '../config';

const updateLucesInfo = (userID: string, payload: any) => {
    const url = `${DASHBOARD_API}/users/${userID}/luces`;
    const response = axios.put(url, payload);
    return response;
};

export default {
    updateLucesInfo,
};
