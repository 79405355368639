import { ICategory, IComment } from '../../../interfaces';
import { IContext } from './community.interface';

export const initial: IContext = {
    loading: true,
    loadingComments: false,
    errors: [],
    action: 'ARTICLES',
    articles: [],
    contributions: [],
    total: 0,
    paginator: {
        limit: 15,
        page: 1,
    },
    search: '',
    category: '',
    categories: [] as ICategory[],
    showComments: null,
    comments: [] as IComment[],
    setComments: () => {},
    setShowComments: () => {},
    setAction: () => {},
    handleSearch: () => {},
    handleCategory: () => {},
    fetchAllArticles: () => {},
    fetchComments: () => {},
    likeComment: () => {},
    likeResponseComment: () => {},
    likeArticle: () => {},
    shareArticle: () => {},
    addComment: () => {},
    addResponse: () => {},
};
