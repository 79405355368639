import axios from 'axios';
import { DASHBOARD_API } from '../config';

const getUsers = (query: string) => {
    const urlParams = new URLSearchParams(query).toString();
    const url = `${DASHBOARD_API}/users?${urlParams}`;
    const response = axios.get(url);
    return response;
};

const getUser = (userID: string) => {
    const url = `${DASHBOARD_API}/users/${userID}`;
    const response = axios.get(url);
    return response;
};

const updatePersonalData = (userID: string, payload: any) => {
    const url = `${DASHBOARD_API}/users/${userID}/personal`;
    const response = axios.put(url, payload);
    return response;
};

const updateWelcomePhrase = (userID: string, payload: any) => {
    const url = `${DASHBOARD_API}/users/${userID}/welcome-phrase`;
    const response = axios.put(url, payload);
    return response;
};

const setAsPartner = (userID: string, payload: any) => {
    const url = `${DASHBOARD_API}/users/${userID}/partner`;
    const response = axios.put(url, payload);
    return response;
};

const updateLucesDesignation = (userID: string, payload: any) => {
    const url = `${DASHBOARD_API}/users/${userID}/partner/luces`;
    const response = axios.put(url, payload);
    return response;
};

export default {
    getUsers,
    getUser,
    updatePersonalData,
    updateWelcomePhrase,
    setAsPartner,
    updateLucesDesignation,
};
