import { useEffect, useState } from 'react';
import { Card, List, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { IArticle, IComment } from '../../interfaces';
import { IContext } from '../../components/Community/context/community.interface';

import Author from './Author';
import Summary from './Summary';
import Actions from './Actions';
import Comment from './Comment';
import PostComment from './Post';
import DrawerArticle from '../DrawerArticle';
import { RECTORAL_BOARD_SITE } from '../../config';

const CardArticle = ({
    article,
    props,
}: { article: IArticle, props: IContext }) => {
    const { t } = useTranslation('admin');
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const [openArticle, setOpenArticle] = useState<boolean>(false);

    const {
        showComments,
        loadingComments,
        setShowComments,
        fetchComments,
        comments,
        likeArticle,
        addComment,
        shareArticle,
    } = props;

    const hasComments = article.comments.length;

    const handleShowComments = () => (hasComments > 0) && setShowComments(article?._id || '');

    const handleLikeArticle = () => likeArticle(article._id as string);

    const handleShareArticle = async () => {
        const url = `${RECTORAL_BOARD_SITE}/ktdra-channel/${article._id}`;
        navigator.clipboard.writeText(url)
            .then(() => {
                message.success(tf('ACTIONS.SHARE_LINK_COPIED'));
                shareArticle(article._id as string);
            })
            .catch(() => message.error(tf('ACTIONS.SHARE_LINK_ERROR')));
    };

    useEffect(() => {
        if (showComments === article?._id && hasComments > 0) {
            fetchComments();
        }
    }, [showComments]);

    return (
        <Card className="full__width">

            {
                openArticle && (
                    <DrawerArticle
                        article={article}
                        open={openArticle}
                        onClose={() => setOpenArticle(false)}
                    />
                )
            }

            <Author
                image={article.authorID?.image}
                name={`${article.authorID?.name} ${article.authorID?.lastName}`}
            />

            <div style={{ paddingLeft: 40 }}>
                <Summary
                    title={article.title}
                    summary={article.summary}
                    categoryID={article.categoryID}
                />

                <Actions
                    comments={article.comments?.length || 0}
                    likes={article?.likes?.length || 0}
                    shares={article?.shares?.length || 0}
                    share={handleShareArticle}
                    like={handleLikeArticle}
                    showMore={() => setOpenArticle(true)}
                    setShowComments={handleShowComments}
                />

                <div className="card__comments">
                    {
                        showComments === article?._id && (
                            <List
                                className="full__width"
                                key={article._id}
                                dataSource={comments}
                                locale={{
                                    emptyText: t('TABLE.EMPTY'),
                                }}
                                loading={loadingComments}
                                renderItem={(comment: IComment) => (
                                    <List.Item
                                        key={comment._id}
                                        className="full__width"
                                        style={{ padding: 8 }}
                                    >
                                        <Comment
                                            comment={comment}
                                            props={props}
                                        />
                                    </List.Item>
                                )}
                            />
                        )
                    }
                </div>
                <PostComment
                    action={(values: any) => addComment(article._id as string, values)}
                />
            </div>
        </Card>
    );
};

export default CardArticle;
