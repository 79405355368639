import {
    Row, Col, Space, Typography, Alert,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';

import ResetPasswordForm from '../../components/Forms/ResetPasswordForm';
import WelcomeMessage from '../../components/Login/WelcomeMessage';
import { theme } from '../../theme';
import LogoRectoral from '../../widgets/Logo';

const { Title } = Typography;

const ResetPassword = () => {
    const { t } = useTranslation('shared', { keyPrefix: 'RESET_PASSWORD' });

    const { resetPassword } = useSelector((state: any) => state.auth);

    const [params] = useSearchParams();
    const token = params.get('token');

    return (
        <Row className="full__size">
            <Col xs={24} lg={12} className="wrapper wrapper__reset-password">
                <div className="forms__main">
                    <Space direction="vertical" size={24} className="full__width">
                        <LogoRectoral
                            size={{ width: 150 }}
                            type="dark"
                            direction="horizontal"
                            className="responsive"
                        />

                        <Title level={2}>
                            {t('HEADER')}
                        </Title>
                    </Space>

                    <Space direction="vertical" size={24} className="full__width">
                        {
                            !token && (
                                <Alert
                                    message={t('NO_TOKEN')}
                                    type="warning"
                                    showIcon
                                />
                            )
                        }

                        {
                            resetPassword === 'success' && (
                                <Alert
                                    message={t('SUCCESS')}
                                    type="success"
                                    showIcon
                                />
                            )
                        }

                        {
                            resetPassword === 'error' && (
                                <Alert
                                    message={t('ERROR')}
                                    type="error"
                                    showIcon
                                />
                            )
                        }

                        <ResetPasswordForm />

                        <Space
                            className="dont-has-account"
                            direction="horizontal"
                            size={8}
                        >
                            <Title
                                level={5}
                                style={{
                                    color: theme.black,
                                    fontWeight: 400,
                                }}
                            >
                                {t('GO_TO')}
                            </Title>

                            <Link to="/login">
                                <Title level={5}>
                                    {t('LOGIN')}
                                </Title>
                            </Link>
                        </Space>
                    </Space>

                </div>
            </Col>
            <Col xs={0} lg={12} className="wrapper sliders">
                <WelcomeMessage />
            </Col>
        </Row>
    );
};

export default ResetPassword;
