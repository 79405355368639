import { createContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { initialContext } from './webinars.initial';
import { IContext } from './webinars.interface';
import webinarsAPI from '../../../../api/webinars';
import { IAxiosError, IAxiosResponse } from '../../../../interfaces';

export const WebinarsContext = createContext<IContext>(initialContext);

export const WebinarsProvider = ({ children }: { children: React.ReactNode}) => {

    const [data, setData] = useState<any[]>(initialContext.data);
    const [itemSelected, setItemSelected] = useState<any | null>(initialContext.itemSelected);
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [actionLoading, setActionLoading] = useState<boolean>(initialContext.actionLoading);
    const [errors, setErrors] = useState<string[]>(initialContext.errors);
    const [total, setTotal] = useState<number>(initialContext.total);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paginator, setPaginator] = useState(initialContext.paginator);
    const [categories, setCategories] = useState<any[]>(initialContext.categories);

    const search = searchParams.get('search') || '';
    const categoryID = searchParams.get('categoryID') || '';

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({
            page,
            limit: pageSize,
        });
    };

    const navigate = useNavigate();

    const getParams = () => {
        const params: any = { };
        if (search) params.search = search;
        if (categoryID) params.categoryID = categoryID;
        return params;
    };

    const handleSearch = (search: string) => {
        setSearchParams({ ...getParams(), search });
        setPaginator(initialContext.paginator);
    };

    const handleCategory = (categoryID: string) => {
        setSearchParams({ ...getParams(), categoryID });
        setPaginator(initialContext.paginator);
    };

    const fetchData = async () => {
        setLoading(true);
        setErrors([]);

        const params = {
            ...getParams(),
            ...paginator,
        };

        webinarsAPI.getWebinars(params)
            .then((response: Partial<IAxiosResponse>) => {
                const { data, meta } = response;
                setData(data);
                setTotal(meta?.itemCount || 0);
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setLoading(false));
    };

    const fetchItem = async () => {
        console.log('fetchItem');
    };

    const fetchCategories = async () => {
        webinarsAPI.getWebinarsCategories()
            .then((response: Partial<IAxiosResponse>) => {
                setCategories(response.data);
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            });
    };

    const addItem = async (payload: any) => {
        setActionLoading(true);
        setErrors([]);

        webinarsAPI.createWebinar(payload)
            .then((response: Partial<IAxiosResponse>) => {
                const { data: newItem } = response;
                setData([newItem, ...data]);
                setTotal(total + 1);
                navigate('/admin/webinars');
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setActionLoading(false));
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <WebinarsContext.Provider
            value={{
                data,
                itemSelected,
                loading,
                actionLoading,
                total,
                searchParams,
                paginator,
                errors,
                categories,
                onPageChange,
                fetchData,
                fetchItem,
                fetchCategories,
                handleSearch,
                handleCategory,
                setItemSelected,
                addItem,
            }}
        >
            {children}
        </WebinarsContext.Provider>
    );

};
