import {
    Avatar, Button, PageHeader, Space, Tag, Tooltip, Typography,
} from 'antd';
import { BsThreeDots } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import IMAGES from '../../constants/images';
import { IAcademic, IImage, IMembership } from '../../interfaces';
import { getCountry } from '../../utils/countries';

interface Props {
    image: IImage,
    name: string;
    actions?: any;
    country?: string;
    type?: string;
    academic?: IAcademic;
    membership?: IMembership
}

const { Title, Text } = Typography;

const Author = ({
    image, name, actions, type, membership, academic, country,
}: Props) => {
    const { t } = useTranslation('shared');

    const countryData = getCountry(country as string);

    return (
        <PageHeader
            title={(
                <div className="flex-row start-row-center">
                    <Avatar
                        src={image?.thumbnail || IMAGES.dashboard.default_avatar}
                        size={28}
                        style={{ verticalAlign: 'middle' }}
                    />
                    <Title
                        level={5}
                        className="none__margin--bottom"
                        style={{ color: type === 'light' ? '#1b4484 !important' : '#FFF !important' }}
                    >
                        {name}
                    </Title>
                </div>
            )}
            subTitle={(
                <Space size={4}>
                    { membership && (
                        <Tag color={membership?.color}>
                            {membership?.name}
                        </Tag>
                    )}
                    { country && (
                        <Tooltip title={countryData?.nativeName}>
                            <Avatar
                                src={countryData?.flag}
                                shape="square"
                                style={{
                                    width: 32,
                                    height: 20,
                                }}
                            />
                        </Tooltip>
                    )}
                </Space>
            )}
            footer={
                academic && (
                    <div
                        style={{ marginTop: '-12px', gap: 12 }}
                        className="flex-column start-column-start"
                    >
                        <Text className="card__date" style={{ fontSize: 12 }}>
                            {academic?.biography}
                        </Text>
                        <Text className="card__date" style={{ fontSize: 12 }}>
                            {`${academic?.institute} - ${academic?.jobPosition}`}
                        </Text>
                    </div>
                )
            }
            extra={actions && [
                <Button
                    type="text"
                    icon={<BsThreeDots color="#1b4484" />}
                />,
            ]}
            style={{ paddingBottom: 12 }}
        />
    );
};

export default Author;
