import {
    Routes,
    Route,
} from 'react-router-dom';

import {
    ArticleDetail,
    Articles,
    Home,
    NewArticle,
    Profile,
    Services,
    Contributions,
    ContributionDetail,
    NewContribution,
    MyLibrary,
    NewDocument,
    DocumentDetail,
} from '../screens';

import { ContributionsProvider } from '../components/Contributions/context/contributionsContext';
import { ArticlesProvider } from '../components/Articles/context/articlesContext';
import { LibraryProvider } from '../components/Library/context/libraryContext';
import { AdminRouter } from './admin.router';
import { CommunityRouter } from './community.router';

export const DashboardRouter = () => (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/profile" element={<Profile />} />
        <Route
            path="/articles"
            element={(
                <ArticlesProvider>
                    <Articles />
                </ArticlesProvider>
            )}
        />
        <Route
            path="/articles/new-article"
            element={(
                <ArticlesProvider>
                    <NewArticle />
                </ArticlesProvider>
            )}
        />
        <Route path="/articles/:articleID" element={<ArticleDetail />} />
        <Route
            path="/contributions"
            element={(
                <ContributionsProvider>
                    <Contributions />
                </ContributionsProvider>
            )}
        />
        <Route
            path="/contributions/:contributionID"
            element={(
                <ContributionsProvider>
                    <ContributionDetail />
                </ContributionsProvider>
            )}
        />
        <Route
            path="/contributions/new-contribution"
            element={(
                <ContributionsProvider>
                    <NewContribution />
                </ContributionsProvider>
            )}
        />
        <Route
            path="/my-library"
            element={(
                <LibraryProvider>
                    <MyLibrary />
                </LibraryProvider>
            )}
        />
        <Route
            path="/my-library/new-document"
            element={(
                <LibraryProvider>
                    <NewDocument />
                </LibraryProvider>
            )}
        />
        <Route
            path="/my-library/:documentID"
            element={(
                <LibraryProvider>
                    <DocumentDetail />
                </LibraryProvider>
            )}
        />
        <Route path="/admin/*" element={<AdminRouter />} />
        <Route path="/community/*" element={<CommunityRouter />} />
    </Routes>
);
