import axios from 'axios';
import { DASHBOARD_API } from '../config';

const getComments = (query: any) => {
    const urlParams = new URLSearchParams(query).toString();
    const url = `${DASHBOARD_API}/comments?${urlParams}`;
    const response = axios.get(url);
    return response;
};

const postComment = (articleID: string, payload: any) => {
    const url = `${DASHBOARD_API}/articles/${articleID}/comment`;
    const response = axios.post(url, payload);
    return response;
};

const postResponseComment = (commentID: string, payload: any) => {
    const url = `${DASHBOARD_API}/comments/${commentID}/response`;
    const response = axios.post(url, payload);
    return response;
};

const likeComment = (commentID: string) => {
    const url = `${DASHBOARD_API}/comments/${commentID}/like`;
    const response = axios.put(url);
    return response;
};

const likeResponseComment = (responseID: string, commentID: string) => {
    const url = `${DASHBOARD_API}/comments/${commentID}/responses/${responseID}/like`;
    const response = axios.put(url);
    return response;
};

export default {
    getComments,
    postComment,
    postResponseComment,
    likeComment,
    likeResponseComment,
};
