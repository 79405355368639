import cs from 'classnames';
import {
    Card, Space, Avatar, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { IMembership } from '../../interfaces';

interface CardMembershipProps {
    form: any;
    membership: IMembership;
    onChange: (membership: IMembership) => void;
}

const { Paragraph } = Typography;

const CardMembership = (props: CardMembershipProps) => {
    const { t: ts } = useTranslation('shared', { keyPrefix: 'MEMBERSHIP' });

    const {
        form, membership, onChange,
    } = props;
    const {
        keyName, name, target, purpose, image, color,
    } = membership;

    const title = `${ts('CATEGORY')} ${name}`;

    return (
        <Card
            style={{ marginBottom: 24 }}
            title={(
                <Space direction="horizontal" size={12}>
                    <Avatar
                        size={28}
                        src={image}
                        shape="square"
                        className="membership__card--avatar"
                    />
                    <span
                        style={{ color }}
                        className="membership__card--title"
                    >
                        {title}
                    </span>
                </Space>
            )}
            hoverable
            key={keyName}
            onClick={() => onChange(membership)}
            className={
                cs(
                    'membership__card',
                    keyName,
                    { active: keyName === form.getFieldValue('membership') },
                )
            }
        >
            <span className="membership__card--target" style={{ color }}>
                {ts(`${target}`)}
            </span>
            <Paragraph className="membership__card--purpose">
                {ts(`${purpose}`)}
            </Paragraph>
        </Card>
    );
};

export default CardMembership;
