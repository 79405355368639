import cs from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { FiChevronRight } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import { STEPS } from '../../constants/steps';

const Steps = () => {
    const { t } = useTranslation('onboarding');

    const [currentStep, setCurrentStep] = useState<number>(0);
    const { pathname } = useLocation();

    useEffect(() => {
        const step = STEPS.findIndex((step) => pathname.includes(step.path));
        setCurrentStep(step);
    }, [pathname]);

    return (
        <div
            className={cs(
                'steps',
                { start: currentStep === 0 },
                { middle: currentStep === 1 },
                { end: currentStep === 2 },
            )}
        >
            {
                STEPS.map((data, index) => (
                    <div
                        key={index}
                        className={cs('step', { active: index <= currentStep })}
                    >
                        {
                            index < currentStep ? (
                                <BsFillCheckCircleFill className={cs('icon', 'complete')} />
                            ) : (
                                <div className={cs('number', { active: index <= currentStep })}>
                                    <span>{index + 1 }</span>
                                </div>
                            )
                        }
                        <span className="name">
                            {t(data.name)}
                        </span>
                        <FiChevronRight className={cs('icon', 'end', { completed: index <= currentStep })} />
                    </div>
                ))
            }
        </div>
    );
};

export default Steps;
