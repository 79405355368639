import axios from 'axios';
import { DASHBOARD_API } from '../config';

const activateUserService = (payload: any) => {
    const url = `${DASHBOARD_API}/user-services/activate`;
    const response = axios.post(url, payload);
    return response;
};

const updateUserServiceData = (payload: any) => {
    const url = `${DASHBOARD_API}/user-services/answers`;
    const response = axios.put(url, payload);
    return response;
};

export default {
    activateUserService,
    updateUserServiceData,
};
