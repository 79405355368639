import {
    Avatar, Space, Table, Tooltip, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { BiEdit } from 'react-icons/bi';
import { AiOutlineUser } from 'react-icons/ai';
import { IUser } from '../../../../interfaces';
import { getCountry } from '../../../../utils/countries';

const { Text } = Typography;

interface Props {
    loading: boolean;
    total: number;
    users: IUser[];
    limit: number;
    page: number;
    onPageChange: (page: number, pageSize: number) => void;
}

const TableUsers = ({
    loading, total, users, onPageChange, limit, page,
}: Props) => {
    const { t } = useTranslation('admin', { keyPrefix: 'TABLE' });
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const navigate = useNavigate();

    const handleAction = (action: string, user: IUser) => {
        switch (action) {
        case 'EDIT':
            navigate(`/admin/users/${user._id}`);
            break;
        case 'DELETE':
            break;
        default:
            break;
        }
    };

    const COLUMNS: ColumnsType<any> = [
        {
            title: t('MEMBER'),
            dataIndex: 'image',
            key: 'image',
            fixed: true,
            width: 250,
            render: (image: string, user: IUser) => {
                const picture = user.image.original;
                return (
                    <Space direction="horizontal" size={8}>
                        <Avatar
                            src={picture || <AiOutlineUser color="#FFF" />}
                            style={{
                                backgroundColor: '#1b4484',
                                marginRight: 8,
                                width: 32,
                                height: 32,
                            }}
                        />
                        <Space size={0} direction="vertical">
                            <Text className="table__summary">
                                {`${user.name} ${user.lastName}`}
                            </Text>
                            <Text
                                className="table__summary"
                                style={{
                                    color: '#999',
                                    fontSize: 14,
                                }}
                            >
                                {user.email}
                            </Text>
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: t('MOBILE'),
            dataIndex: 'mobile',
            key: 'fullMobile',
            render: (mobile: string, user: IUser) => (
                user.contact.fullMobile ? (
                    <Text>
                        {user.contact.fullMobile}
                    </Text>
                ) : (
                    <Text className="table__not-available">
                        {t('NOT_AVAILABLE')}
                    </Text>
                )
            ),
        },
        {
            title: t('COUNTRY'),
            dataIndex: 'country',
            key: 'country',
            align: 'center' as 'center',
            render: (_: any, user: IUser) => {
                const code = user.country;
                const country = getCountry(code);
                return (
                    <Tooltip title={country?.nativeName}>
                        <Avatar
                            src={country?.flag}
                            shape="square"
                            style={{
                                width: 32,
                                height: 20,
                            }}
                        />
                    </Tooltip>
                );
            },
        },
        {
            title: t('MEMBERSHIP'),
            dataIndex: 'membership',
            key: 'membership',
            render: (_: any, user: IUser) => (
                <Text
                    className="table__not-available"
                    style={{
                        backgroundColor: user.membership?.color,
                        color: user.membership?.keyName ? '#fff' : '#000',
                        fontWeight: 600,
                        textTransform: 'capitalize',
                    }}
                >
                    {user.membership?.name || t('NOT_AVAILABLE')}
                </Text>
            ),
        },
        {
            title: t('ACADEMIC_DATA'),
            children: [
                {
                    title: t('BIOGRAPHY'),
                    dataIndex: 'biography',
                    key: 'biography',
                    render: (_: any, user: IUser) => (
                        <Tooltip title={user?.academic?.biography} placement="bottomLeft">
                            <Text
                                className="table__summary"
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    width: '100%',
                                    maxHeight: '100px',
                                    display: 'inline-block',
                                }}
                            >
                                {user?.academic?.biography || t('NOT_AVAILABLE')}
                            </Text>
                        </Tooltip>
                    ),
                },
                {
                    title: t('JOB_POSITION'),
                    dataIndex: 'institute',
                    key: 'jobPosition',
                    render: (_: any, user: IUser) => (
                        <Text className="table__summary">
                            {user?.academic?.jobPosition || t('NOT_AVAILABLE')}
                        </Text>
                    ),
                },
                {
                    title: t('INSTITUTE'),
                    dataIndex: 'institute',
                    key: 'institute',
                    align: 'center' as 'center',
                    render: (_: any, user: IUser) => (
                        <Text className="table__summary">
                            {user?.academic?.institute || t('NOT_AVAILABLE')}
                        </Text>
                    ),
                },
                {
                    title: t('INSTITUTE_TYPE'),
                    dataIndex: 'instituteType',
                    key: 'instituteType',
                    render: (_: any, user: IUser) => (
                        <Text className="table__summary">
                            {tf(`INPUTS.INSTITUTE_TYPES.${user?.academic?.instituteType}`) || t('NOT_AVAILABLE')}
                        </Text>
                    ),
                },
                {
                    title: t('DESIGNATION'),
                    dataIndex: 'designation',
                    key: 'designation',
                    render: (_: any, user: IUser) => (
                        <Text className="table__summary">
                            {user?.academic?.designation || t('NOT_AVAILABLE')}
                        </Text>
                    ),
                },
            ],
        },
        {
            title: t('ACTIONS'),
            dataIndex: 'actions',
            key: 'actions',
            fixed: 'right' as 'right',
            width: 160,
            render: (actions: any, user: IUser) => (
                <div className="table__actions">
                    <BiEdit size={28} className="table__actions-icon" onClick={() => handleAction('EDIT', user)} />
                    {/* <MdDelete size={28} className="table__actions-icon delete" onClick={() => handleAction('DELETE', user)} /> */}
                </div>
            ),
        },
    ];

    /* if (loading) return <Loading />; */

    return (
        <Table
            className="table"
            bordered
            rowKey="_id"
            loading={loading}
            columns={COLUMNS}
            dataSource={users}
            scroll={{ x: 1500 }}
            pagination={{
                total,
                pageSize: limit,
                current: page,
                onChange: onPageChange,
                showSizeChanger: false,
                size: 'small',
            }}
        />
    );
};

export default TableUsers;
