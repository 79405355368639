import { IPalette } from './interfaces/palette';
import { variables as vars } from './styles/variables';

export const theme: IPalette = {
    black: vars.black,
    blue: vars.blue,
    green: vars.green,
    magenta: vars.magenta,
    neutralDark: vars.neutralDark,
    neutralLight: vars.neutralLight,
    neutralLighter: vars.neutralLighter,
    neutralLighterAlt: vars.neutralLighterAlt,
    neutralPrimary: vars.neutralPrimary,
    neutralPrimaryAlt: vars.neutralPrimaryAlt,
    neutralQuaternary: vars.neutralQuaternary,
    neutralQuaternaryAlt: vars.neutralQuaternaryAlt,
    neutralSecondary: vars.neutralSecondary,
    neutralSecondaryAlt: vars.neutralSecondaryAlt,
    neutralTertiary: vars.neutralTertiary,
    neutralTertiaryAlt: vars.neutralTertiaryAlt,
    orange: vars.orange,
    orangeLight: vars.orangeLight,
    orangeLighter: vars.orangeLighter,
    purple: vars.purple,
    red: vars.red,
    redDark: vars.redDark,
    teal: vars.teal,
    themeDark: vars.themeDark,
    themeDarkAlt: vars.themeDarkAlt,
    themeDarker: vars.themeDarker,
    themeLight: vars.themeLight,
    themeLighter: vars.themeLighter,
    themeLighterAlt: vars.themeLighterAlt,
    themePrimary: vars.themePrimary,
    themeSecondary: vars.themeSecondary,
    themeTertiary: vars.themeTertiary,
    white: vars.white,
    yellow: vars.yellow,
    gold: vars.gold,
    platinum: vars.platinum,
    diamond: vars.diamond,
    legacy: vars.legacy,
    thinkTank: vars.thinkTank,
    brands: vars.brands,
    roadMap: vars.roadMap,
};
