import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button, Col, Form, Input, PageHeader, Row, Typography, notification,
} from 'antd';
import { IAcademic, IUser } from '../../../../interfaces';
import InstituteType from '../../../../widgets/InstituteType';
import Languages from '../../../../widgets/Languages';
import userAPI from '../../../../api/user';

interface Props {
    t: (key: string) => string;
    userData: IUser | null;
}

const { Title } = Typography;
const { TextArea } = Input;

const Academic = ({ t, userData }: Props) => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const RULES: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
    ];

    const RULES_URL: any = [
        { type: 'url', warningOnly: true, message: tf('RULES.INVALID_URL') },
        { type: 'string', min: 6, message: tf('RULES.MIN_LENGTH', { length: 6 }) },
    ];

    const onSubmit = (values: IAcademic) => {
        setLoading(true);

        const payload: IAcademic = {
            ...values,
        };

        userAPI.updateUser(payload, userData?._id || '')
            .then(() => {
                notification.success({
                    message: tf('RESPONSES.UPDATE_SUCCESS'),
                });
            })
            .catch((error: any) => {
                const description = error?.response?.data?.message || error.message;
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        form.setFieldsValue({
            institute: userData?.academic?.institute,
            instituteType: userData?.academic?.instituteType,
            jobPosition: userData?.academic?.jobPosition,
            designation: userData?.academic?.designation,
            biography: userData?.academic?.biography,
            curriculum: userData?.academic?.curriculum,
            languages: userData?.academic?.languages,
        });
    }, [userData]);

    return (
        <div className="profile__manage-tabs-forms">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                className="full__width"
            >
                <PageHeader
                    title={(
                        <Title level={4} style={{ marginBottom: 0 }}>
                            {t('PERSONAL.ACADEMIC_DATA')}
                        </Title>
                    )}
                    extra={(
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {tf('ACTIONS.UPDATE')}
                        </Button>
                    )}
                />
                <Row className="full__size" gutter={[24, 28]}>
                    <Col xs={24} xxl={12} className="col-left">
                        <Form.Item
                            label={tf('INPUTS.ACADEMIC.INSTITUTE')}
                            name="institute"
                            rules={RULES.jobPosition}
                        >
                            <Input placeholder={tf('INPUTS.ACADEMIC.INSTITUTE_PLACEHOLDER')} />
                        </Form.Item>

                        <InstituteType />

                        <Form.Item
                            label={tf('INPUTS.ACADEMIC.DESIGNATION')}
                            name="designation"
                            rules={RULES}
                        >
                            <Input placeholder={tf('INPUTS.ACADEMIC.DESIGNATION_PLACEHOLDER')} />
                        </Form.Item>

                        <Form.Item
                            label={tf('INPUTS.ACADEMIC.JOB_POSITION')}
                            name="jobPosition"
                            rules={RULES}
                        >
                            <Input placeholder={tf('INPUTS.ACADEMIC.JOB_POSITION_PLACEHOLDER')} />
                        </Form.Item>

                    </Col>
                    <Col xs={24} xxl={12} className="col-right">

                        <Form.Item
                            label={tf('INPUTS.ACADEMIC.CURRICULUM')}
                            name="curriculum"
                            rules={RULES_URL}
                        >
                            <Input
                                addonBefore="https://"
                                placeholder={tf('INPUTS.ACADEMIC.CURRICULUM_PLACEHOLDER')}
                            />
                        </Form.Item>

                        <Form.Item
                            label={tf('INPUTS.ACADEMIC.BIOGRAPHY')}
                            name="biography"
                            rules={RULES}
                        >
                            <TextArea
                                placeholder={tf('INPUTS.ACADEMIC.BIOGRAPHY_PLACEHOLDER')}
                                maxLength={300}
                                showCount
                            />
                        </Form.Item>

                        <Languages />

                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default Academic;
