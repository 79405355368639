import { message } from 'antd';
import { createContext, useEffect, useState } from 'react';
import { IContext } from './community.interface';
import { initial } from './community.initial';
import {
    IArticle,
    IAxiosError,
    IAxiosResponse,
    ICategory,
    IComment,
    IResponse,
} from '../../../interfaces';
import articlesAPI from '../../../api/articles';
import commentsAPI from '../../../api/comments';

interface Props {
    children: React.ReactNode;
}

export const CommunityContext = createContext<IContext>(initial);

export const CommunityProvider = ({ children }: Props) => {
    const [loading, setLoading] = useState<boolean>(initial.loading);
    const [loadingComments, setLoadingComments] = useState<boolean>(initial.loadingComments);
    const [errors, setErrors] = useState<string[]>(initial.errors);
    const [action, setAction] = useState<string>(initial.action);
    const [articles, setArticles] = useState<IArticle[]>(initial.articles);
    const [contributions, setContributions] = useState(initial.contributions);
    const [total, setTotal] = useState<number>(initial.total);
    const [paginator, setPaginator] = useState(initial.paginator);
    const [search, setSearch] = useState<string>(initial.search);
    const [category, setCategory] = useState<string>(initial.category);
    const [categories, setCategories] = useState<ICategory[]>(initial.categories);
    const [showComments, setShowComments] = useState<string | null>('');
    const [comments, setComments] = useState<IComment[]>(initial.comments);

    const fetchAllArticles = (search?: string, category?: string) => {
        setLoading(true);
        setErrors([]);

        const params: any = { ...paginator };

        if (search) params.search = search;
        if (category) params.categoryID = category;

        articlesAPI.getAllArticles(params)
            .then((response: Partial<IAxiosResponse>) => {
                const { data, meta } = response;
                setArticles(data);
                setTotal(meta?.itemCount || 0);
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setLoading(false));
    };

    const fetchAllCategories = () => {
        articlesAPI.getCategories()
            .then((response: Partial<IAxiosResponse>) => {
                const { data } = response;
                setCategories(data);
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error?.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            });
    };

    const fetchComments = () => {
        setLoadingComments(true);

        const params = {
            articleID: showComments || '',
        };

        commentsAPI.getComments(params)
            .then((response: Partial<IAxiosResponse>) => setComments(response.data))
            .catch((error: Partial<IAxiosError>) => {
                const description = error?.response?.data?.error || 'Something went wrong';
                message.error({ content: description });
            })
            .finally(() => setLoadingComments(false));
    };

    const handleSearch = (value: string) => {
        setSearch(value);
        fetchAllArticles(value, category);
    };

    const handleCategory = (value: string) => {
        setCategory(value);
        fetchAllArticles(search, value);
    };

    const likeComment = (commentID: string) => {
        commentsAPI.likeComment(commentID)
            .then((response: Partial<IAxiosResponse>) => {
                const newComments = comments.map((comment) => {
                    if (comment._id === commentID) {
                        comment.likes = response.data.likes;
                    }
                    return comment;
                });
                setComments(newComments);
            })
            .catch((error: Partial<IAxiosError>) => {
                const description = error?.response?.data?.error || 'Something went wrong';
                message.error({ content: description });
            });
    };

    const likeResponseComment = (responseID: string, commentID: string) => {
        commentsAPI.likeResponseComment(responseID, commentID)
            .then((response: Partial<IAxiosResponse>) => {
                const newResponse = response.data;
                const newComments = comments.map((comment: IComment) => {
                    if (comment._id === commentID) {
                        const newResponses = comment.responses.map((responseComment: IResponse) => {
                            if (responseComment._id === responseID) {
                                responseComment.likes = newResponse.likes;
                            }
                            return responseComment;
                        });
                        comment.responses = newResponses;
                    }
                    return comment;
                });
                setComments(newComments);
            })
            .catch((error: Partial<IAxiosError>) => {
                const description = error?.response?.data?.error || 'Something went wrong';
                message.error({ content: description });
            });

    };

    const likeArticle = (articleID: string) => {
        articlesAPI.likeArticle(articleID)
            .then((response: Partial<IAxiosResponse>) => {
                const newArticle = response.data;
                const newArticles = articles.map((article) => {
                    if (article._id === articleID) {
                        article.likes = newArticle.likes;
                    }
                    return article;
                });
                setArticles(newArticles);
            })
            .catch((error: Partial<IAxiosError>) => {
                const description = error?.response?.data?.error || 'Something went wrong';
                message.error({ content: description });
            });
    };

    const shareArticle = (articleID: string) => {
        articlesAPI.shareArticle(articleID)
            .then((response: Partial<IAxiosResponse>) => {
                const newArticle = response.data;
                const newArticles = articles.map((article) => {
                    if (article._id === articleID) {
                        article.shares = newArticle.shares;
                    }
                    return article;
                });
                setArticles(newArticles);
            })
            .catch((error: Partial<IAxiosError>) => {
                const description = error?.response?.data?.error || 'Something went wrong';
                message.error({ content: description });
            });
    };

    const addComment = (articleID: string, payload: any) => {
        commentsAPI.postComment(articleID, payload)
            .then((response: Partial<IAxiosResponse>) => {
                const newComments = [response.data, ...comments];
                setComments(newComments);

                const newArticles = articles.map((article) => {
                    if (article._id === articleID) {
                        article.comments = [...article.comments, response.data._id];
                    }
                    return article;
                });
                setArticles(newArticles);
            })
            .catch((error: Partial<IAxiosError>) => {
                const description = error?.response?.data?.error || 'Something went wrong';
                message.error({ content: description });
            });
    };

    const addResponse = (commentID: string, payload: any) => {
        commentsAPI.postResponseComment(commentID, payload)
            .then((response: Partial<IAxiosResponse>) => {
                const newResponse = response.data;
                const newComments = comments.map((comment: IComment) => {
                    if (comment._id === commentID) {
                        comment.responses = [...comment.responses, newResponse];
                    }
                    return comment;
                });
                setComments(newComments);
            })
            .catch((error: Partial<IAxiosError>) => {
                const description = error?.response?.data?.error || 'Something went wrong';
                message.error({ content: description });
            });
    };

    useEffect(() => {
        fetchAllCategories();
    }, []);

    return (
        <CommunityContext.Provider
            value={{
                loading,
                loadingComments,
                errors,
                action,
                articles,
                contributions,
                total,
                paginator,
                search,
                categories,
                category,
                setAction,
                handleSearch,
                handleCategory,
                fetchAllArticles,
                showComments,
                setShowComments,
                fetchComments,
                comments,
                setComments,
                likeComment,
                likeResponseComment,
                likeArticle,
                shareArticle,
                addComment,
                addResponse,
            }}
        >
            {children}
        </CommunityContext.Provider>
    );
};
