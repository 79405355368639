import esES from 'antd/es/locale/es_ES';
import enUS from 'antd/es/locale/en_US';
import ptPT from 'antd/es/locale/pt_PT';
import {
    Button, PageHeader, Row, Col, Form, Input, DatePicker, ConfigProvider, DatePickerProps, notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import TextEditor from '../../Articles/components/TextEditor';
import Country from '../../../widgets/Country';
import SearchUser from '../../SearchUser';
import { IUser } from '../../../interfaces';
import InstituteType from '../../../widgets/InstituteType';
import { ReviewsContext } from './context/reviews.context';

const CreateReview = () => {
    const { t, i18n } = useTranslation('admin');
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });
    const { t: ti } = useTranslation('forms', { keyPrefix: 'FORMS.INPUTS' });

    const {
        reviews, errors, loadAction, addReview,
    } = useContext(ReviewsContext);

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const onSubmit = (values: any) => {
        const payload = {
            ...values,
        };

        addReview(payload);

        if (!loadAction) {
            if (errors.length === 0) {
                notification.success({ message: tf('RESPONSES.SUCCESS') });
                navigate(-1);
            } else {
                notification.error({
                    message: 'Error',
                    description: errors[0] || tf('SOMENTHING_WENT_WRONG'),
                });
            }
        }
    };

    const RULES: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
    ];

    const RULES_URL: any = [
        { type: 'url', warningOnly: true, message: tf('RULES.INVALID_URL') },
        { type: 'string', min: 6, message: tf('RULES.MIN_LENGTH', { length: 6 }) },
    ];

    const handleSelectUser = (user: IUser) => {
        form.setFieldsValue({
            name: user.name,
            lastName: user.lastName,
            email: user.email,
            country: user.country,
            jobPosition: user.academic.jobPosition,
            institute: user.academic.institute,
            instituteType: user.academic.instituteType,
            image: user?.image,
        });
    };

    const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
        form.setFieldsValue({ publishDate: date?.toISOString() });
    };

    return (
        <div className="articles__edit">
            <Form
                form={form}
                layout="vertical"
                name="add-review"
                onFinish={onSubmit}
            >
                <PageHeader
                    className="site-page-header-responsive"
                    title={t('CREATE_REVIEW')}
                    onBack={() => {
                        form.resetFields();
                        navigate(-1);
                    }}
                    extra={[
                        <Button
                            type="primary"
                            onClick={() => form.submit()}
                        >
                            {t('ADD')}
                        </Button>,
                    ]}
                    footer={(

                        <Row className="full__width" gutter={[24, 28]}>
                            <Col xs={24} xl={12} className="col-left">
                                <div className="services__container none__margin--top none__padding--medium">
                                    <div className="services__container-form">
                                        <Form.Item name="review" hidden>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name="image" hidden>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label={ti('REVIEW.TITLE')}
                                            name="title"
                                            rules={RULES}
                                        >
                                            <Input placeholder={ti('REVIEW.TITLE_PLACEHOLDER')} />
                                        </Form.Item>

                                        <Form.Item
                                            label={ti('REVIEW.DESCRIPTION')}
                                            rules={RULES}
                                        >
                                            <TextEditor
                                                form={form}
                                                contentName="review"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label={ti('KEY_NAME')}
                                            name="keyName"
                                            rules={RULES}
                                        >
                                            <Input
                                                placeholder={ti('KEY_NAME_PLACEHOLDER')}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label={ti('EXTERNAL_LINK')}
                                            name="externalLink"
                                            rules={RULES_URL}
                                        >
                                            <Input
                                                placeholder={ti('EXTERNAL_LINK_PLACEHOLDER')}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label={ti('PUBLISH_DATE')}
                                            name="publishDate"
                                        >
                                            <ConfigProvider
                                                locale={
                                                    i18n.language === 'en'
                                                        ? enUS : i18n.language === 'pt'
                                                            ? ptPT : esES
                                                }
                                            >
                                                <DatePicker className="full__width" onChange={onChangeDate} />
                                            </ConfigProvider>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} xl={12} className="col-right">
                                <div className="services__container none__margin--top none__padding--medium">
                                    <div className="services__container-form">

                                        <SearchUser
                                            handleSelect={handleSelectUser}
                                        />

                                        <Row gutter={[16, 16]} className="full__width">
                                            <Col span={12} className="col-left">
                                                <Form.Item
                                                    label={ti('NAME')}
                                                    name="name"
                                                    rules={RULES}
                                                >
                                                    <Input
                                                        placeholder={ti('NAME_PLACEHOLDER')}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label={ti('EMAIL')}
                                                    name="email"
                                                    rules={RULES}
                                                >
                                                    <Input
                                                        placeholder={ti('EMAIL_PLACEHOLDER')}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label={ti('ACADEMIC.JOB_POSITION')}
                                                    name="jobPosition"
                                                    rules={RULES}
                                                >
                                                    <Input
                                                        placeholder={ti('ACADEMIC.JOB_POSITION_PLACEHOLDER')}
                                                    />
                                                </Form.Item>
                                                <InstituteType />
                                            </Col>
                                            <Col span={12} className="col-right">
                                                <Form.Item
                                                    label={ti('LAST_NAME')}
                                                    name="lastName"
                                                    rules={RULES}
                                                >
                                                    <Input
                                                        placeholder={ti('LAST_NAME_PLACEHOLDER')}
                                                    />
                                                </Form.Item>
                                                <Country />
                                                <Form.Item
                                                    label={ti('INSTITUTE')}
                                                    name="institute"
                                                    rules={RULES}
                                                >
                                                    <Input
                                                        placeholder={ti('INSTITUTE_PLACEHOLDER')}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )}
                />
            </Form>
        </div>
    );
};

export default CreateReview;
