import { PageHeader, Space } from 'antd';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StudyGroupsContext } from './context/study-groups.context';
import Filters from './components/Filters';
import TableStudyGroups from './components/Table';
import ModalWrapper from '../../Modals';
import Members from './components/Members';

const StudyGroups = ({ params }: { params: URLSearchParams }) => {
    const { t } = useTranslation('admin');

    const props = useContext(StudyGroupsContext);

    const {
        fetchStudyGroups, searchParams, paginator, total, studyGroup, setStudyGroup,
    } = props;

    const { search } = searchParams;
    const defaultSearch = params.get('search');

    useEffect(() => {
        fetchStudyGroups();
    }, [paginator, search, defaultSearch]);

    return (
        <div className="articles__edit">
            {
                studyGroup && (
                    <ModalWrapper
                        visible={studyGroup !== null}
                        onCancel={() => {
                            setStudyGroup(null);
                        }}
                    >
                        <Members {...props} />
                    </ModalWrapper>
                )
            }
            <PageHeader
                className="site-page-header-responsive"
                title={`${t('ADMIN_STUDY_GROUPS')} (${total || 0})`}
                footer={(
                    <Space direction="vertical" size={16} className="full__width">
                        <Filters
                            defaultSearch={defaultSearch}
                            props={props}
                        />
                        <TableStudyGroups {...props} />
                    </Space>
                )}
            />
        </div>
    );
};

export default StudyGroups;
