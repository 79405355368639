import { POSITION_LANGUAGE } from '../enums/languages.enum';

const getPositionLanguage = (language: string) => {
    const languageKeys: any = language.toUpperCase() as keyof typeof POSITION_LANGUAGE;
    return POSITION_LANGUAGE[languageKeys as 'ES' | 'EN' | 'PT'];
};

export {
    getPositionLanguage,
};
