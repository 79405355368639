import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Props {
    form: any;
    contentName?: string;
}

const TextEditor = ({ form, contentName }: Props) => {
    const [value, setValue] = useState<string>('');

    const onChange = (valueEditor: any) => {
        setValue(valueEditor);
        form.setFieldsValue({
            [contentName as keyof string || 'content']: valueEditor,
        });
    };

    useEffect(() => {
        setValue(form.getFieldValue(contentName as keyof string || 'content'));
    }, [form.getFieldValue(contentName as keyof string || 'content')]);

    return (
        <div className="editor">
            <ReactQuill
                className="editor__quill"
                theme="snow"
                value={value}
                onChange={onChange}
            />
        </div>
    );
};

export default TextEditor;
