import { Typography } from 'antd';

const { Paragraph } = Typography;
const Copyright = () => (
    <Paragraph className="copyright center">
        © Rectoral Board - All rights reserved
    </Paragraph>
);

export default Copyright;
