import { Form, Button, notification } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IQuestion } from '../../interfaces';
import Question from './Question';
import servicesAPI from '../../api/services';
import { updateServices } from '../../store/slices/auth';

interface Props {
    t: (key: string) => string;
    form: any;
    services: any;
    keyName: string;
    questions: IQuestion[];
}

const FormServices = ({
    t, form, services, keyName, questions,
}: Props) => {
    const dispatch = useDispatch<any>();

    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = (values: any) => {
        setLoading(true);
        const service = services.find((service: any) => service.keyName === keyName);
        const { questions } = service;

        const newQuestions = Object.keys(values).map((key: string) => ({
            keyName: key,
            type: questions.find((question: any) => question.keyName === key).type,
            answer: values[key],
        }));

        const payload = {
            service: keyName,
            questions: newQuestions,
        };

        const dataToUpdate = [
            ...services.map((service: any) => {
                if (service.keyName === keyName) {
                    return {
                        ...service,
                        questions: newQuestions,
                    };
                }
                return service;
            }),
        ];

        servicesAPI.updateUserServiceData(payload)
            .then(() => {
                notification.success({
                    message: t('NOTIFICATIONS.SUCCESS'),
                    description: t('NOTIFICATIONS.SERVICE_UPDATED'),
                });
                dispatch(updateServices(dataToUpdate));
            })
            .catch((error: any) => {
                const description = error.response?.data?.message;
                notification.error({
                    message: t('NOTIFICATIONS.ERROR'),
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            className="services__container-form"
        >
            { questions.map((question: IQuestion) => <Question t={t} question={question} />)}
            <Button
                block
                type="primary"
                htmlType="submit"
                loading={loading}
            >
                {t('ACTIONS.UPDATE')}
            </Button>
        </Form>
    );
};

export default FormServices;
