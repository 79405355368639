export const enum EContributionCategory {
    NETWORK = 'NETWORK',
    IES = 'IES',
    GOVERNMENT = 'GOVERNMENT'
}

export const enum EContributionStatus {
    ACTIVE = 'ACTIVE',
    FINISHED = 'FINISHED',
}
