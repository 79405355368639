import axios from 'axios';
import { DASHBOARD_API } from '../config';
import { IAcademic } from '../interfaces';

const updateUser = (payload: IAcademic, userID: string) => {
    const url = `${DASHBOARD_API}/users/${userID}/academic`;
    const response = axios.put(url, payload);
    return response;
};

const updatePersonalDataUser = (payload: any, userID: string) => {
    const url = `${DASHBOARD_API}/users/basic-data`;
    const response = axios.put(url, payload);
    return response;
};

const updateMembership = (payload: string, userID: string) => {
    const url = `${DASHBOARD_API}/users/${userID}/membership`;
    const response = axios.put(url, payload);
    return response;
};

const updateWelcomePhraseDataUser = (payload: any) => {
    const url = `${DASHBOARD_API}/users/welcome-phrase/update`;
    const response = axios.put(url, payload);
    return response;
};

const sendMailToResetPassword = (query: string) => {
    const urlParams = new URLSearchParams(query).toString();
    const url = `${DASHBOARD_API}/users/reset-password/send?${urlParams}`;
    const response = axios.post(url);
    return response;
};

const resetPassword = (token: string, password: string) => {
    const urlParams = new URLSearchParams({ token, password }).toString();
    const url = `${DASHBOARD_API}/users/reset-password/update?${urlParams}`;
    const response = axios.put(url);
    return response;
};

const updateSettings = (payload: any) => {
    const url = `${DASHBOARD_API}/users/update-settings`;
    const response = axios.put(url, payload);
    return response;
};

export default {
    updateUser,
    updateMembership,
    sendMailToResetPassword,
    resetPassword,
    updatePersonalDataUser,
    updateSettings,
    updateWelcomePhraseDataUser,
};
