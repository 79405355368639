import {
    Form, Typography, Divider, Button, Alert, Space, notification,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { sendMailToResetPassword } from '../../../store/slices/auth';
import { theme } from '../../../theme';

import SendEmail from '../../Forms/SendEmailForm';
import styles from '../styles.module.scss';

const { Title, Paragraph } = Typography;

const FormResetPassword = () => {
    const { t } = useTranslation('forms', { keyPrefix: 'FORMS' });
    const { error, loading } = useSelector((state: any) => state.auth);
    const [form] = Form.useForm();

    const dispatch = useDispatch<any>();
    const language = localStorage.getItem('i18nextLng');

    const onSubmit = (values: any) => {
        const payload = {
            ...values,
            lang: language?.split('-')[1] || 'ES',
        };
        dispatch(sendMailToResetPassword(payload));
        if (!error) {
            form.resetFields();
            notification.success({
                message: t('RESPONSES.SUCCESS'),
            });
        }
    };

    return (
        <div className={styles.containerForm}>
            <Form
                layout="vertical"
                form={form}
                className={styles.form}
                onFinish={onSubmit}
            >
                <Title level={2} style={{ color: theme.themePrimary }}>
                    {t('RESET_PASSWORD')}
                </Title>

                <Paragraph>
                    {t('RESET_PASSWORD_DESCRIPTION')}
                </Paragraph>

                <Space direction="vertical" size={24} className="full__width">
                    {
                        error && (
                            <Alert
                                message={t(error?.error)}
                                type="error"
                                showIcon
                            />
                        )
                    }
                    <SendEmail />
                </Space>

                <Divider />

                <Button
                    block
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                >
                    {t('ACTIONS.SEND')}
                </Button>

            </Form>
        </div>
    );
};

export default FormResetPassword;
