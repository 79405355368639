import { Button, Space } from 'antd';
import { Trans } from 'react-i18next';
import { BsLightbulbFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

interface Props {
    t: (key: string) => string;
    path: string;
    nameButton: string;
}

const EmptyData = ({ t, path, nameButton }: Props) => {
    const navigate = useNavigate();
    return (
        <Space size={16} direction="vertical">
            <div className="empty-data">
                <BsLightbulbFill size={14} className="empty-data-icon" />
                <Trans
                    t={t}
                    i18nKey="EMPTY_DATA"
                    components={{
                        b: <b className="empty-data-text" />,
                    }}
                />
            </div>
            <Button type="primary" onClick={() => navigate(path)}>
                {t(nameButton)}
            </Button>
        </Space>
    );
};

export default EmptyData;
