import moment from 'moment';
import {
    Avatar, Space, Spin, Tooltip, Typography, notification,
} from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdDelete, MdEdit } from 'react-icons/md';
import { IContext } from '../context/reviews.interface';
import { getCountry } from '../../../../utils/countries';

interface IColumn {
    title: string;
    dataIndex: string;
    key: string;
    align: 'center' | 'left' | 'right' | undefined;
    render: (value: any, object?: any) => React.ReactNode;
}

const { Text } = Typography;

const TableReviews = ({ ...props }: IContext) => {
    const { t } = useTranslation('admin', { keyPrefix: 'TABLE' });
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS.INPUTS.INSTITUTE_TYPES' });
    const { t: tr } = useTranslation('forms', { keyPrefix: 'FORMS.RESPONSES' });

    const navigate = useNavigate();

    const {
        loading, loadAction, total, reviews, onPageChange, paginator, setUnavailable,
    } = props;

    const handleAction = (action: string, review: any) => {
        switch (action) {
        case 'EDIT':
            navigate(`/admin/book/reviews/${review._id}`);
            break;
        case 'DELETE':
            setUnavailable(review._id);
            notification.success({
                message: tr('SUCCESS'),
                description: tr('REVIEW_DELETED'),
            });
            break;
        default:
            break;
        }
    };

    const COLUMNS: ColumnsType<IColumn> = [
        {
            title: t('CREATED_AT'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center' as 'center',
            render: (createdAt: string) => (
                <Text className="table__summary">
                    {moment(createdAt).format('DD MMM HH:mm')}
                </Text>
            ),
        },
        {
            title: t('PUBLISH_DATE'),
            dataIndex: 'publishDate',
            key: 'publishDate',
            align: 'center' as 'center',
            render: (publishDate: string, review: any) => (
                <Text className="table__summary">
                    {moment(publishDate || review.createdAt).format('DD MMM HH:mm')}
                </Text>
            ),
        },
        {
            title: t('AUTHOR'),
            dataIndex: 'name',
            key: 'name',
            render: (name: string, review: any) => (
                <Space
                    size={0}
                    direction="vertical"
                    onClick={() => handleAction('VIEW', review)}
                >
                    <Text
                        className="table__summary"
                        style={{ color: '#1976d2', cursor: 'pointer' }}
                    >
                        {`${name} ${review.lastName}`}
                    </Text>
                    <Text
                        className="table__summary"
                        style={{
                            color: '#999',
                            fontSize: 14,
                        }}
                    >
                        {review.email}
                    </Text>
                </Space>
            ),
        },
        {
            title: t('INSTITUTE_TYPE'),
            dataIndex: 'instituteType',
            key: 'instituteType',
            align: 'center' as 'center',
            render: (instituteType: string) => (
                instituteType ? (
                    <Text>
                        {tf(instituteType)}
                    </Text>
                ) : (
                    <Text className="table__not-available">
                        {t('NOT_AVAILABLE')}
                    </Text>
                )
            ),
        },
        {
            title: t('INSTITUTE'),
            dataIndex: 'institute',
            key: 'institute',
            align: 'center' as 'center',
            render: (institute: string) => (
                <Text className="table__summary">
                    {institute}
                </Text>
            ),
        },
        {
            title: t('COUNTRY'),
            dataIndex: 'country',
            key: 'country',
            align: 'center' as 'center',
            render: (countryCode: string) => {
                const country = getCountry(countryCode);
                return (
                    <Tooltip title={country?.nativeName}>
                        <Avatar
                            src={country?.flag}
                            shape="square"
                            style={{
                                width: 32,
                                height: 20,
                            }}
                        />
                    </Tooltip>
                );
            },
        },
        {
            title: t('STATUS'),
            dataIndex: 'isApproved',
            key: 'isApproved',
            render: (isApproved: boolean) => (
                <Text
                    className="table__not-available"
                    style={{
                        color: isApproved ? '#52c41a' : '#8a8886',
                        borderColor: isApproved ? '#52c41a' : '#8a8886',
                    }}
                >
                    {t(isApproved ? 'APPROVED' : 'PENDING')}
                </Text>
            ),
        },
        {
            title: t('ACTIONS'),
            dataIndex: 'actions',
            key: 'actions',
            fixed: 'right' as 'right',
            width: 160,
            render: (actions: any, item: any) => (
                <div className="table__actions">
                    <MdEdit
                        size={28}
                        className="table__actions-icon"
                        onClick={() => handleAction('EDIT', item)}
                    />
                    {
                        loadAction
                            ? <Spin size="small" />
                            : (
                                <MdDelete
                                    size={28}
                                    className="table__actions-icon delete"
                                    onClick={() => handleAction('DELETE', item)}
                                />
                            )
                    }
                </div>
            ),
        },
    ];

    return (
        <Table
            className="table"
            bordered
            rowKey="_id"
            loading={loading}
            columns={COLUMNS}
            dataSource={reviews}
            scroll={{ x: 1500 }}
            pagination={{
                total,
                pageSize: paginator.limit,
                current: paginator.page,
                onChange: onPageChange,
                showSizeChanger: false,
                size: 'small',
            }}
        />
    );
};

export default TableReviews;
