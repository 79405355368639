import moment from 'moment';
import cs from 'classnames';
import {
    Avatar,
    Descriptions,
    Divider,
    Drawer,
    List,
    Space,
    Tooltip,
    Typography,
    Image,
    Tag,
} from 'antd';
import { useTranslation } from 'react-i18next';
import DrawerHeader from './Header';
import { RECTORAL_BOARD_SITE } from '../../config';
import { getPositionLanguage } from '../../utils/languages.util';
import ExternalLinkColumn from '../Table/Link';
import Author from '../CardArticle/Author';

interface Props {
    webinar: any;
    open: boolean;
    onClose: () => void;
}

const { Text, Title } = Typography;
const { Item } = Descriptions;

const DrawerWebinar = ({
    webinar,
    open,
    onClose,
}: Props) => {
    const { t } = useTranslation('admin');

    const action = () => {
        const webinarLink = `${RECTORAL_BOARD_SITE}/webinars/legacy`;
        console.log(webinarLink);
    };

    const language = localStorage.getItem('i18nextLng');
    const keyLanguage = language?.split('-')[0];

    const posLng = getPositionLanguage(keyLanguage as string);

    const date = moment(webinar.date).locale(keyLanguage as string).format('ddd D MMM YYYY');

    return (
        <Drawer
            open={open}
            onClose={() => {}}
            width={650}
            headerStyle={{ display: 'none' }}
        >
            <Space direction="vertical" size={24} className="full__width">
                <DrawerHeader
                    date={webinar.createdAt}
                    action={action}
                    onClose={onClose}
                />
                <Space direction="vertical" size={12} className="full__width" align="start">
                    <Text className={cs('table__category', webinar.categoryID.class)}>
                        {webinar.categoryID.name}
                    </Text>
                    <Title level={5} className="none__margin--bottom">
                        {`${webinar.tag} ${t('TABLE.WEBINAR')} - ${webinar.topic[posLng]}`}
                    </Title>
                </Space>
                <Text>
                    {webinar.summary[posLng]}
                </Text>

                <Divider style={{ margin: 0 }} />

                <Descriptions title="" layout="vertical">
                    <Item label={t('TABLE.EVENT_DATE')}>
                        {date}
                    </Item>
                    <Item label={t('TABLE.IS_NEXT')}>
                        {webinar.isNext ? t('TABLE.YES') : t('TABLE.NO')}
                    </Item>
                    <Item label={t('TABLE.REGISTER_LINK')}>
                        <ExternalLinkColumn link={webinar.registerLink} />
                    </Item>
                    <Item label={t('TABLE.SURVEY')}>
                        <ExternalLinkColumn link={webinar.survey} />
                    </Item>
                </Descriptions>

                <Divider style={{ margin: 0 }} />

                <Space size={4}>
                    <Title level={5} className="none__margin--bottom">
                        {t('TABLE.VIDEO')}
                    </Title>
                    {
                        !webinar.video?.isAvailable && (
                            <Tag>
                                {t('TABLE.NOT_AVAILABLE')}
                            </Tag>
                        )
                    }
                </Space>

                <ExternalLinkColumn link={webinar?.video?.link || ''} />

                <Divider style={{ margin: 0 }} />

                <Title level={5} className="none__margin--bottom">
                    {t('TABLE.TRANSMISSION_CHANNELS')}
                </Title>

                <Space size={4}>
                    {
                        webinar.transmissionChannels.map((item: any) => (
                            <Tooltip title={item.name}>
                                <Tag>
                                    {item.name}
                                </Tag>
                            </Tooltip>

                        ))
                    }
                </Space>

                <Divider style={{ margin: 0 }} />

                <Title level={5}>
                    {t('TABLE.INTERVIEWERS')}
                </Title>

                <List
                    dataSource={webinar.interviewerID}
                    renderItem={(item: any) => (
                        <List.Item key={item._id}>
                            <Author
                                image={item?.image}
                                name={`${item?.name} ${item?.lastName}`}
                                country={item?.country}
                                membership={item?.membership}
                                academic={item?.academic}
                            />
                        </List.Item>
                    )}
                />

                <Title level={5}>
                    {t('TABLE.INTERVIEWEES')}
                </Title>

                {
                    webinar.interviewersIds?.length && (
                        <List
                            dataSource={webinar.interviewersIds}
                            renderItem={(item: any) => (
                                <List.Item key={item._id}>
                                    <Author
                                        image={item?.image}
                                        name={`${item?.name} ${item?.lastName}`}
                                        country={item?.country}
                                        membership={item?.membership}
                                        academic={item?.academic}
                                    />
                                </List.Item>
                            )}
                        />
                    )
                }

                <Title level={5}>
                    {t('TABLE.MODERATORS')}
                </Title>

                <List
                    dataSource={webinar.moderatorIds}
                    renderItem={(item: any) => (
                        <List.Item key={item._id}>
                            <Author
                                image={item?.image}
                                name={`${item?.name} ${item?.lastName}`}
                                country={item?.country}
                                membership={item?.membership}
                                academic={item?.academic}
                            />
                        </List.Item>
                    )}
                />

                <Divider style={{ margin: 0 }} />

                <Image
                    width="100%"
                    src={webinar.banner.image}
                    previewPrefixCls="article__preview"
                    preview={false}
                />

            </Space>
        </Drawer>
    );
};

export default DrawerWebinar;
