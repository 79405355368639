import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { CContributionStatus } from '../../constants/contributions.constants';

const { Option } = Select;

const ContributionStatus = () => {
    const { t } = useTranslation('forms', { keyPrefix: 'FORMS.INPUTS' });
    const { t: tr } = useTranslation('forms', { keyPrefix: 'FORMS.RULES' });

    const RULES = [{ required: true, message: tr('REQUIRED_FIELD') }];

    return (
        <Form.Item
            label={t('CONTRIBUTION.STATUS')}
            name="status"
            initialValue="ACTIVE"
            rules={RULES}
        >
            <Select
                placeholder={t('CONTRIBUTION.STATUS_PLACEHOLDER')}
            >
                {
                    CContributionStatus.map((status, index) => (
                        <Option value={status} key={index}>
                            {t(`CONTRIBUTION.${status}`)}
                        </Option>
                    ))
                }
            </Select>
        </Form.Item>
    );
};

export default ContributionStatus;
