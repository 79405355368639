import {
    Row, Col, Steps, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../theme';

import styles from '../styles.module.scss';

const { Title, Paragraph } = Typography;

const Clauses = () => {
    const { t } = useTranslation('terms');
    const { t: tc } = useTranslation('terms', { keyPrefix: 'CLAUSES' });

    const CLAUSES = [
        {
            key: 'PURPOSE',
            title: tc('PURPOSE.HEADER'),
            items: ['ITEM_0'],
        },
        {
            key: 'BRAND',
            title: tc('BRAND.HEADER'),
            items: ['ITEM_0', 'ITEM_1', 'ITEM_2'],
        },
        {
            key: 'INFORMATION_USES',
            title: tc('INFORMATION_USES.HEADER'),
            items: ['ITEM_0', 'ITEM_1', 'ITEM_2', 'ITEM_3'],
        },
        {
            key: 'SERVICES',
            title: tc('SERVICES.HEADER'),
            items: ['ITEM_0', 'ITEM_1', 'ITEM_2', 'ITEM_3', 'ITEM_4', 'ITEM_5', 'ITEM_6', 'ITEM_7'],
        },
        {
            key: 'PLAN_OF_ACTIVITIES',
            title: tc('PLAN_OF_ACTIVITIES.HEADER'),
            items: ['ITEM_0', 'ITEM_1', 'ITEM_2', 'ITEM_3'],
        },
        {
            key: 'COORDINATION',
            title: tc('COORDINATION.HEADER'),
            items: ['ITEM_0', 'ITEM_1', 'ITEM_2', 'ITEM_3', 'ITEM_4', 'ITEM_5'],
        },
        {
            key: 'ACCOUNT',
            title: tc('ACCOUNT.HEADER'),
            items: ['ITEM_0', 'ITEM_1', 'ITEM_2'],
        },
        {
            key: 'OPINION',
            title: tc('OPINION.HEADER'),
            items: ['ITEM_0'],
        },
        {
            key: 'SERVICE_MODIFICATION',
            title: tc('SERVICE_MODIFICATION.HEADER'),
            items: ['ITEM_0'],
        },
    ];

    return (
        <Row className={styles.terms}>
            <Col xs={24} className={styles.clauses}>
                <Title level={4}>
                    { t('TERMS_AND_CONDITIONS_RECTORAL_BOARD') }
                </Title>
                <Paragraph
                    style={{
                        color: theme.neutralTertiaryAlt,
                        fontSize: 12,
                    }}
                >
                    { t('UPDATED_AT') }
                </Paragraph>

                <div className={styles.containerClauses}>
                    { CLAUSES.map((clause) => (
                        <div key={clause.title}>
                            <Title level={5}>
                                { clause.title }
                            </Title>
                            <ul>
                                { clause.items.map((item: any, index) => (
                                    <li key={index} className={styles.item}>
                                        <Paragraph style={{ fontSize: 14 }}>
                                            { tc(`${clause.key}.ITEMS.${item}`) }
                                        </Paragraph>
                                    </li>
                                )) }
                            </ul>
                        </div>
                    )) }
                </div>
            </Col>
        </Row>
    );
};

export default Clauses;
