import { Row, Col } from 'antd';
import { NAMES, SERVICES } from '../../constants/services';
import { IService } from '../../interfaces';

import Blog from './Blog';
import Leadership from './Leadership';
import Mentoring from './Mentoring';
import Other from './Other';
import Toolbox from './Toolbox';

interface Props {
    t: (key: string) => string;
}

interface IComponentProps {
    t: (key: string) => string;
    data: IService;
}

interface Item {
    keyName: string;
    data: IService;
    component: React.FC<IComponentProps>;
}

const Manage = ({ t }: Props) => {
    type Items = Item[];

    const ITEMS: Items = [
        {
            keyName: NAMES.MENTORING,
            data: SERVICES.MENTORING,
            component: Mentoring,
        },
        {
            keyName: NAMES.LEADERSHIP,
            data: SERVICES.LEADERSHIP,
            component: Leadership,
        },
        {
            keyName: NAMES.TOOL_BOX,
            data: SERVICES.TOOL_BOX,
            component: Toolbox,
        },
        {
            keyName: NAMES.BLOG,
            data: SERVICES.BLOG,
            component: Blog,
        },
        {
            keyName: NAMES.OTHER,
            data: SERVICES.OTHER,
            component: Other,
        },
    ];

    return (
        <Row gutter={[32, 40]}>
            {
                ITEMS.map((item) => (
                    <Col xs={24} xl={12} key={item.keyName}>
                        <item.component t={t} data={item.data} />
                    </Col>
                ))
            }
        </Row>
    );
};

export default Manage;
