import { createContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { initialContext } from './study-groups.initial';
import { IContext } from './study-groups.interface';
import { IAxiosError, IAxiosResponse } from '../../../../interfaces';
import studyGroupsAPI from '../../../../api/study-groups';

export const StudyGroupsContext = createContext<IContext>(initialContext);

export const StudyGroupsProvider = ({ children }: { children: React.ReactNode }) => {
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [loadAction, setLoadAction] = useState<boolean>(initialContext.loadAction);
    const [errors, setErrors] = useState<string[]>(initialContext.errors);
    const [studyGroups, setStudyGroups] = useState<any[]>(initialContext.studyGroups);
    const [studyGroup, setStudyGroup] = useState<any | null>(initialContext.studyGroup);
    const [total, setTotal] = useState<number>(initialContext.total);
    const [paginator, setPaginator] = useState<any>(initialContext.paginator);
    const [searchParams, setSearchParams] = useSearchParams();

    const search = searchParams.get('search') || '';

    const getParams = () => {
        const params: any = { };
        if (search) params.search = search;
        return params;
    };

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({
            page,
            limit: pageSize,
        });
    };

    const fetchStudyGroups = async () => {
        setLoading(true);
        setErrors([]);

        const params: any = {
            ...getParams(),
            ...paginator,
        };

        studyGroupsAPI.getStudyGroups(params)
            .then(({ data }: Partial<IAxiosResponse>) => {
                setStudyGroups(data.data);
                setTotal(data.meta?.itemCount || 0);
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setLoading(false));
    };

    const handleSearch = (search: string) => {
        setSearchParams({ ...getParams(), search });
        setPaginator(initialContext.paginator);
    };

    return (
        <StudyGroupsContext.Provider
            value={{
                loading,
                loadAction,
                studyGroups,
                studyGroup,
                total,
                paginator,
                searchParams,
                errors,
                onPageChange,
                fetchStudyGroups,
                handleSearch,
                setStudyGroup,
            }}
        >
            {children}
        </StudyGroupsContext.Provider>
    );
};
