import axios from 'axios';
import { DASHBOARD_API } from '../config';

const getReviews = (query: string) => {
    const urlParams = new URLSearchParams(query).toString();
    const url = `${DASHBOARD_API}/book/reviews?${urlParams}`;
    const response = axios.get(url);
    return response;
};

const deleteReview = (id: string) => {
    const url = `${DASHBOARD_API}/book/reviews/${id}`;
    const response = axios.delete(url);
    return response;
};

const createReview = (payload: any) => {
    const url = `${DASHBOARD_API}/book/reviews`;
    const response = axios.post(url, payload);
    return response;
};

const updateReview = (id: string, payload: any) => {
    const url = `${DASHBOARD_API}/book/reviews/${id}`;
    const response = axios.put(url, payload);
    return response;
};

export default {
    getReviews,
    deleteReview,
    createReview,
    updateReview,
};
