import {
    Card, Space, Typography, List, Avatar,
} from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { AiFillCheckCircle } from 'react-icons/ai';
import { BsFillBookmarksFill } from 'react-icons/bs';

interface Props {
    quote: any;
}

const { Title, Text } = Typography;

const QuoteLeadership = ({ quote }: Props) => {
    const { t } = useTranslation('admin');

    const section = (label: string, value: string) => (
        <Space direction="vertical" size={0} className="full__width">
            <Text strong style={{ fontSize: 16 }}>
                {t(label)}
            </Text>
            <Text style={{ fontSize: 16 }}>
                {value}
            </Text>
        </Space>
    );

    return (
        <Space direction="vertical" size={12} className="full__width">
            <Card
                title={(
                    <Title level={4} className="none__margin--bottom">
                        {t('LEADERSHIP.LEADERSHIP_SERVICE')}
                    </Title>
                )}
            >
                <Space direction="vertical" size={12} className="full__width">
                    {
                        section(
                            'LEADERSHIP.AUDIENCE',
                            quote.audience,
                        )
                    }
                    {
                        section(
                            'LEADERSHIP.PROFILE_PUBLIC',
                            quote.profilePublic,
                        )
                    }
                    {
                        section(
                            'TABLE.CONTEXTUALIZATION',
                            quote.contextualize,
                        )
                    }
                </Space>
            </Card>
            <Card
                title={(
                    <Title level={4} className="none__margin--bottom">
                        {t('LEADERSHIP.PROGRAM_DETAIL')}
                    </Title>
                )}
            >
                <List
                    itemLayout="horizontal"
                    dataSource={quote.program}
                    renderItem={(item: any, index: number) => (
                        <List.Item
                            key={index}
                            actions={[
                                <AiFillCheckCircle
                                    color={
                                        item.value ? '#1b4484' : '#c8c6c4'
                                    }
                                    size={20}
                                    style={{ verticalAlign: 'middle' }}
                                />,
                            ]}
                        >
                            <List.Item.Meta
                                avatar={(
                                    <Avatar
                                        style={{
                                            backgroundColor: '#eaeaea',
                                            borderRadius: 4,
                                        }}
                                        shape="square"
                                        size={28}
                                        src={(
                                            <BsFillBookmarksFill color="#1b4484" size={14} />
                                        )}
                                        className="flex-row center-row-center"
                                    />
                                )}
                                title={(
                                    <Space direction="vertical" size={0} className="full__width">
                                        <Title level={5} className="none__margin--bottom">
                                            <Trans
                                                t={t}
                                                i18nKey="LEADERSHIP.MODULE"
                                                values={{ number: item.index + 1 }}
                                            />
                                        </Title>
                                        <Text strong style={{ fontSize: 16 }}>
                                            {t(`LEADERSHIP.${item.key}`)}
                                        </Text>
                                    </Space>
                                )}
                                description={(
                                    <Space direction="vertical" size={4}>
                                        {
                                            item.key === 'PROGRAM_12' && (
                                                <Text>
                                                    {item.description}
                                                </Text>
                                            )
                                        }
                                        <Text style={{ fontSize: 16 }}>
                                            <Trans
                                                t={t}
                                                i18nKey={
                                                    item.hours === 1 ? 'LEADERSHIP.HOUR' : 'LEADERSHIP.HOURS'
                                                }
                                                values={{ hours: item.hours }}
                                            />
                                        </Text>
                                    </Space>
                                )}
                            />
                        </List.Item>
                    )}
                />
            </Card>
        </Space>
    );
};

export default QuoteLeadership;
