/* eslint-disable react/no-unstable-nested-components */
import axios from 'axios';
import {
    ProLayout, PageContainer, ProSettings, ProConfigProvider,
} from '@ant-design/pro-components';
import { Dropdown, MenuProps } from 'antd';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillHome, AiOutlineProfile } from 'react-icons/ai';
import { FiLogOut, FiUsers } from 'react-icons/fi';
import { BsPinMapFill } from 'react-icons/bs';
import { IoLibrarySharp } from 'react-icons/io5';
import { GrUserAdmin } from 'react-icons/gr';
import { RiCalendarEventLine } from 'react-icons/ri';
import { MdDashboard, MdTravelExplore, MdRateReview } from 'react-icons/md';
import {
    FaFileSignature, FaWpforms, FaBook, FaUsers,
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import IMAGES from '../../constants/images';
import { logout } from '../../store/slices/auth';
import { SelectLanguage } from '../SelectLanguage';

const Dashboard = ({ children }: { children: ReactNode }) => {
    const { t } = useTranslation('navbar', { keyPrefix: 'NAVBAR' });
    const { t: ta } = useTranslation('navbar', { keyPrefix: 'APP_LIST' });

    const { user } = useSelector((state: any) => state.auth);

    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    const [settings] = useState<Partial<ProSettings> | undefined>({
        fixSiderbar: true,
        layout: 'mix',
        splitMenus: true,
        menu: {
            type: 'group',
            hideMenuWhenCollapsed: true,
            collapsedShowTitle: true,
            collapsedShowGroupTitle: true,
        },
        title: '',
    });

    const items: MenuProps['items'] = [
        {
            key: 'PROFILE',
            label: t('PROFILE'),
            icon: <AiOutlineProfile size={16} />,
        },
        {
            key: 'LOGOUT',
            label: t('LOGOUT'),
            icon: <FiLogOut size={16} />,
        },
    ];

    const onClick: MenuProps['onClick'] = ({ key }) => {
        const url = '/profile';
        if (key === 'LOGOUT') {
            dispatch(logout());
            localStorage.clear();
        } else {
            navigate(url);
        }
    };

    const [pathname, setPathname] = useState<any>('/');

    const appList = [
        {
            icon: IMAGES.apps.institute,
            title: ta('RECTORAL_BOARD_INSTITUTE'),
            desc: ta('RECTORAL_BOARD_INSTITUTE_DESCRIPTION'),
            url: 'https://rectoralboard.com',
            target: '_blank',
        },
        {
            icon: IMAGES.apps.academy,
            title: ta('RECTORAL_ACADEMY'),
            desc: ta('RECTORAL_ACADEMY_DESCRIPTION'),
            url: 'https://luces.rectoralboard.com',
            target: '_blank',
        },
    ];

    const routes = [
        {
            path: '/',
            name: t('HOME'),
            icon: <AiFillHome />,
        },
        {
            path: '/services',
            name: t('SERVICES'),
            icon: <MdDashboard />,
        },
        {
            path: '/articles',
            name: t('MY_ARTICLES'),
            icon: <FaFileSignature />,
        },
        {
            path: '/contributions',
            name: t('MY_CONTRIBUTIONS'),
            icon: <BsPinMapFill />,
        },
        {
            path: '/my-library',
            name: t('MY_LIBRARY'),
            icon: <IoLibrarySharp />,
        },
        {
            path: '/community',
            name: t('COMMUNITY'),
            icon: <FaUsers />,
        },
        {
            path: '/admin',
            name: t('ADMIN'),
            icon: <GrUserAdmin />,
            children: [
                {
                    path: '/admin/users',
                    name: t('ADMIN_USERS'),
                    icon: <FiUsers />,
                },
                {
                    path: '/admin/webinars',
                    name: t('ADMIN_WEBINARS'),
                    icon: <RiCalendarEventLine />,
                },
                {
                    path: '/admin/quotes?type=mentoring',
                    name: t('ADMIN_QUOTES'),
                    icon: <FaWpforms />,
                },
                {
                    path: '/admin/book',
                    name: t('ADMIN_BOOK'),
                    icon: <FaBook />,
                    children: [
                        {
                            path: '/admin/book/study-groups',
                            name: t('ADMIN_BOOK_STUDY_GROUPS'),
                            icon: <FaUsers />,
                        },
                        {
                            path: '/admin/book/reviews',
                            name: t('ADMIN_BOOK_REVIEWS'),
                            icon: <MdRateReview />,
                        },
                        {
                            path: '/admin/book/experiences',
                            name: t('ADMIN_BOOK_EXPERIENCES'),
                            icon: <MdTravelExplore />,
                        },
                    ],
                },
            ],
        },
    ];

    const defaultProps = {
        route: { routes },
    };

    const backgroundImgList = [
        {
            src: IMAGES.illustrations.trama,
            left: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
        },
    ];

    const startLogout = () => {
        localStorage.clear();
        delete axios.defaults.headers.common.authorization;
        dispatch(logout());
    };

    return (
        <div className="layouts__dashboard">
            <ProConfigProvider
                hashed={false}
            >
                <ProLayout
                    {...settings}
                    {...defaultProps}
                    logo={IMAGES.apps.institute}
                    appList={appList}
                    location={{ pathname }}
                    bgLayoutImgList={backgroundImgList}
                    breadcrumbRender={false}
                    menu={{
                        collapsedShowGroupTitle: true,
                    }}
                    avatarProps={{
                        src: user.image.thumbnail || IMAGES.dashboard.default_avatar,
                        size: 'small',
                        title: (
                            <Dropdown
                                arrow
                                menu={{ items, onClick }}
                                trigger={['hover']}
                                placement="bottomRight"
                            >
                                <span>{user?.name}</span>
                            </Dropdown>
                        ),
                    }}
                    actionsRender={(props) => [
                        <SelectLanguage />,
                    ]}
                    siderWidth={265}
                    menuItemRender={(item, dom) => {
                        const isAdmin = user?.role.includes('SUPER_ADMIN');
                        if (item.path?.includes('/admin') && !isAdmin) return null;
                        return (
                            <Link
                                to={item.path || '/'}
                                onClick={() => setPathname(item.path)}
                            >
                                {dom}
                            </Link>
                        );
                    }}
                >
                    <PageContainer title={false}>
                        {children}
                    </PageContainer>
                </ProLayout>
            </ProConfigProvider>
        </div>
    );
};

export default Dashboard;
