import {
    Typography, Form, Row, Col, Input, Tooltip, Button, notification, PageHeader,
} from 'antd';
import { ReactNode, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    AiFillFacebook,
    AiFillInstagram,
    AiFillLinkedin,
    AiFillTwitterSquare,
} from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { updateContactData } from '../../store/slices/auth';
import { theme } from '../../theme';
import PhoneNumber from '../../widgets/PhoneNumber';

interface Props {
    t: (key: string) => string;
}

interface SocialInputs {
    name: string;
    label: string;
    placeholder: string;
    icon: ReactNode;
}

const { Title } = Typography;

const Contact = ({ t }: Props) => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const { user, loading, error } = useSelector((state: any) => state.auth);
    const {
        email, contact: {
            _id, mobile, countryCode, socialMedia: {
                FB, TW, LN, IG,
            },
        },
    } = user;

    const [form] = Form.useForm();
    const dispatch = useDispatch<any>();

    const onSubmit = (values: any) => {
        const payload = {
            contactID: _id,
            countryCode: values.countryCode,
            email,
            mobile: values.mobile,
            fullMobile: `${values.countryCode}${values.mobile}`,
            socialMedia: {
                FB: values.FB,
                TW: values.TW,
                LN: values.LN,
                IG: values.IG,
            },
        };
        dispatch(updateContactData(payload));
        if (!loading) {
            if (error) {
                notification.error({
                    message: 'Error',
                    description: error,
                });
            } else {
                notification.success({
                    message: tf('RESPONSES.UPDATE_SUCCESS'),
                });
            }
        }
    };

    useEffect(() => {
        form.setFieldsValue({
            mobile,
            countryCode,
            FB,
            TW,
            LN,
            IG,
        });
    }, []);

    const RULES_URL: any = [
        { type: 'url', warningOnly: true, message: tf('RULES.INVALID_URL') },
        { type: 'string', min: 6, message: tf('RULES.MIN_LENGTH', { length: 6 }) },
    ];

    const socialInputs: SocialInputs[] = [
        {
            name: 'LN',
            label: tf('INPUTS.SOCIAL.LINKEDIN'),
            placeholder: tf('INPUTS.SOCIAL.LINKEDIN_PLACEHOLDER'),
            icon: <AiFillLinkedin color={theme.themePrimary} />,
        },
        {
            name: 'FB',
            label: tf('INPUTS.SOCIAL.FACEBOOK'),
            placeholder: tf('INPUTS.SOCIAL.FACEBOOK_PLACEHOLDER'),
            icon: <AiFillFacebook color={theme.themePrimary} />,
        },
        {
            name: 'TW',
            label: tf('INPUTS.SOCIAL.TWITTER'),
            placeholder: tf('INPUTS.SOCIAL.TWITTER_PLACEHOLDER'),
            icon: <AiFillTwitterSquare color={theme.themePrimary} />,
        },
        {
            name: 'IG',
            label: tf('INPUTS.SOCIAL.INSTAGRAM'),
            placeholder: tf('INPUTS.SOCIAL.INSTAGRAM_PLACEHOLDER'),
            icon: <AiFillInstagram color={theme.themePrimary} />,
        },
    ];

    return (
        <div className="profile__manage-tabs-forms">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                className="full__width"
            >
                <PageHeader
                    title={(
                        <Title level={4} style={{ marginBottom: 0 }}>
                            {t('PERSONAL.CONTACT_DATA')}
                        </Title>
                    )}
                    extra={(
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {tf('ACTIONS.UPDATE')}
                        </Button>
                    )}
                />

                <Row className="full__size" gutter={[32, 24]}>
                    <Col xs={24} xxl={12} className="col-left">
                        <PhoneNumber />
                        {

                            socialInputs.map((input, index) => (
                                <Form.Item
                                    key={index}
                                    label={input.label}
                                    name={input.name}
                                    rules={RULES_URL}
                                >
                                    <Input
                                        placeholder={input.placeholder}
                                        addonAfter={input.icon}
                                    />
                                </Form.Item>
                            ))
                        }
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default Contact;
