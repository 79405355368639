import {
    Typography,
    Row,
    Col,
    Button,
    Tooltip,
    Modal,
} from 'antd';
import { FaHandsClapping } from 'react-icons/fa6';
import {
    TiMessages,
    TiMessage,
    TiArrowForwardOutline,
    TiArrowBack,
} from 'react-icons/ti';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
    comments: number;
    likes: number;
    shares?: number;
    commentSingularText?: string;
    commentPluralText?: string;
    share?: () => void;
    like: () => void;
    showMore?: () => void;
    setShowComments?: () => void;
    setShowReply?: () => void;
}

const { Text } = Typography;

const Actions = ({
    comments,
    likes,
    shares,
    commentSingularText,
    commentPluralText,
    share,
    like,
    showMore,
    setShowComments,
    setShowReply,
}: Props) => {
    const { t } = useTranslation('community');
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const handleShareArticle = () => {
        if (!share) return;
        Modal.confirm({
            title: t('SHARE_POST_TITLE'),
            centered: true,
            content: (
                <Trans
                    t={t}
                    i18nKey="SHARE_POST_CONTENT"
                    components={{
                        br: <br />,
                    }}
                />
            ),
            icon: null,
            onOk: share,
            onCancel: () => {},
            okText: t('SHARE'),
            cancelText: tf('ACTIONS.CANCEL'),
        });
    };

    return (
        <div className="card__actions">
            <Row justify="space-between" align="middle" className="full__width">
                <Col flex={1}>
                    <Row justify="start" align="middle" gutter={[20, 12]}>
                        <Col>
                            <Tooltip title={t('CLAPS')}>
                                <div
                                    className="flex-row start-row-center"
                                    style={{ gap: 4, cursor: 'pointer' }}
                                    onClick={like}
                                >
                                    <FaHandsClapping
                                        size={15}
                                        style={{ verticalAlign: 'middle' }}
                                        color="#d13438"
                                    />
                                    <Text strong style={{ fontSize: 12, color: '#d13438' }}>
                                        {likes || 0}
                                    </Text>
                                </div>
                            </Tooltip>
                        </Col>
                        <Col>
                            <Tooltip title={t(commentPluralText || 'OPINIONS')}>
                                <div
                                    className="flex-row start-row-center"
                                    style={{ gap: 4, cursor: setShowComments ? 'pointer' : 'auto' }}
                                    onClick={setShowComments}
                                >
                                    {
                                        commentSingularText ? (
                                            <TiMessages
                                                size={16}
                                                style={{ verticalAlign: 'middle' }}
                                                color="#1b4484"
                                            />
                                        ) : (
                                            <TiMessage
                                                size={16}
                                                style={{ verticalAlign: 'middle' }}
                                                color="#1b4484"
                                            />
                                        )
                                    }
                                    <Text strong style={{ fontSize: 12, color: '#1b4484' }}>
                                        {
                                            comments === 1
                                                ? `1 ${t(commentSingularText || 'OPINION')}`
                                                : `${comments} ${t(commentPluralText || 'OPINIONS')}`
                                        }
                                    </Text>
                                </div>
                            </Tooltip>
                        </Col>
                        {
                            share && (
                                <Col>
                                    <Tooltip title={t('SHARES')}>
                                        <div
                                            className="flex-row start-row-center"
                                            style={{ gap: 4, cursor: 'pointer' }}
                                            onClick={handleShareArticle}
                                        >
                                            <TiArrowForwardOutline
                                                size={16}
                                                style={{ verticalAlign: 'middle' }}
                                                color="#1b4484"
                                            />
                                            <Text strong style={{ fontSize: 12, color: '#1b4484' }}>
                                                {shares === 1 ? `1 ${t('SHARED')}` : `${shares} ${t('SHARES')}`}
                                            </Text>
                                        </div>
                                    </Tooltip>
                                </Col>
                            )
                        }
                        {
                            setShowReply && (
                                <Col>
                                    <Tooltip title={t('REPLY')}>
                                        <div
                                            className="flex-row start-row-center"
                                            style={{ gap: 4, cursor: 'pointer' }}
                                            onClick={setShowReply}
                                        >
                                            <TiArrowBack
                                                size={16}
                                                style={{ verticalAlign: 'middle' }}
                                                color="#1b4484"
                                            />
                                            <Text strong style={{ fontSize: 12, color: '#1b4484' }}>
                                                {t('REPLY')}
                                            </Text>
                                        </div>
                                    </Tooltip>
                                </Col>
                            )
                        }
                    </Row>
                </Col>
                <Col>
                    {
                        showMore && (
                            <Button
                                type="text"
                                size="small"
                                onClick={showMore}
                            >
                                {t('SEE_MORE')}
                            </Button>
                        )
                    }
                </Col>
            </Row>
        </div>
    );
};

export default Actions;
