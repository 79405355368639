import cs from 'classnames';
import {
    Avatar, Modal, Table, Tooltip, Typography, notification,
} from 'antd';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BiEdit, BiLinkExternal } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import { ContributionsContext } from '../context/contributionsContext';
import Loading from '../../../widgets/Loading';
import EmptyData from '../../../widgets/EmptyData';
import { getCountry } from '../../../utils/countries';
import { IContribution } from '../../../interfaces';

interface Props {
    t: (key: string) => string;
}

const { Text } = Typography;

const TableContributions = ({ t }: Props) => {
    const {
        loading,
        actionLoading,
        contributions,
        errors,
        total,
        paginator,
        onPageChange,
        fetchContributions,
        deleteContribution,
    } = useContext(ContributionsContext);

    const { limit, page } = paginator;

    const navigate = useNavigate();

    const confirmDelete = (title: string, contributionID: string) => {
        Modal.confirm({
            className: 'modal__confirm-delete',
            icon: <MdDelete size={28} className="modal__confirm-delete icon" />,
            title: t('DELETE_CONFIRMATION'),
            content: title,
            okText: t('DELETE'),
            cancelText: t('CANCEL'),
            onOk: () => {
                deleteContribution(contributionID);
                if (errors.length === 0) {
                    notification.success({ message: t('DELETE_SUCCESS') });
                } else {
                    notification.error({
                        message: t('DELETE_ERROR'),
                        description: errors[0] || t('SOMENTHING_WENT_WRONG'),
                    });
                }
            },
            okButtonProps: {
                loading: actionLoading,
            },
        });
    };

    const handleAction = (action: string, contribution: IContribution) => {
        switch (action) {
        case 'EDIT':
            navigate(`/contributions/${contribution._id}`);
            break;
        case 'DELETE':
            confirmDelete(contribution.project.name, contribution._id);
            break;
        default:
            break;
        }
    };

    const COLUMNS = [
        {
            title: t('TABLE.PROJECT'),
            children: [
                {
                    title: t('TABLE.PROJECT_NAME'),
                    dataIndex: 'projectName',
                    key: 'projectName',
                    width: 400,
                    render: (_: any, contribution: any) => (
                        <Text className="table__summary">
                            {contribution.project.name}
                        </Text>
                    ),
                },
                {
                    title: t('TABLE.PROJECT_EXTERNAL_LINK'),
                    dataIndex: 'externalLink',
                    key: 'externalLink',
                    width: 200,
                    render: (_: any, contribution: any) => {
                        const { externalLink } = contribution.project;
                        return (
                            !externalLink ? (
                                <Text className="table__not-available">
                                    {t('NOT_AVAILABLE')}
                                </Text>
                            ) : (
                                <Tooltip title={externalLink} showArrow placement="topLeft">
                                    <div className="table__external-link" onClick={() => window.open(externalLink, '_blank')}>
                                        {t('TABLE.GO_TO_LINK')}
                                        <BiLinkExternal size={16} className="table__external-link-icon" />
                                    </div>
                                </Tooltip>
                            )
                        );
                    },
                },
                {
                    title: t('TABLE.PROJECT_DATE'),
                    dataIndex: 'executeDate',
                    key: 'executeDate',
                    align: 'center' as 'center',
                    render: (_: any, contribution: any) => {
                        const { startYear, endYear } = contribution.project;
                        return (
                            <Text className="table__summary">
                                {`${startYear} - ${endYear}`}
                            </Text>
                        );
                    },
                },
                {
                    title: t('TABLE.PROJECT_LEADERSHIP'),
                    dataIndex: 'leadership',
                    key: 'leadership',
                    render: (_: any, contribution: any) => (
                        <Text className="table__summary">
                            {contribution.project.leadership}
                        </Text>
                    ),
                },
            ],
        },
        {
            title: t('TABLE.CATEGORY'),
            dataIndex: 'category',
            key: 'category',
            render: (category: string) => (
                <Text className="table__not-available">
                    {t(`TABLE.${category}`)}
                </Text>
            ),
        },
        {
            title: t('TABLE.STATUS'),
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => (
                <Text className={cs('table__not-available', status)}>
                    {
                        status ? (
                            t(`TABLE.${status}`)
                        ) : (
                            t('NOT_AVAILABLE')
                        )
                    }
                </Text>
            ),
        },
        {
            title: t('TABLE.LOCATION'),
            children: [
                {
                    title: t('TABLE.LOCATION_COUNTRY'),
                    dataIndex: 'country',
                    key: 'country',
                    align: 'center' as 'center',
                    render: (_: any, contribution: any) => {
                        const code = contribution.location.country;
                        const country = getCountry(code);
                        return (
                            <Tooltip title={country?.nativeName}>
                                <Avatar
                                    src={country?.flag}
                                    shape="square"
                                    style={{
                                        marginRight: 8,
                                        width: 32,
                                        height: 20,
                                    }}
                                />
                            </Tooltip>
                        );
                    },
                },
                {
                    title: t('TABLE.LOCATION_CITY'),
                    dataIndex: 'city',
                    key: 'city',
                    align: 'center' as 'center',
                    render: (_: any, contribution: any) => (
                        <Text className="table__summary">
                            {contribution.location.city}
                        </Text>
                    ),
                },
            ],
        },
        {
            title: t('TABLE.ACTIONS'),
            dataIndex: 'actions',
            key: 'actions',
            fixed: 'right' as 'right',
            width: 160,
            render: (_: any, contribution: any) => (
                <div className="table__actions">
                    <BiEdit size={28} className="table__actions-icon" onClick={() => handleAction('EDIT', contribution)} />
                    <MdDelete size={28} className="table__actions-icon delete" onClick={() => handleAction('DELETE', contribution)} />
                </div>
            ),
        },
    ];

    useEffect(() => {
        fetchContributions();
    }, [paginator]);

    if (loading) return <Loading />;

    return (
        total === 0 ? (
            <EmptyData
                t={t}
                path="/contributions/new-contribution"
                nameButton="ADD_CONTRIBUTION"
            />
        ) : (
            <Table
                className="table"
                bordered
                rowKey="_id"
                loading={loading}
                columns={COLUMNS}
                dataSource={contributions}
                scroll={{ x: 1500 }}
                pagination={{
                    total,
                    pageSize: limit,
                    current: page,
                    onChange: onPageChange,
                    showSizeChanger: false,
                    size: 'small',
                }}
            />
        )
    );
};

export default TableContributions;
