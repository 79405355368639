import cs from 'classnames';
import { Row, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

interface Props {
    t: (key: string) => string;
}

const { Title, Paragraph } = Typography;

const Summary = ({ t }: Props) => {
    const { user } = useSelector((state: any) => state.auth);

    const { slogan, articles, progressProfile = 100 } = user;

    const totalArticles = articles?.length || 0;

    const summary = [
        {
            title: progressProfile === 100 ? 'PROFILE_COMPLETED' : 'PROFILE_INCOMPLETE',
            description: progressProfile === 100 ? 'PROFILE_COMPLETED_MESSAGE' : 'PROFILE_INCOMPLETE_MESSAGE',
            requireCompleted: progressProfile === 100,
        },
        {
            title: 'WELCOME_PHRASE',
            description: slogan.isRequired ? 'WELCOME_PHRASE_ACTIVATED' : 'WELCOME_PHRASE_DEACTIVATED',
            requireCompleted: slogan.isRequired,
        },
        {
            title: 'ARTICLES_WRITTEN',
            description: totalArticles === 1 ? 'ARTICLES_WRITTEN_MESSAGE_1' : 'ARTICLES_WRITTEN_MESSAGE',
            requireCompleted: false,
            hasValues: true,
            values: { total: totalArticles },
        },
    ];

    return (
        <div className="home__summary">
            <Title level={3}>
                {t('MY_PROGRESS')}
            </Title>
            <Paragraph>
                {t('MY_PROGRESS_MESSAGE')}
            </Paragraph>
            <Row>
                {
                    summary.map((item, index) => (
                        <div
                            className={
                                cs(
                                    'home__summary-item',
                                    { completed: item.requireCompleted && true },
                                )
                            }
                            key={index}
                        >
                            <Title level={4}>
                                {t(item.title)}
                            </Title>
                            <p>
                                {
                                    item?.hasValues
                                        ? (
                                            <Trans
                                                t={t}
                                                i18nKey={item.description}
                                                components={{ b: <b /> }}
                                                values={item.values}
                                            />
                                        )
                                        : t(item.description)
                                }
                            </p>
                        </div>
                    ))
                }
            </Row>
        </div>
    );
};

export default Summary;
