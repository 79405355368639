import cs from 'classnames';
import {
    Space, Tooltip, Typography, Switch, notification,
} from 'antd';
import { BsPatchCheckFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import servicesAPI from '../../api/services';
import { updateServices } from '../../store/slices/auth';

interface Props {
    t: (key: string) => string;
    keyName: string;
    name: string;
    hasService: boolean;
    setHasService: (value: boolean) => void;
}

const { Title } = Typography;

const Header = ({
    t, keyName, name, hasService, setHasService,
}: Props) => {
    const { user: { services } } = useSelector((state: any) => state.auth);
    const dispatch = useDispatch<any>();

    const onChange = (checked: boolean) => {
        setHasService(checked);
        const payload = {
            service: keyName,
            activate: checked,
        };
        const dataToUpdate = [
            ...services.map((service: any) => {
                if (service.keyName === keyName) {
                    return {
                        ...service,
                        active: checked,
                    };
                }
                return service;
            }),
        ];
        servicesAPI.activateUserService(payload)
            .then(() => {
                const description = checked ? t('NOTIFICATIONS.SERVICE_ACTIVATE') : t('NOTIFICATIONS.SERVICE_DEACTIVATE');
                notification.success({
                    message: t('NOTIFICATIONS.SUCCESS'),
                    description,
                });
                dispatch(updateServices(dataToUpdate));
            })
            .catch((error: any) => {
                const description = error.response?.data?.message;
                notification.error({
                    message: t('NOTIFICATIONS.ERROR'),
                    description,
                });
            });
    };

    return (
        <div className="services__container-header">
            <Space direction="horizontal" size={8} style={{ width: 'fit-content' }}>
                <Title level={4}>
                    {t(name)}
                </Title>
                <Tooltip
                    title={t(
                        hasService ? 'SERVICE_ACTIVE' : 'SERVICE_INACTIVE',
                    )}
                    placement="topLeft"
                    showArrow
                >
                    <BsPatchCheckFill
                        size={18}
                        className={cs(
                            'services__container-icon',
                            { active: hasService },
                        )}
                    />
                </Tooltip>
            </Space>
            <Tooltip
                title={t('SERVICE_ACTIVE_HELP')}
                placement="topRight"
                showArrow
            >
                <Switch checked={hasService} onChange={onChange} />
            </Tooltip>
        </div>
    );
};

export default Header;
