import {
    Avatar, Space, Spin, Typography,
} from 'antd';
import {
    useCallback, useEffect, useRef, useState,
} from 'react';
import { BsFillPinFill } from 'react-icons/bs';
import { BiErrorAlt } from 'react-icons/bi';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_MAP_API_KEY } from '../../../config';
import { MapStyles } from './MapStyles';

interface Props {
    t: (key: string) => string;
    coordinates: any;
    setCoordinates: (coordinates: any) => void;
}

const { Title, Text, Paragraph } = Typography;

const Location = ({ t, coordinates, setCoordinates }: Props) => {
    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAP_API_KEY,
    });

    const containerStyles = {
        width: '100%',
        height: '500px',
    };

    const options = {
        styles: MapStyles,
        mapTypeControl: false,
        streetViewControl: false,
    };

    const mapRef = useRef<any>();
    const [center, setCenter] = useState({
        lat: 0, lng: 0,
    });

    const onLoad = useCallback((map: any) => {
        mapRef.current = map;
    }, []);

    const onUnmount = useCallback(() => {
        if (!mapRef) return;
        mapRef.current = null;
    }, []);

    const onDragEnd = useCallback(() => {
        if (!mapRef) return;
        const lat = mapRef.current.center.lat();
        const lng = mapRef.current.center.lng();
        setCenter({ lat, lng });
        setCoordinates({ lat, lng });
    }, []);

    if (loadError) {
        return (
            <div className="location__error">
                <BiErrorAlt color="#d13438" size={32} />
                <Title level={4}>
                    {t('ERROR_LOADING_MAP')}
                </Title>
            </div>
        );
    }

    useEffect(() => {
        if (coordinates) {
            setCenter({ lat: coordinates.lat, lng: coordinates.lng });
        }
    }, [coordinates]);

    return (
        <Space direction="vertical" size={12} className="full__width">
            <Text className="location__label">
                {t('LOCATION')}
            </Text>
            <Paragraph className="location__help">
                {t('LOCATION_HELP')}
            </Paragraph>
            <div className="location">
                {
                    isLoaded ? (
                        <div className="location__map">
                            <GoogleMap
                                mapContainerStyle={containerStyles}
                                center={center}
                                zoom={11}
                                options={options}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                                onDragEnd={onDragEnd}
                            />
                            <Avatar
                                className="location__pin"
                                size={40}
                                src={<BsFillPinFill size={32} color="#d83b01" />}
                            />
                        </div>
                    ) : (
                        <div className="location__loading">
                            <Spin />
                            <Title level={4}>
                                {t('LOADING_MAP')}
                            </Title>
                        </div>
                    )
                }
            </div>
        </Space>
    );
};

export default Location;
