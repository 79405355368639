import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
    Button, Col, Form, Input, Row, Typography, message, Checkbox, notification, PageHeader,
} from 'antd';
import { IUser } from '../../../../interfaces';
import usersAPI from '../../../../api/users';

interface Props {
    t: (key: string) => string;
    userData: IUser | null;
}

const { Title } = Typography;
const { TextArea } = Input;

const WelcomePhrase = ({ t, userData }: Props) => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const [form] = Form.useForm();
    const [showPhrase, setShowPhrase] = useState<boolean>(false);

    const [loading, setLoading] = useState(false);

    const RULES: any = [
        { type: 'string', max: 250, message: tf('RULES.MAX_LENGTH', { length: 250 }) },
        { type: 'string', min: 5, message: tf('RULES.MIN_LENGTH', { length: 5 }) },
    ];

    const onChange = (e: CheckboxChangeEvent) => {
        const value = e.target.checked;
        setShowPhrase(value);
    };

    const onSubmit = async (values: any) => {
        setLoading(true);
        if (showPhrase && (values.content === '' || !values.content)) {
            message.warning(tf('RULES.NO_SHOW_EMPTY_WELCOME_PHRASE'));
            setShowPhrase(false);
            setLoading(false);
            return;
        }

        const payload = {
            content: values.content,
            isRequired: showPhrase,
        };

        const userID = userData?._id || '';

        usersAPI.updateWelcomePhrase(userID, payload)
            .then(() => {
                notification.success({
                    message: tf('RESPONSES.UPDATE_SUCCESS'),
                });
            })
            .catch((error: any) => {
                const description = error?.response?.data?.message || error.message;
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        form.setFieldsValue({
            content: userData?.slogan.content,
        });
        setShowPhrase(userData?.slogan.isRequired || false);
    }, []);

    return (
        <div className="profile__manage-tabs-forms">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                className="full__width"
            >
                <PageHeader
                    title={(
                        <Title level={4} style={{ marginBottom: 0 }}>
                            {t('PERSONAL.WELCOME_PHRASE')}
                        </Title>
                    )}
                    extra={(
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {tf('ACTIONS.UPDATE')}
                        </Button>
                    )}
                />
                <Row className="full__size" gutter={[24, 28]}>
                    <Col xs={24} className="col-left">
                        <Form.Item
                            name="content"
                            rules={RULES}
                        >
                            <TextArea
                                placeholder={tf('INPUTS.WELCOME_PHRASE_PLACEHOLDER')}
                                maxLength={250}
                                minLength={5}
                                showCount
                            />
                        </Form.Item>

                        <Checkbox
                            checked={showPhrase}
                            onChange={onChange}
                        >
                            {tf('INPUTS.SHOW_WELCOME_PHRASE')}
                        </Checkbox>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default WelcomePhrase;
