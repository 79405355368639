import {
    Col, Row, Typography, Form, Input, Button, notification, PageHeader,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Country from '../../../../widgets/Country';
import GenderType from '../../../../widgets/GenderType';
import { IUser } from '../../../../interfaces';
import UploadImage from '../../../UploadImage';
import usersAPI from '../../../../api/users';

interface Props {
    t: (key: string) => string;
    userData: IUser | null;
}

const { Title } = Typography;

const Personal = ({ t, userData }: Props) => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const RULES: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
    ];

    const onSubmit = (values: any) => {
        setLoading(true);

        const payload = {
            ...values,
            image: {
                original: values.image,
                thumbnail: values.image,
            },
        };

        const userID = userData?._id || '';

        usersAPI.updatePersonalData(userID, payload)
            .then(() => {
                notification.success({
                    message: tf('RESPONSES.UPDATE_SUCCESS'),
                });
            })
            .catch((error: any) => {
                const description = error?.response?.data?.message || error.message;
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        form.setFieldsValue({
            name: userData?.name,
            lastName: userData?.lastName,
            country: userData?.country,
            gender: userData?.gender || 'HIDDEN',
            image: userData?.image?.original,
        });
    }, [userData]);

    return (
        <div className="profile__manage-tabs-forms">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                className="full__width"
            >
                <PageHeader
                    title={(
                        <Title level={4} style={{ marginBottom: 0 }}>
                            {t('PERSONAL.PERSONAL_DATA')}
                        </Title>
                    )}
                    extra={(
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {tf('ACTIONS.UPDATE')}
                        </Button>
                    )}
                />
                <Row className="full__size" gutter={[24, 28]}>
                    <Col xs={24} xxl={8} className="col-left">
                        <Form.Item name="image">
                            <UploadImage t={tf} type="profile" name={`${userData?.name}-${userData?.lastName}`} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xxl={16} className="col-right">
                        <Form.Item
                            label={tf('INPUTS.NAME')}
                            name="name"
                            rules={RULES}
                        >
                            <Input placeholder={tf('INPUTS.NAME_PLACEHOLDER')} />
                        </Form.Item>

                        <Form.Item
                            label={tf('INPUTS.LAST_NAME')}
                            name="lastName"
                            rules={RULES}
                        >
                            <Input placeholder={tf('INPUTS.LAST_NAME_PLACEHOLDER')} />
                        </Form.Item>
                        <Country />
                        <GenderType />
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default Personal;
