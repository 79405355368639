import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import { ReviewsRouter } from './admin/reviews.router';
import { UsersRouter } from './admin/users.router';
import { QuotesRouter } from './admin/quotes.router';
import { StudyGroupsRouter } from './admin/study-groups.router';
import { WebinarsRouter } from './admin/webinars.router';

export const AdminRouter: FC = () => (
    <Routes>
        <Route path="users/*" element={<UsersRouter />} />
        <Route path="quotes/*" element={<QuotesRouter />} />
        <Route path="book">
            <Route path="reviews/*" element={<ReviewsRouter />} />
            <Route path="study-groups/*" element={<StudyGroupsRouter />} />
        </Route>
        <Route path="webinars/*" element={<WebinarsRouter />} />
    </Routes>
);
