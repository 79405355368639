import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button, Col, Form, Input, PageHeader, Row, Typography, notification,
} from 'antd';
import {
    AiFillFacebook, AiFillInstagram, AiFillLinkedin, AiFillTwitterSquare,
} from 'react-icons/ai';
import { IUser } from '../../../../interfaces';
import { theme } from '../../../../theme';
import PhoneNumber from '../../../../widgets/PhoneNumber';
import contactsAPI from '../../../../api/contact';

interface Props {
    t: (key: string) => string;
    userData: IUser | null;
}

interface SocialInputs {
    name: string;
    label: string;
    placeholder: string;
    icon: ReactNode;
}

const { Title } = Typography;

const Contact = ({ t, userData }: Props) => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onSubmit = (values: any) => {
        setLoading(true);
        const contactID = userData?.contact._id || '';
        const payload = {
            countryCode: values.countryCode,
            email: userData?.email,
            mobile: values.mobile,
            fullMobile: `${values.countryCode}${values.mobile}`,
            socialMedia: {
                FB: values.FB,
                TW: values.TW,
                LN: values.LN,
                IG: values.IG,
            },
        };

        contactsAPI.updateContactDataUser(payload, contactID)
            .then(() => {
                notification.success({
                    message: tf('RESPONSES.UPDATE_SUCCESS'),
                });
            })
            .catch((error: any) => {
                const description = error?.response?.data?.message || error.message;
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!userData) return;
        form.setFieldsValue({
            mobile: userData?.contact.mobile,
            countryCode: userData?.contact.countryCode,
            FB: userData?.contact.socialMedia.FB,
            TW: userData?.contact.socialMedia.TW,
            LN: userData?.contact.socialMedia.LN,
            IG: userData?.contact.socialMedia.IG,
        });
    }, []);

    const RULES_URL: any = [
        { type: 'url', warningOnly: true, message: tf('RULES.INVALID_URL') },
        { type: 'string', min: 6, message: tf('RULES.MIN_LENGTH', { length: 6 }) },
    ];

    const socialInputs: SocialInputs[] = [
        {
            name: 'LN',
            label: tf('INPUTS.SOCIAL.LINKEDIN'),
            placeholder: tf('INPUTS.SOCIAL.LINKEDIN_PLACEHOLDER'),
            icon: <AiFillLinkedin color={theme.themePrimary} />,
        },
        {
            name: 'FB',
            label: tf('INPUTS.SOCIAL.FACEBOOK'),
            placeholder: tf('INPUTS.SOCIAL.FACEBOOK_PLACEHOLDER'),
            icon: <AiFillFacebook color={theme.themePrimary} />,
        },
        {
            name: 'TW',
            label: tf('INPUTS.SOCIAL.TWITTER'),
            placeholder: tf('INPUTS.SOCIAL.TWITTER_PLACEHOLDER'),
            icon: <AiFillTwitterSquare color={theme.themePrimary} />,
        },
        {
            name: 'IG',
            label: tf('INPUTS.SOCIAL.INSTAGRAM'),
            placeholder: tf('INPUTS.SOCIAL.INSTAGRAM_PLACEHOLDER'),
            icon: <AiFillInstagram color={theme.themePrimary} />,
        },
    ];

    return (
        <div className="profile__manage-tabs-forms">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                className="full__width"
            >
                <PageHeader
                    title={(
                        <Title level={4} style={{ marginBottom: 0 }}>
                            {t('PERSONAL.CONTACT_DATA')}
                        </Title>
                    )}
                    extra={(
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {tf('ACTIONS.UPDATE')}
                        </Button>
                    )}
                />

                <Row className="full__size" gutter={[32, 24]}>
                    <Col xs={24} xxl={12} className="col-left">
                        <PhoneNumber />
                        {

                            socialInputs.map((input, index) => (
                                <Form.Item
                                    key={index}
                                    label={input.label}
                                    name={input.name}
                                    rules={RULES_URL}
                                >
                                    <Input
                                        placeholder={input.placeholder}
                                        addonAfter={input.icon}
                                    />
                                </Form.Item>
                            ))
                        }
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default Contact;
