import {
    Card, Typography, Space,
} from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
    quote: any;
}

const { Title, Text } = Typography;

const QuoteMentoring = ({ quote }: Props) => {
    const { t } = useTranslation('admin');

    const section = (label: string, value: string) => (
        <Space direction="vertical" size={0} className="full__width">
            <Text strong style={{ fontSize: 16 }}>
                {t(label)}
            </Text>
            <Text style={{ fontSize: 16 }}>
                {value}
            </Text>
        </Space>
    );

    return (
        <Card
            title={(
                <Title level={4} className="none__margin--bottom">
                    {t('MENTORING.MENTORING_SERVICE')}
                </Title>
            )}
        >
            <Space direction="vertical" size={12} className="full__width">
                {
                    section(
                        'MENTORING.MENTORING_SERVICE',
                        t(`MENTORING.${quote.type.toUpperCase()}`),
                    )
                }
                {
                    section(
                        'TABLE.CONTEXTUALIZATION',
                        quote.contextualize,
                    )
                }
            </Space>
        </Card>
    );
};

export default QuoteMentoring;
