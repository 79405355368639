import axios from 'axios';
import { DASHBOARD_API } from '../config';

const createArticle = (payload: any) => {
    const url = `${DASHBOARD_API}/articles`;
    const response = axios.post(url, payload);
    return response;
};

const getArticles = (query: any, userID: string) => {
    const urlParams = new URLSearchParams(query).toString();
    const url = `${DASHBOARD_API}/articles/${userID}?${urlParams}`;
    const response = axios.get(url);
    return response;
};

const getAllArticles = (query: any) => {
    const urlParams = new URLSearchParams(query).toString();
    const url = `${DASHBOARD_API}/articles?${urlParams}`;
    const response = axios.get(url);
    return response;
};

const getArticle = (articleID: string) => {
    const url = `${DASHBOARD_API}/articles/detail/${articleID}`;
    const response = axios.get(url);
    return response;
};

const updateArticle = (articleID: string, payload: any) => {
    const url = `${DASHBOARD_API}/articles/${articleID}`;
    const response = axios.put(url, payload);
    return response;
};

const deleteArticle = (articleID: string) => {
    const url = `${DASHBOARD_API}/articles/${articleID}`;
    const response = axios.delete(url);
    return response;
};

const getCategories = () => {
    const url = `${DASHBOARD_API}/category`;
    const response = axios.get(url);
    return response;
};

const likeArticle = (articleID: string) => {
    const url = `${DASHBOARD_API}/articles/${articleID}/like`;
    const response = axios.put(url);
    return response;
};

const shareArticle = (articleID: string) => {
    const url = `${DASHBOARD_API}/articles/${articleID}/share`;
    const response = axios.put(url);
    return response;
};

export default {
    createArticle,
    getArticles,
    getAllArticles,
    getArticle,
    updateArticle,
    deleteArticle,
    getCategories,
    likeArticle,
    shareArticle,
};
