import {
    Row, Col, Form, Typography, Input, Button, notification, PageHeader,
} from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updatePersonalData } from '../../store/slices/auth';
import Country from '../../widgets/Country';
import GenderType from '../../widgets/GenderType';
import UploadImage from '../UploadImage';

interface Props {
    t: (key: string) => string;
}

const { Title } = Typography;

const Personal = ({ t }: Props) => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const { user, loading, error } = useSelector((state: any) => state.auth);
    const {
        name, lastName, country, gender,
    } = user;

    const [form] = Form.useForm();

    const dispatch = useDispatch<any>();

    const onSubmit = (values: any) => {
        const payload = {
            userID: user?._id,
            personal: {
                ...values,
                image: {
                    original: values.image,
                    thumbnail: values.image,
                },
            },
        };
        dispatch(updatePersonalData(payload));
        if (!loading) {
            if (error) {
                notification.error({
                    message: 'Error',
                    description: error,
                });
            } else {
                notification.success({
                    message: tf('RESPONSES.UPDATE_SUCCESS'),
                });
            }
        }
    };

    const RULES: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
    ];

    useEffect(() => {
        form.setFieldsValue({
            name,
            lastName,
            country,
            gender: gender || 'HIDDEN',
            image: user?.image?.original,
        });
    }, []);

    return (
        <div className="profile__manage-tabs-forms">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                className="full__width"
            >
                <PageHeader
                    title={(
                        <Title level={4} style={{ marginBottom: 0 }}>
                            {t('PERSONAL.PERSONAL_DATA')}
                        </Title>
                    )}
                    extra={(
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {tf('ACTIONS.UPDATE')}
                        </Button>
                    )}
                />
                <Row className="full__size" gutter={[24, 28]}>
                    <Col xs={24} xxl={8} className="col-left">
                        <Form.Item name="image">
                            <UploadImage t={tf} type="profile" name={`${user.name}-${user.lastName}`} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xxl={16} className="col-right">
                        <Form.Item
                            label={tf('INPUTS.NAME')}
                            name="name"
                            rules={RULES}
                        >
                            <Input placeholder={tf('INPUTS.NAME_PLACEHOLDER')} />
                        </Form.Item>

                        <Form.Item
                            label={tf('INPUTS.LAST_NAME')}
                            name="lastName"
                            rules={RULES}
                        >
                            <Input placeholder={tf('INPUTS.LAST_NAME_PLACEHOLDER')} />
                        </Form.Item>
                        <Country />
                        <GenderType />
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default Personal;
