import axios from 'axios';
import { DASHBOARD_API } from '../config';

const getQuotes = (query: string) => {
    const urlParams = new URLSearchParams(query).toString();
    const url = `${DASHBOARD_API}/quotes?${urlParams}`;
    const response = axios.get(url);
    return response;
};

const changeStatus = (quoteID: string, query: any) => {
    const urlParams = new URLSearchParams(query).toString();
    const url = `${DASHBOARD_API}/quotes/${quoteID}?${urlParams}`;
    const response = axios.put(url);
    return response;
};

export default {
    getQuotes,
    changeStatus,
};
