import { createSlice } from '@reduxjs/toolkit';
import { IUser } from '../../../interfaces';

type authProps = {
    status: 'no-authenticated' | 'authenticated' | 'checking';
    loading: boolean;
    user: IUser | null;
    emailExist: boolean;
    resetPassword: 'pending' | 'success' | 'error';
    error: {
        status: number,
        error: string,
    } | null;
}

const _initialState: authProps = {
    status: 'checking',
    loading: false,
    user: null,
    emailExist: false,
    resetPassword: 'pending',
    error: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState: _initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },

        login: (state, { payload }) => {
            state.user = payload;
            state.status = 'authenticated';
            state.loading = false;
        },

        signup: (state, { payload }) => {
            state.user = payload;
            state.status = 'authenticated';
            state.loading = false;
        },

        logout: (state) => {
            state.user = null;
            state.loading = false;
            state.status = 'no-authenticated';
            state.emailExist = false;
        },

        setEmailExist: (state, { payload }) => {
            state.emailExist = payload;
        },

        setError: (state, { payload }) => {
            state.error = payload;
        },

        updatePersonal: (state, { payload }) => {
            if (state.user) {
                state.user = {
                    ...state.user,
                    ...payload,
                };
                state.loading = false;
            }
        },

        updateWelcomePhrase: (state, { payload }) => {
            if (state.user) {
                state.user.slogan = payload;
                state.loading = false;
            }
        },

        updateAcademic: (state, { payload }) => {
            if (state.user) {
                state.user.academic = payload;
                state.loading = false;
            }
        },

        updateContact: (state, { payload }) => {
            if (state.user) {
                state.user.contact = {
                    ...state.user.contact,
                    ...payload,
                };
                state.loading = false;
            }
        },

        updateMembership: (state, { payload }) => {
            if (state.user) {
                state.user.membership = payload;
                state.loading = false;
            }
        },

        statusResetPassword: (state, { payload }) => {
            state.resetPassword = payload;
        },

        updateSettings: (state, { payload }) => {
            if (state.user) {
                state.user.settings = {
                    ...state.user.settings,
                    ...payload,
                };
            }
        },

        updateServices: (state, { payload }) => {
            if (state.user) {
                state.user.services = payload;
            }
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setLoading,
    setEmailExist,
    login,
    signup,
    logout,
    setError,
    updateAcademic,
    updateMembership,
    statusResetPassword,
    updatePersonal,
    updateWelcomePhrase,
    updateContact,
    updateSettings,
    updateServices,
} = authSlice.actions;
