import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';

import Login from '../screens/Login';
import Onboarding from '../screens/Onboarding';
import SignUp from '../screens/Signup';
import ResetPassword from '../screens/ResetPassword';

import Layout from '../components/Layout';
import Dashboard from '../components/Dashboard';

import PrivateRoute from './private.route';
import PublicRoute from './public.route';
import { DashboardRouter } from './dashboard.router';

export const AppRouter = () => (
    <Router>
        <Routes>
            <Route
                path="/login"
                element={(
                    <PublicRoute>
                        <Layout>
                            <Login />
                        </Layout>
                    </PublicRoute>
                )}
            />
            <Route
                path="/reset-password"
                element={(
                    <PublicRoute>
                        <Layout>
                            <ResetPassword />
                        </Layout>
                    </PublicRoute>
                )}
            />
            <Route
                path="/sign-up"
                element={(
                    <PublicRoute>
                        <Layout>
                            <SignUp />
                        </Layout>
                    </PublicRoute>
                )}
            />
            <Route
                path="/onboarding"
                element={(
                    <Navigate to="/onboarding/academic-record" />
                )}
            />
            <Route
                path="/onboarding/*"
                element={(
                    <PrivateRoute>
                        <Layout>
                            <Onboarding />
                        </Layout>
                    </PrivateRoute>
                )}
            />
            <Route
                path="/*"
                element={(
                    <PrivateRoute>
                        <Dashboard>
                            <DashboardRouter />
                        </Dashboard>
                    </PrivateRoute>
                )}
            />
        </Routes>
    </Router>
);

export default AppRouter;
