import {
    Col, Input, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { IContext } from '../context/study-groups.interface';

interface IProps {
    defaultSearch: string | null;
    props: IContext;
}

const { Search } = Input;

const Filters = ({
    defaultSearch, props,
}: IProps) => {
    const { t } = useTranslation('admin');
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS.INPUTS' });

    const {
        handleSearch, loading, searchParams,
    } = props;
    const { search } = searchParams;

    return (
        <Row className="full__width" gutter={[24, 24]}>
            <Col xs={24} lg={16} className="col-left">
                <Search
                    allowClear
                    enterButton
                    placeholder={t('TABLE.SEARCH_STUDY_GROUP')}
                    onSearch={handleSearch}
                    defaultValue={search || defaultSearch}
                    disabled={loading}
                />
            </Col>
        </Row>
    );
};

export default Filters;
