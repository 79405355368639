import {
    Col, Form, Row, Typography, Input, Checkbox, message, Button, notification, Image, Space, PageHeader,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateWelcomePhraseData } from '../../store/slices/auth';
import IMAGES from '../../constants/images';
import { RECTORAL_BOARD_SITE } from '../../config';

interface Props {
    t: (key: string) => string;
}

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

const WelcomePhrase = ({ t }: Props) => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const [form] = Form.useForm();
    const [showPhrase, setShowPhrase] = useState<boolean>(false);

    const { user, loading, error } = useSelector((state: any) => state.auth);

    const dispatch = useDispatch<any>();

    const RULES: any = [
        { type: 'string', max: 250, message: tf('RULES.MAX_LENGTH', { length: 250 }) },
        { type: 'string', min: 5, message: tf('RULES.MIN_LENGTH', { length: 5 }) },
    ];

    const onChange = (e: CheckboxChangeEvent) => {
        const value = e.target.checked;
        setShowPhrase(value);
    };

    const onSubmit = async (values: any) => {
        if (showPhrase && (values.content === '' || !values.content)) {
            message.warning(tf('RULES.NO_SHOW_EMPTY_WELCOME_PHRASE'));
            setShowPhrase(false);
            return;
        }

        const payload = {
            content: values.content,
            isRequired: showPhrase,
        };

        dispatch(updateWelcomePhraseData(payload));

        if (!loading) {
            if (error) {
                notification.error({
                    message: 'Error',
                    description: error,
                });
            } else {
                notification.success({
                    message: tf('RESPONSES.UPDATE_SUCCESS'),
                });
                setShowPhrase(false);
            }
        }
    };

    useEffect(() => {
        form.setFieldsValue({
            content: user.slogan.content,
        });
        setShowPhrase(user.slogan.isRequired);
    }, []);

    return (
        <div className="profile__manage-tabs-forms">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                className="full__width"
            >
                <PageHeader
                    title={(
                        <Title level={4} style={{ marginBottom: 0 }}>
                            {t('PERSONAL.WELCOME_PHRASE')}
                        </Title>
                    )}
                    extra={(
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {tf('ACTIONS.UPDATE')}
                        </Button>
                    )}
                />
                <Row className="full__size" gutter={[24, 28]}>
                    <Col xs={24} xxl={12} className="col-left">
                        <Title level={4}>
                            {t('PERSONAL.PREVIEW_WELCOME_PHRASE')}
                        </Title>
                        <Paragraph>
                            <Trans
                                t={t}
                                i18nKey="PERSONAL.WELCOME_PHRASE_DESCRIPTION"
                                components={{
                                    b: <b style={{ color: '#1b4484' }} onClick={() => window.open(RECTORAL_BOARD_SITE, '_blank')} />,
                                }}
                            />
                        </Paragraph>
                        <Image
                            preview={false}
                            src={IMAGES.previews.welcome_phrase}
                            className="preview__image"
                        />
                    </Col>
                    <Col xs={24} xxl={12} className="col-right">
                        <Form.Item
                            label={tf('INPUTS.WELCOME_PHRASE')}
                            name="content"
                            rules={RULES}
                        >
                            <TextArea
                                placeholder={tf('INPUTS.WELCOME_PHRASE_PLACEHOLDER')}
                                maxLength={250}
                                minLength={5}
                                showCount
                            />
                        </Form.Item>

                        <Checkbox
                            checked={showPhrase}
                            onChange={onChange}
                        >
                            {tf('INPUTS.SHOW_WELCOME_PHRASE')}
                        </Checkbox>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default WelcomePhrase;
