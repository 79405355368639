import { useContext } from 'react';
import {
    Button, Form, PageHeader, notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AddWebinar } from '../../../components/Admin/Webinars';
import { WebinarsContext } from '../../../components/Admin/Webinars/context/webinars.context';

const AddWebinarScreen = () => {
    const { t } = useTranslation('admin');
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const [form] = Form.useForm();

    const { errors, actionLoading, addItem: addWebinar } = useContext(WebinarsContext);

    const navigate = useNavigate();

    const onSubmit = (values: any) => {
        const payload = {
            date: values.date,
            categoryID: values.categoryID,
            tag: values.tag,
            banner: {
                image: values.image,
                thumbnail: values.image,
            },
            schedules: values.schedules,
            video: {
                link: values.videoLink,
                frame: values.videoFrame,
                isAvailable: values.isAvailable,
            },
            registerLink: values.registerLink,
            interviewers: values.interviewers.map((item: any) => item._id),
            interviewees: values.interviewees.map((item: any) => item._id),
            moderators: values.moderators.map((item: any) => item._id),
            channels: values.channels,
            survey: values.survey,
            topic: [
                values.topic_es,
                values.topic_en,
                values.topic_pt,
            ],
            summary: [
                values.summary_es,
                values.summary_en,
                values.summary_pt,
            ],
        };

        addWebinar(payload);

        if (!actionLoading) {
            if (errors.length === 0) {
                notification.success({ message: tf('RESPONSES.ADD_SUCCESS') });
                form.resetFields();
            } else {
                notification.error({
                    message: tf('RESPONSES.ADD_ERROR'),
                    description: errors[0] || tf('SOMENTHING_WENT_WRONG'),
                });
            }
        }
    };

    return (
        <div className="new__add">
            <Form
                form={form}
                layout="vertical"
                name="add-webinar"
                onFinish={onSubmit}
                initialValues={{
                    channels: ['FACEBOOK'],
                }}
            >
                <PageHeader
                    onBack={() => navigate(-1)}
                    className="site-page-header-responsive"
                    title={t('WEBINARS.NEW')}
                    extra={[
                        <Button
                            type="primary"
                            loading={actionLoading}
                            htmlType="submit"
                        >
                            {t('ADD')}
                        </Button>,
                    ]}
                    footer={<AddWebinar form={form} />}
                />
            </Form>
        </div>
    );
};

export default AddWebinarScreen;
