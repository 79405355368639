import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button, Col, Form, Input, PageHeader, Row, Typography, notification,
} from 'antd';
import { IMembership, IUser } from '../../../../interfaces';
import { MEMBERSHIPS } from '../../../../constants/memberships';
import CardMembership from '../../../../widgets/CardMembership';
import userAPI from '../../../../api/user';

interface Props {
    t: (key: string) => string;
    userData: IUser | null;
}

const { Title } = Typography;

const Membership = ({ t, userData }: Props) => {
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const [form] = Form.useForm();
    const [membership, setMembership] = useState<IMembership>();
    const [loading, setLoading] = useState(false);

    const onSubmit = (values: any) => {
        setLoading(true);
        const category = { ...values };
        const userID = userData?._id || '';

        userAPI.updateMembership(category, userID)
            .then(() => {
                notification.success({
                    message: tf('RESPONSES.UPDATE_SUCCESS'),
                });
            })
            .catch((error: any) => {
                const description = error?.response?.data?.message || error.message;
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));

    };

    useEffect(() => {
        form.setFieldsValue({
            membership: userData?.membership?.keyName,
        });
        setMembership(
            MEMBERSHIPS.find((membership: IMembership) => membership.keyName === userData?.membership?.keyName),
        );
    }, []);

    const onChange = (membership: IMembership) => {
        setMembership(membership);
        form.setFieldsValue({ membership: membership.keyName });
    };

    return (
        <div className="profile__manage-tabs-forms">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                className="full__width"
            >
                <PageHeader
                    title={(
                        <Title level={4} style={{ marginBottom: 0 }}>
                            {t('PERSONAL.MEMBERSHIP_DATA')}
                        </Title>
                    )}
                    extra={(
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {tf('ACTIONS.UPDATE')}
                        </Button>
                    )}
                />
                <Form.Item hidden name="membership">
                    <Input />
                </Form.Item>
                <Row className="full__size" gutter={16}>
                    {
                        MEMBERSHIPS.map((membership: IMembership, index: number) => (
                            <Col xs={24} xxl={8} key={index}>
                                <CardMembership
                                    form={form}
                                    membership={membership}
                                    onChange={onChange}
                                />
                            </Col>
                        ))
                    }
                </Row>
            </Form>
        </div>
    );
};

export default Membership;
