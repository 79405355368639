import axios from 'axios';
import { DASHBOARD_API } from '../config';
import { ICreateDocument, IUpdateDocument } from '../interfaces';

const getDocuments = (query: any) => {
    const urlParams = new URLSearchParams(query).toString();
    const url = `${DASHBOARD_API}/library/?${urlParams}`;
    const response = axios.get(url);
    return response;
};

const getDocument = (documentID: string) => {
    const url = `${DASHBOARD_API}/library/${documentID}`;
    const response = axios.get(url);
    return response;
};

const createDocument = (payload: ICreateDocument) => {
    const url = `${DASHBOARD_API}/library/create`;
    const response = axios.post(url, payload);
    return response;
};

const updateDocument = (documentID: string, payload: IUpdateDocument) => {
    const url = `${DASHBOARD_API}/library/${documentID}`;
    const response = axios.put(url, payload);
    return response;
};

const deleteDocument = (documentID: string) => {
    const url = `${DASHBOARD_API}/library/${documentID}`;
    const response = axios.delete(url);
    return response;
};

export default {
    getDocuments,
    createDocument,
    getDocument,
    updateDocument,
    deleteDocument,
};
