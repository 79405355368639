import { Typography } from 'antd';

interface Props {
    title: string;
    description: string;
}

const { Title, Paragraph } = Typography;

const Header = ({ title, description }: Props) => (
    <div className="header">
        <Title level={3}>
            {title}
        </Title>
        <Paragraph>
            {description}
        </Paragraph>
    </div>
);

export default Header;
