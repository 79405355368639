import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

const { Text } = Typography;

const NotAvailable = () => {
    const { t } = useTranslation('admin', { keyPrefix: 'TABLE' });

    return (
        <Text className="table__not-available">
            {t('NOT_AVAILABLE')}
        </Text>
    );
};

export default NotAvailable;
