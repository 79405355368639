import {
    Typography, Form, Button, Row, Col, Input, Tooltip, notification, Alert, PageHeader,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { LibraryContext } from '../context/libraryContext';
import UploadImage from '../../UploadImage';
import Categories from '../../../widgets/Categories';
import DocumentType from '../../../widgets/DocumentType';
import libraryAPI from '../../../api/library';
import {
    IAxiosError, IAxiosResponse, IDocument, IUpdateDocument,
} from '../../../interfaces';
import UploadFile from '../../UploadFile';

const { Title } = Typography;

const EditDocument = () => {
    const { t } = useTranslation('library', { keyPrefix: 'LIBRARY' });
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });
    const { t: ti } = useTranslation('forms', { keyPrefix: 'FORMS.INPUTS' });

    const { actionLoading, errors, updateDocument } = useContext(LibraryContext);
    const { documentID } = useParams();
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = (values: any) => {
        const payload: IUpdateDocument = {
            isAvailable: true,
            keyWords: values.keyWords,
            title: values.title,
            type: values.documentType,
            categoryID: values.categoryID,
            dataSheet: {
                source: values.source,
                publisher: values.publisher || '',
                publisherYear: values.publisherYear,
                author: values.author || '',
            },
        };

        if (values.image) {
            payload.image = {
                original: values.image,
                thumbnail: values.image,
            };
        }

        if (values.file) {
            payload.externalLink = values.file;
        } else {
            payload.externalLink = values.externalLink;
        }

        updateDocument(documentID || '', payload);

        if (!actionLoading) {
            if (errors.length === 0) {
                notification.success({ message: t('UPDATED_SUCCESS') });
                form.resetFields();
            } else {
                notification.error({
                    message: t('UPDATED_ERROR'),
                    description: errors[0] || tf('SOMETHING_WENT_WRONG'),
                });
            }
        }
    };

    const RULES: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
    ];

    const RULES_YEAR: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
        { type: 'string', max: 4, message: tf('RULES.MAX_LENGTH', { length: 4 }) },
        { type: 'string', min: 4, message: tf('RULES.MIN_LENGTH', { length: 4 }) },
    ];

    const RULES_URL: any = [
        { type: 'url', warningOnly: true, message: tf('RULES.INVALID_URL') },
        { type: 'string', min: 6, message: tf('RULES.MIN_LENGTH', { length: 6 }) },
    ];

    useEffect(() => {
        if (!documentID) return;
        setLoading(true);
        libraryAPI.getDocument(documentID)
            .then((response: Partial<IAxiosResponse>) => {
                const document: IDocument = response.data;
                form.setFieldsValue({
                    isAvailable: document.isAvailable,
                    keyWords: document.keyWords,
                    title: document.title,
                    type: document.type,
                    externalLink: document.externalLink,
                    categoryID: document.categoryID,
                    source: document.dataSheet.source,
                    publisher: document.dataSheet.publisher || '',
                    publisherYear: document.dataSheet.publisherYear,
                    author: document.dataSheet.author || '',
                });

                if (document.image) {
                    form.setFieldsValue({
                        image: document.image.original,
                    });
                }
            })
            .catch((error: Partial<IAxiosError>) => console.log(error))
            .finally(() => setLoading(false));
    }, [documentID]);

    return (
        <div className="new__edit">
            <Form
                form={form}
                layout="vertical"
                name="add-article"
                onFinish={onSubmit}
            >
                <PageHeader
                    onBack={() => navigate(-1)}
                    className="site-page-header-responsive"
                    title={t('EDIT_DOCUMENT')}
                    extra={[
                        <Button
                            type="primary"
                            loading={loading}
                            htmlType="submit"
                        >
                            {t('UPDATE_DOCUMENT')}
                        </Button>,
                    ]}
                    footer={(

                        <Row gutter={[32, 40]}>
                            <Col xs={24} xl={12} className="col-left">
                                <div className="services__container none__margin--top none__padding--large">
                                    <div className="services__container-header">
                                        <Title level={4}>
                                            {t('DOCUMENT_INFORMATION')}
                                        </Title>
                                    </div>
                                    <div className="services__container-form">
                                        <Form.Item
                                            label={ti('DOCUMENT.IMAGE')}
                                            name="image"
                                            noStyle
                                        >
                                            <UploadImage t={tf} type="library" name={form.getFieldValue('title')} />
                                        </Form.Item>
                                        <Form.Item
                                            label={ti('DOCUMENT.TITLE')}
                                            name="title"
                                            rules={RULES}
                                        >
                                            <Input placeholder={ti('DOCUMENT.TITLE_PLACEHOLDER')} />
                                        </Form.Item>

                                        <DocumentType />

                                        <Categories />
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} xl={12} className="col-right">
                                <div className="services__container none__margin--top none__padding--large">
                                    <div className="services__container-header">
                                        <Title level={4}>
                                            {t('DOCUMENT_DATA_SHEET')}
                                        </Title>
                                    </div>
                                    <div className="services__container-form">
                                        <Form.Item
                                            label={ti('AUTHOR')}
                                            name="author"
                                        >
                                            <Input placeholder={ti('AUTHOR_PLACEHOLDER')} />
                                        </Form.Item>

                                        <Form.Item
                                            label={ti('SOURCE')}
                                            name="source"
                                        >
                                            <Input placeholder={ti('SOURCE_PLACEHOLDER')} />
                                        </Form.Item>

                                        <Form.Item
                                            label={ti('PUBLISHER')}
                                            name="publisher"
                                        >
                                            <Input placeholder={ti('PUBLISHER_PLACEHOLDER')} />
                                        </Form.Item>

                                        <Tooltip placement="bottomLeft" showArrow title={ti('KEY_WORDS_HELP')}>
                                            <Form.Item
                                                label={ti('KEY_WORDS')}
                                                name="keyWords"
                                                rules={RULES}
                                            >
                                                <Input placeholder={ti('KEY_WORDS_PLACEHOLDER')} />
                                            </Form.Item>
                                        </Tooltip>

                                        <Form.Item
                                            label={ti('YEAR_PUBLISHER')}
                                            name="publisherYear"
                                            rules={RULES_YEAR}
                                        >
                                            <Input placeholder={ti('YEAR_PUBLISHER_PLACEHOLDER')} />
                                        </Form.Item>

                                        <Form.Item
                                            label={ti('EXTERNAL_LINK')}
                                            name="externalLink"
                                            rules={[...RULES_URL, ...RULES]}
                                        >
                                            <Input
                                                placeholder={ti('EXTERNAL_LINK_PLACEHOLDER')}
                                            />
                                        </Form.Item>

                                        <Alert
                                            style={{ marginBottom: 20 }}
                                            message={ti('EMPTY_FILE_NAME')}
                                            type="info"
                                        />

                                        <Form.Item
                                            label={ti('DOCUMENT.FILE')}
                                            name="file"
                                            noStyle
                                        >
                                            <UploadFile
                                                t={ti}
                                                type="library"
                                                name={form.getFieldValue('title')}
                                                form={form}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )}
                />
            </Form>
        </div>
    );
};

export default EditDocument;
