import { useContext, useEffect } from 'react';
import { PageHeader, Tabs } from 'antd';
import type { TabPaneProps } from 'antd/lib/tabs';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { UsersContext } from '../../../components/Admin/Users/context/users.context';
import {
    PersonalUser, AcademicUser, ContactUser, MembershipUser, WelcomePhraseUser, LucesInfo,
} from '../../../components/Admin/Users';
import Partner from '../../../components/Admin/Users/components/Partner';

type TabPosition = 'top' | 'right' | 'bottom' | 'left';

const UpdateUserScreen = () => {
    const { t } = useTranslation('admin');
    const { t: tp } = useTranslation('profile', { keyPrefix: 'PROFILE' });
    const navigate = useNavigate();

    const { id: userID } = useParams();

    const { userSelected, fetchUser } = useContext(UsersContext);

    const mode: TabPosition = 'top';

    const items: TabPaneProps[] = [
        {
            tab: tp('TABS.PERSONAL'),
            tabKey: '1',
            children: <PersonalUser t={tp} userData={userSelected} />,
        },
        {
            tab: tp('TABS.ACADEMIC'),
            tabKey: '2',
            children: <AcademicUser t={tp} userData={userSelected} />,
        },
        {
            tab: tp('TABS.CONTACT'),
            tabKey: '3',
            children: <ContactUser t={tp} userData={userSelected} />,
        },
        {
            tab: tp('TABS.MEMBERSHIP'),
            tabKey: '4',
            children: <MembershipUser t={tp} userData={userSelected} />,
        },
        {
            tab: tp('TABS.WELCOME_PHRASE'),
            tabKey: '5',
            children: <WelcomePhraseUser t={tp} userData={userSelected} />,
        },
        {
            tab: tp('TABS.PARTNER'),
            tabKey: '6',
            children: <Partner t={tp} userData={userSelected} />,
        },
        {
            tab: tp('TABS.LUCES'),
            tabKey: '7',
            children: <LucesInfo t={tp} userData={userSelected} />,
        },
    ];

    useEffect(() => {
        if (!userID) return;
        fetchUser(userID);
    }, [userID]);

    return (
        <div className="articles__edit">
            <PageHeader
                onBack={() => navigate(-1)}
                className="site-page-header-responsive"
                title={(
                    <Trans
                        t={t}
                        i18nKey="EDIT_MEMBER"
                        components={{ span: <span style={{ fontWeight: 500 }} /> }}
                        values={{ name: userSelected?.name, lastName: userSelected?.lastName }}
                    />
                )}
                footer={(
                    <Tabs
                        defaultActiveKey="0"
                        tabPosition={mode}
                        className="profile__manage-tabs"
                        type="card"
                        items={
                            items.map((item, index) => {
                                const { tab, children } = item;
                                const id = String(index);
                                return {
                                    label: tab,
                                    key: id,
                                    children,
                                };
                            })
                        }
                    />
                )}
            />
        </div>
    );
};

export default UpdateUserScreen;
