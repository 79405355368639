import React, { useEffect, useState } from 'react';
import {
    Form, Space, Checkbox, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';

import ModalWrapper from '../../components/Modals';
import Clauses from '../../components/Modals/Clauses';
import { theme } from '../../theme';

const { Title } = Typography;

interface Props {
    form?: any;
}

const TermsAndConditions = ({ form }: Props) => {
    const { t } = useTranslation('terms');
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });

    const [visible, setVisible] = useState<boolean>(false);

    const RULES: any = [
        { required: true, message: tf('RULES.REQUIRED_TERMS_CONDITIONS') },
    ];

    const onChange = (values: any) => {
        const { checked } = values.target;
        form.setFieldsValue({ terms: checked });
    };

    return (
        <div>
            <ModalWrapper
                visible={visible}
                onCancel={() => setVisible(false)}
            >
                <Clauses />
            </ModalWrapper>

            <Form.Item
                name="terms"
                valuePropName="checked"
                rules={RULES}
            >
                <Checkbox onChange={(value: any) => onChange(value)} defaultChecked>
                    <Space
                        className="dont-has-account"
                        direction="horizontal"
                        size={8}
                    >
                        <Title
                            level={5}
                            style={{
                                color: theme.black,
                                fontWeight: 400,
                            }}
                        >
                            {t('I_HAVE_READ_AND_ACCEPT')}
                        </Title>
                    </Space>
                </Checkbox>
                <Title
                    level={5}
                    style={{ width: 'auto', display: 'contents', cursor: 'pointer' }}
                    onClick={() => setVisible(true)}
                >
                    {t('TERMS_AND_CONDITIONS')}
                </Title>
            </Form.Item>

        </div>
    );
};

export default TermsAndConditions;
