import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import CommunityScreen from '../screens/Community';
import { CommunityProvider } from '../components/Community/context/community.context';

export const CommunityRouter: FC = () => (
    <Routes>
        <Route
            path="/"
            element={(
                <CommunityProvider>
                    <CommunityScreen />
                </CommunityProvider>
            )}
        />
    </Routes>
);
