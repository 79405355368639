import { EQuotesStatus, EQuotesType } from '../enums/quotes.enum';

export const QUOTES_TYPES: string[] = [
    EQuotesType.MENTORING,
    EQuotesType.LEADERSHIP,
    EQuotesType.TOOL_BOX,
];

export const QUOTES_STATUS: string[] = [
    EQuotesStatus.PENDING,
    EQuotesStatus.APPROVED,
];
