import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import { StudyGroupsProvider } from '../../providers';
import { StudyGroupsScreen } from '../../screens/Admin/StudyGroups';

export const StudyGroupsRouter: FC = () => (
    <Routes>
        <Route
            path=""
            element={(
                <StudyGroupsProvider>
                    <StudyGroupsScreen />
                </StudyGroupsProvider>
            )}
        />
    </Routes>
);
