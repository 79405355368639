import { Image, IImageProps } from '@fluentui/react/lib/Image';
import IMAGES from '../../constants/images';

interface Props {
    type : 'light' | 'dark',
    direction: 'vertical' | 'horizontal',
    size: { width: number, height?: number },
    className?: string,
}

const LogoRectoral = ({
    type, direction, size, className,
}: Props) => {
    const { width, height } = size;
    const imageProps: Partial<IImageProps> = {
        src: IMAGES.logo[direction][type],
        alt: 'Logo rectoral board',
        loading: 'lazy',
        width,
        height: height || 'auto',
    };

    return <Image {...imageProps} className={className} />;
};

export default LogoRectoral;
