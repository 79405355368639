import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { theme } from '../../theme';
import Copyright from '../../widgets/Copyright';
import LogoRectoral from '../../widgets/Logo';

const { Title, Paragraph } = Typography;

const WelcomeMessage = () => {
    const { t } = useTranslation('login', { keyPrefix: 'WELCOME' });

    return (
        <div className="welcome-message">
            <LogoRectoral
                size={{ width: 200, height: 200 }}
                type="light"
                direction="vertical"
            />
            <Title level={2} style={{ color: theme.white }}>
                {t('WELCOME_TO_RECTORAL_BOARD')}
            </Title>
            <Paragraph style={{ color: theme.white }} className="center">
                {t('WELCOME_MESSAGE')}
            </Paragraph>
            <Title level={3} style={{ color: theme.white }} className="center">
                {t('JOIN_TO_RECTORAL_BOARD')}
            </Title>
            <Copyright />
        </div>
    );
};

export default WelcomeMessage;
