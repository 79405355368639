import { Button, PageHeader, Space } from 'antd';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReviewsContext } from './context/reviews.context';
import TableReviews from './components/Table';
import Filters from './components/Filters';

const Reviews = ({ params }: { params: URLSearchParams }) => {
    const { t } = useTranslation('admin');
    const navigate = useNavigate();

    const props = useContext(ReviewsContext);

    const {
        fetchReviews, searchParams, paginator,
    } = props;
    const { search, country } = searchParams;

    const defaultSearch = params.get('search');
    const defaultCountry = params.get('country');

    useEffect(() => {
        fetchReviews();
    }, [paginator, search, defaultSearch, country, defaultCountry]);

    return (
        <div className="articles__edit">
            <PageHeader
                className="site-page-header-responsive"
                title={t('ADMIN_REVIEWS')}
                extra={[
                    <Button
                        type="primary"
                        onClick={() => navigate('/admin/book/reviews/create')}
                    >
                        {t('CREATE_REVIEW')}
                    </Button>,
                ]}
                footer={(
                    <Space direction="vertical" size={16} className="full__width">
                        <Filters
                            defaultSearch={defaultSearch}
                            defaultCountry={defaultCountry}
                            props={props}
                        />
                        <TableReviews {...props} />
                    </Space>
                )}
            />
        </div>
    );
};

export default Reviews;
