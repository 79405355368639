import { createContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { initialContext } from './reviews.initial';
import { IContext } from './reviews.interface';
import { IAxiosError, IAxiosResponse } from '../../../../interfaces';
import reviewsAPI from '../../../../api/reviews';

export const ReviewsContext = createContext<IContext>(initialContext);

export const ReviewsProvider = ({ children }: { children: React.ReactNode }) => {
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [loadAction, setLoadAction] = useState<boolean>(initialContext.loadAction);
    const [errors, setErrors] = useState<string[]>(initialContext.errors);
    const [reviews, setReviews] = useState<any[]>(initialContext.reviews);
    const [review, setReview] = useState<any | null>(initialContext.review);
    const [total, setTotal] = useState<number>(initialContext.total);
    const [paginator, setPaginator] = useState<any>(initialContext.paginator);
    const [searchParams, setSearchParams] = useSearchParams();

    const search = searchParams.get('search') || '';
    const country = searchParams.get('country') || '';

    const getParams = () => {
        const params: any = { };
        if (search) params.search = search;
        if (country) params.country = country;
        return params;
    };

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({
            page,
            limit: pageSize,
        });
    };

    const fetchReviews = async () => {
        setLoading(true);
        setErrors([]);

        const params: any = {
            ...getParams(),
            ...paginator,
        };

        reviewsAPI.getReviews(params)
            .then(({ data, meta }: Partial<IAxiosResponse>) => {
                setReviews(data);
                setTotal(meta?.itemCount || 0);
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setLoading(false));
    };

    const handleSearch = (search: string) => {
        setSearchParams({ ...getParams(), search });
        setPaginator(initialContext.paginator);
    };

    const handleCountry = (country: string) => {
        setSearchParams({ ...getParams(), country });
        setPaginator(initialContext.paginator);
    };

    const setUnavailable = (id: string) => {
        setLoadAction(true);
        reviewsAPI.deleteReview(id)
            .then(() => {
                const newReviews = reviews.filter((item) => item._id !== id);
                setReviews(newReviews);
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setLoadAction(false));
    };

    const addReview = (payload: any) => {
        setLoadAction(true);
        setErrors([]);

        reviewsAPI.createReview(payload)
            .then((response: Partial<IAxiosResponse>) => {
                const { data } = response;
                setReviews([data, ...reviews]);
                setTotal(total + 1);
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setLoadAction(false));

    };

    const updateReview = (id: string, payload: any) => {
        setLoadAction(true);
        setErrors([]);

        reviewsAPI.updateReview(id, payload)
            .then((response: Partial<IAxiosResponse>) => {
                const { data } = response;
                const newReviews = reviews.map((item) => (item._id === id ? { ...item, ...data } : item));
                setReviews(newReviews);
            })
            .catch((error: Partial<IAxiosError>) => {
                const message = error.response?.data?.error || 'Something went wrong';
                setErrors([message]);
            })
            .finally(() => setLoadAction(false));
    };

    return (
        <ReviewsContext.Provider
            value={{
                loading,
                loadAction,
                reviews,
                review,
                total,
                paginator,
                searchParams,
                errors,
                onPageChange,
                fetchReviews,
                handleSearch,
                handleCountry,
                setUnavailable,
                addReview,
                updateReview,
                setReview,
            }}
        >
            {children}
        </ReviewsContext.Provider>
    );
};
