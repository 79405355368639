import {
    Button, Col, Form, Input, PageHeader, Row, Space, Tooltip, Typography, message, notification,
} from 'antd';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ContributionsContext } from '../context/contributionsContext';
import ContributionCategory from '../../../widgets/ContributionType';
import ContributionStatus from '../../../widgets/Status';
import UploadImage from '../../UploadImage';
import Country from '../../../widgets/Country';
import Location from './Location';
import { IContributionPayload } from '../../../interfaces';

const { Title } = Typography;
const { TextArea } = Input;

const AddContribution = () => {
    const { t } = useTranslation('contributions', { keyPrefix: 'CONTRIBUTIONS' });
    const { t: tf } = useTranslation('forms', { keyPrefix: 'FORMS' });
    const { t: ti } = useTranslation('forms', { keyPrefix: 'FORMS.INPUTS' });
    const { user } = useSelector((state: any) => state.auth);
    const { actionLoading, errors, addContribution } = useContext(ContributionsContext);

    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [coordinates, setCoordinates] = useState<any>({
        lat: -2.170998, lng: -79.922356,
    });

    const onSubmit = (values: any) => {
        if (!coordinates) {
            message.error(t('MISSING_LOCATION'));
            return;
        }

        const payload: IContributionPayload = {
            authorID: user._id,
            registerBy: user._id,
            isAvailable: true,
            project: {
                name: values.projectName,
                summary: values.summary,
                category: values.category,
                startYear: values.startYear,
                endYear: values.endYear,
                leadership: values.leadership,
                externalLink: values.externalLink,
            },
            keyWords: values.keyWords,
            location: {
                city: values.city,
                country: values.country,
                coordinates,
            },
            status: values.status,
            category: values.category,
        };

        if (values.image) {
            payload.image = {
                original: values.image,
                thumbnail: values.image,
            };
        }

        addContribution(payload);

        if (!actionLoading) {
            if (errors.length === 0) {
                notification.success({ message: t('ADDED_CONTRIBUTION_SUCCESSFULLY') });
                setCoordinates(null);
                form.resetFields();
            } else {
                notification.error({
                    message: tf('ADDED_CONTRIBUTION_ERROR'),
                    description: errors[0] || t('SOMETHING_WENT_WRONG'),
                });
            }
        }
    };

    const RULES: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
    ];

    const RULES_YEAR: any = [
        { required: true, message: tf('RULES.REQUIRED_FIELD') },
        { type: 'string', max: 4, message: tf('RULES.MAX_LENGTH', { length: 4 }) },
        { type: 'string', min: 4, message: tf('RULES.MIN_LENGTH', { length: 4 }) },
    ];

    const RULES_URL: any = [
        { type: 'url', warningOnly: true, message: tf('RULES.INVALID_URL') },
        { type: 'string', min: 6, message: tf('RULES.MIN_LENGTH', { length: 6 }) },
    ];

    return (
        <div className="new__add">
            <Form
                form={form}
                layout="vertical"
                name="add-article"
                onFinish={onSubmit}
            >
                <PageHeader
                    onBack={() => navigate(-1)}
                    className="site-page-header-responsive"
                    title={t('ADD_CONTRIBUTION')}
                    extra={[
                        <Button
                            type="primary"
                            loading={actionLoading}
                            htmlType="submit"
                        >
                            {t('ADD_CONTRIBUTION')}
                        </Button>,
                    ]}
                    footer={(
                        <Row gutter={[40, 32]}>
                            <Col xs={24} xl={12} className="col-left">
                                <Space direction="vertical" size={24} className="full__width">
                                    <div className="services__container none__margin--top none__padding--medium">
                                        <div className="services__container-header">
                                            <Title level={4}>
                                                {t('CONTRIBUTION_INFORMATION')}
                                            </Title>
                                        </div>

                                        <div className="services__container-form">
                                            <Form.Item
                                                label={ti('CONTRIBUTION.INSTITUTE_LOGO')}
                                                name="image"
                                                noStyle
                                            >
                                                <UploadImage
                                                    t={tf}
                                                    type="contribution"
                                                    name={form.getFieldValue('projectName')}
                                                />
                                            </Form.Item>
                                            <ContributionStatus />
                                            <ContributionCategory />
                                        </div>
                                    </div>

                                    <div className="services__container none__margin--top none__padding--medium">
                                        <div className="services__container-header">
                                            <Title level={4}>
                                                {t('LOCATION_PROJECT')}
                                            </Title>
                                        </div>
                                        <div className="services__container-form">
                                            <Location
                                                t={t}
                                                coordinates={coordinates}
                                                setCoordinates={setCoordinates}
                                            />
                                            <Row gutter={[24, 28]}>
                                                <Col xs={12} className="col-left">
                                                    <Country />
                                                </Col>
                                                <Col xs={12} className="col-right">
                                                    <Form.Item
                                                        label={ti('CONTRIBUTION.CITY')}
                                                        name="city"
                                                        rules={RULES}
                                                    >
                                                        <Input placeholder={ti('CONTRIBUTION.CITY_PLACEHOLDER')} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                        </div>
                                    </div>
                                </Space>
                            </Col>
                            <Col xs={24} xl={12} className="col-left">
                                <div className="services__container none__margin--top none__padding--medium">
                                    <div className="services__container-header">
                                        <Title level={4}>
                                            {t('PROJECT_INFORMATION')}
                                        </Title>
                                    </div>

                                    <div className="services__container-form">
                                        <Form.Item
                                            label={ti('CONTRIBUTION.PROJECT_NAME')}
                                            name="projectName"
                                            rules={RULES}
                                        >
                                            <Input placeholder={ti('CONTRIBUTION.PROJECT_NAME_PLACEHOLDER')} />
                                        </Form.Item>

                                        <Form.Item
                                            label={ti('CONTRIBUTION.SUMMARY')}
                                            name="summary"
                                            rules={RULES}
                                        >
                                            <TextArea
                                                placeholder={ti('CONTRIBUTION.SUMMARY_PLACEHOLDER')}
                                                maxLength={500}
                                                showCount
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label={ti('CONTRIBUTION.LEADERSHIP')}
                                            name="leadership"
                                            rules={RULES}
                                        >
                                            <Input placeholder={ti('CONTRIBUTION.LEADERSHIP_PLACEHOLDER')} />
                                        </Form.Item>

                                        <Form.Item
                                            label={ti('CONTRIBUTION.PROJECT_CATEGORY')}
                                            name="projectCategory"
                                        >
                                            <Input placeholder={ti('CONTRIBUTION.PROJECT_CATEGORY_PLACEHOLDER')} />
                                        </Form.Item>

                                        <Tooltip placement="bottomLeft" showArrow title={ti('KEY_WORDS_HELP')}>
                                            <Form.Item
                                                label={ti('KEY_WORDS')}
                                                name="keyWords"
                                                rules={RULES}
                                            >
                                                <Input placeholder={ti('KEY_WORDS_PLACEHOLDER')} />
                                            </Form.Item>
                                        </Tooltip>

                                        <Form.Item
                                            label={ti('CONTRIBUTION.START_YEAR')}
                                            name="startYear"
                                            rules={RULES_YEAR}
                                        >
                                            <Input placeholder={ti('CONTRIBUTION.START_YEAR_PLACEHOLDER')} />
                                        </Form.Item>

                                        <Form.Item
                                            label={ti('CONTRIBUTION.END_YEAR')}
                                            name="endYear"
                                            rules={RULES_YEAR}
                                        >
                                            <Input placeholder={ti('CONTRIBUTION.END_YEAR_PLACEHOLDER')} />
                                        </Form.Item>

                                        <Form.Item
                                            label={ti('CONTRIBUTION.EXTERNAL_LINK')}
                                            name="externalLink"
                                            rules={RULES_URL}
                                        >
                                            <Input
                                                placeholder={ti('CONTRIBUTION.EXTERNAL_LINK_PLACEHOLDER')}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )}
                />
            </Form>
        </div>
    );
};

export default AddContribution;
